export enum DeviceOrderBy {
  ID = 'id',
  TYPE = 'type',
  CUSTOMER = 'customer',
  SITE = 'site',
  LASTEMPTY = 'lastEmpty',
  LASTSERVICE = 'lastService',
  FILL = 'fillLevel',
  WASTEFRACTION = 'wasteFraction'
}
