import UserType from './UserType';

interface User {
    Id: number;
    B2cId: string;
    Name: string;
    Role: UserType;
    Group: UserGroup;
    Status: UserInviteStatus;
    PhoneNumber: string;
    ServiceToolAccess: ServiceToolAccessStatus;
    Email: string;
    GroupName: string;
}

export default User;

export enum UserInviteStatus {
    Active,
    InvitePending,
}

export enum ServiceToolAccessStatus {
    Access,
    NoAccess,
}

export interface UserGroup {
    Id: number;
    Name: string;
}
