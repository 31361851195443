import React, { useEffect, useState } from 'react';
import './SearchBar.css';
import { ReactComponent as SearchIcon } from '../../assets/common/search.svg';

interface SearchBarProps {
  placeholder?: string;
  search: string;
  onChange: (search: string) => void;
  collapsed?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder, search, onChange, collapsed = false }) => {
  const [hiddenField, setHiddenField] = useState(true);

  useEffect(() => {
    if (!collapsed) {
      setHiddenField(true);
    }
  }, [collapsed]);

  return (
    <>
      {collapsed && !hiddenField && <div className="searchbar__hide-layer" onClick={() => setHiddenField(true)}></div>}
      <div
        className={
          'searchbar ' + (hiddenField && collapsed ? 'searchbar__hidden' : collapsed ? 'searchbar__on-top' : '')
        }
      >
        {/* Insert search icon */}
        {(!collapsed || !hiddenField) && (
          <input
            className="searchbar__input"
            type="text"
            placeholder={placeholder}
            value={search}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
        <SearchIcon
          title="Search icon"
          className="searchbar__icon"
          onClick={() => {
            if (collapsed) setHiddenField(!hiddenField);
          }}
        />
      </div>
    </>
  );
};

export default SearchBar;
