import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppInvalidLogin.css';

const AppInvalidLogin: React.FC = () => {
  const { t } = useTranslation();
  return <h1 className="no-acces">{t('INVALID_ORIGIN.NO_ACCESS')}</h1>;
};

export default AppInvalidLogin;
