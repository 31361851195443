import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconError } from '../../assets/popup/iconError.svg';
import { ReactComponent as IconWarning } from '../../assets/popup/iconWarning.svg';
import { Status } from '../../models/Device';
import { GetDeviceWarningColour } from '../../utils/GetDeviceWarningColour';
import './DeviceIssuesDisplaySmall.css';

type DeviceIssuesDisplaySmallProps = {
  status: Status;
  message: string;
};

export const DeviceIssuesDisplaySmall: React.FC<DeviceIssuesDisplaySmallProps> = ({
  status,
  message,
}: DeviceIssuesDisplaySmallProps) => {
  const { t } = useTranslation();
  const issueSeverityColour = GetDeviceWarningColour(status);

  return (
    <div
      className="DeviceIssuesDisplaySmall"
      style={{ backgroundColor: issueSeverityColour.background, borderColor: issueSeverityColour.border }}
    >
      <div className="DeviceIssuesDisplaySmall__icon">
        {status === Status.error ? (
          <IconError></IconError>
        ) : status === Status.serviceDue ? (
          <IconWarning></IconWarning>
        ) : null}
      </div>
      <div className="DeviceIssuesDisplaySmall__message" style={{ color: issueSeverityColour.textColor }}>
        {t(message)}
      </div>
    </div>
  );
};
