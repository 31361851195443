import React, { useEffect, useRef, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ReactComponent as IconFill0 } from '../../../../../../../assets/eventlog/iconFill0.svg';
import { ReactComponent as IconFill1 } from '../../../../../../../assets/eventlog/iconFill1.svg';
import { ReactComponent as IconFill2 } from '../../../../../../../assets/eventlog/iconFill2.svg';
import { ReactComponent as IconFill3 } from '../../../../../../../assets/eventlog/iconFill3.svg';
import { ReactComponent as IconFillFull } from '../../../../../../../assets/eventlog/iconFillFull.svg';
import { ReactComponent as IconFillOverFull } from '../../../../../../../assets/eventlog/iconFillOverFull.svg';
import { ReactComponent as IconFillTooFullToBaleOut } from '../../../../../../../assets/eventlog/iconFillTooFullToBaleOut.svg';
import { ReactComponent as IconError } from '../../../../../../../assets/popup/iconError.svg';
import { ReactComponent as IconWarning } from '../../../../../../../assets/popup/iconWarning.svg';
import { DeviceCodeType } from '../../../../../../../data/models/devices/DeviceEventLogCode';
import { GetDeviceEventLogCodes } from '../../../../../../../data/services/DeviceService';
import Device, { Status } from '../../../../../../../models/Device';
import { getFormattedDateString } from '../../../../../../../utils/DateFormat';
import { GetDeviceEventLogColour } from '../../../../../../../utils/GetDeviceEventLogColour';
import { DeviceErrorCodesNavigation } from '../../../DeviceDetailsErrorCodes/DeviceErrorCodesNavigation/DeviceErrorCodesNavigation';
import './DeviceDetailsStatisticsEventLogCodes.css';

type DeviceDetailsEventLogCodesProps = {
	device: Device;
};

interface DeviceEventLogVisual {
	key: number;
	bit: number;
	label: string;
	type: Status;
	date: string;
}

export const DeviceDetailsStatisticsEventLogCodes: React.FC<DeviceDetailsEventLogCodesProps> = ({ device }) => {
	const listRef = useRef<HTMLDivElement | null>(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [deviceEventLogCodes, setDeviceEventLogCodes] = useState<DeviceEventLogVisual[]>([]);
	const [visibleDeviceEventLogCodes, setVisibleDeviceEventLogCodes] = useState<DeviceEventLogVisual[]>([]);
	const [totalNavigationGroups, setTotalNavigationGroups] = useState<number>(0);
	const AMOUNT_TO_SHOW = 20;
	const { t }: UseTranslationResponse = useTranslation();

	const handlePagination = (direction: number) => {
		if (currentPage + direction <= totalNavigationGroups - 1 && currentPage + direction >= 0) {
			setCurrentPage(currentPage + direction);
		}
	};

	const handleToSpecificPage = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	useEffect(() => {
		GetDeviceEventLogCodes(device.id).then(d => {
			if (d.deviceEventLogList) {
				let deviceEventLogVisualList: DeviceEventLogVisual[] = [];

				d.deviceEventLogList.map(e => {
					let _type: Status;

					switch (e.type) {
						case DeviceCodeType.FILLLEVEL:
							_type = Status.fillLevel;
							break;
						case DeviceCodeType.SERVICE:
							_type = Status.serviceDue;
							break;
						case DeviceCodeType.ERROR:
							_type = Status.error;
							break;
						default:
							_type = Status.ok;
							break;
					}

					var eventLog: DeviceEventLogVisual = {
						key: e.deviceEventLogId,
						bit: e.bitNumber,
						date: e.date,
						label: e.name,
						type: _type
					}

					deviceEventLogVisualList.push(eventLog);
				});

				setTotalNavigationGroups(Math.ceil(deviceEventLogVisualList.length / AMOUNT_TO_SHOW));
				setDeviceEventLogCodes(deviceEventLogVisualList);
				setCurrentPage(0);
			}
		});
	}, []);

	useEffect(() => {
		if (deviceEventLogCodes.length > 0) {
			updateToPage(currentPage);
		}
	}, [currentPage, deviceEventLogCodes]);

	const updateToPage = (page: number) => {
		const currentCodePage: DeviceEventLogVisual[] = [];
		for (let i = 0; i < AMOUNT_TO_SHOW; i++) {
			const deviceCode = deviceEventLogCodes[i + currentPage * AMOUNT_TO_SHOW];
			if (deviceCode) {
				currentCodePage.push(deviceCode);
			}
		}
		setVisibleDeviceEventLogCodes(currentCodePage);
	};

	return (
		<>
{visibleDeviceEventLogCodes.length > 0 ? (
				<div className="DeviceDetailsStatisticsEventLogCodes">
					<div className="DeviceDetailsStatisticsEventLogCodes__list" ref={listRef}>
						{visibleDeviceEventLogCodes.length > 0 &&
							visibleDeviceEventLogCodes.map((errorCode) => (
								<EventLogCodeDisplay
									key={errorCode.key}
									name={errorCode.label}
									severity={errorCode.type}
									date={errorCode.date}
									bit={errorCode.bit}
								/>
							))}
					</div>
					<div className="DeviceDetailsStatisticsEventLogCodes__navigation">
						<DeviceErrorCodesNavigation
							currentPage={currentPage}
							totalPages={totalNavigationGroups}
							toNextPage={handlePagination}
							toPrevPage={handlePagination}
							toSpecificPage={handleToSpecificPage}
						/>
					</div>
				</div>
			) : (
				<div style={{textAlign: 'center', marginTop: '20px', fontWeight: 'bold'}}>{t('EVENT_LOG.NO_RECORDS')}</div>
			)}
</>
	);
};

type EventLogCodeDisplayProps = {
	name: string;
	severity: Status;
	bit: number;
	date: string;
};

const EventLogCodeDisplay: React.FC<EventLogCodeDisplayProps> = ({ name, severity, bit, date }) => {
	const { t } = useTranslation();
	const issueSeverityColour = GetDeviceEventLogColour(severity);
	return (
		<div className="EventLogCodeDisplay__wrapper">
			<div
				className="EventLogCodeDisplay"
				style={{ backgroundColor: issueSeverityColour.background, borderColor: issueSeverityColour.border }}
			>
				<div className="EventLogCodeDisplay__icon">
					{severity === Status.error ? (
						<IconError></IconError>
					) : severity === Status.serviceDue ? (
						<IconWarning></IconWarning>
					) : severity === Status.fillLevel && bit === 18 ? (
						<IconFillFull></IconFillFull>
					) : severity === Status.fillLevel && bit === 19 ? (
						<IconFillOverFull></IconFillOverFull>
					) : severity === Status.fillLevel && bit === 51 ? (
						<IconFillTooFullToBaleOut></IconFillTooFullToBaleOut>
					) : severity === Status.fillLevel && bit === 53 ? (		
						<IconFill1></IconFill1>
					) : severity === Status.fillLevel && bit === 54 ? (
						<IconFill2></IconFill2>
					) : severity === Status.fillLevel && (bit === 37 || bit === 55) ? (
						<IconFill3></IconFill3>
					) : severity === Status.fillLevel && bit === 201 ? (
						<IconFill0></IconFill0>
					) : severity === Status.fillLevel && (bit === 202 || bit === 203) ? (
						<IconFill0></IconFill0>
					) : null}
				</div>
				<div className="EventLogCodeDisplay__message" style={{ color: issueSeverityColour.textColor }}>
					{t(name) ?? name}
				</div>
				<div className="EventLogCodeDisplay__date" style={{ color: issueSeverityColour.textColor }}>
					{getFormattedDateString(new Date(date))}
				</div>
			</div>
		</div>
	);
};
