import UserType from '../../models/UserType';

export default interface PostUser {
  email: string;
  name: string;
  permission: Permission;
  groupId: number;
  phoneNumber: string;
  hasServicetoolAccess: boolean;
  platformUrl: string;
}

export enum Permission {
  RegularUser = 0,
  SuperUser = 1,
  Admin = 2,
  NotificationUser = -1
}

export const toPermission = (userType: UserType): Permission => {
  switch (userType) {
    case UserType.RegularUser:
      return Permission.RegularUser;
    case UserType.SuperUser:
      return Permission.SuperUser;
    case UserType.Admin:
      return Permission.Admin;
    case UserType.NotificationUser:
        return Permission.NotificationUser;
  }
};
