import React, { useEffect, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import SimpleNotificationGroupDeviceCode from '../../../../../models/SimpleNotificationGroupDeviceCode';

import './NotificationGroupInfo.css';
import { DeviceCodeType } from "../../../../../data/models/devices/DeviceCode";

export interface NotificationGroupInfoProps {
    notificationGroupDeviceCodes: SimpleNotificationGroupDeviceCode[];
}

const NotificationGroupInfo: React.FC<NotificationGroupInfoProps> = ({ notificationGroupDeviceCodes }) => {
    const { t }: UseTranslationResponse = useTranslation();

    const [errorList, setErrorList] = useState<SimpleNotificationGroupDeviceCode[]>();
    const [warningList, setWarningList] = useState<SimpleNotificationGroupDeviceCode[]>();
    const [fillLevelList, setFillLevelList] = useState<SimpleNotificationGroupDeviceCode[]>();
    const [serviceList, setServiceList] = useState<SimpleNotificationGroupDeviceCode[]>();

    useEffect(() => {
        let errors = notificationGroupDeviceCodes.filter(c => c.type === DeviceCodeType.ERROR);
        setErrorList(errors);

        let warnings = notificationGroupDeviceCodes.filter(c => c.type === DeviceCodeType.WARNING);
        setWarningList(warnings);

        let fillLevels = notificationGroupDeviceCodes.filter(c => c.type === DeviceCodeType.FILLLEVEL);
        setFillLevelList(fillLevels);

        let services = notificationGroupDeviceCodes.filter(c => c.type === DeviceCodeType.SERVICE);
        setServiceList(services);

    }, [notificationGroupDeviceCodes]);

    return (
        <>
            {errorList && errorList.length > 0 && (
                    <ul className="notificationinfo__card">
                        <li>Errors</li>
                        <li>
                            <ul>
                                {errorList.map((e, i) => {
                                    return (
                                        <li key={`error${i}`}>{t(e.name)}</li>
                                    );
                                })
                                }
                            </ul>
                        </li>
                    </ul>
            )}
            {warningList && warningList.length > 0 && (
                <ul className="notificationinfo__card">
                    <li>Warning</li>
                    <li>
                        <ul>
                            {warningList.map((e, i) => {
                                return (
                                    <li key={`warning${i}`}>{t(e.name)}</li>
                                );
                            })
                            }
                        </ul>
                    </li>
                </ul>
            )}
            {fillLevelList && fillLevelList.length > 0 && (
                <ul className="notificationinfo__card">
                    <li>Fill Level</li>
                    <li>
                        <ul>
                            {fillLevelList.map((e, i) => {
                                return (
                                    <li key={`filllevel${i}`}>{t(e.name)}</li>
                                );
                            })
                            }
                        </ul>
                    </li>
                </ul>
            )}
            {serviceList && serviceList.length > 0 && (
                <ul className="notificationinfo__card">
                    <li>Service</li>
                    <li>
                        <ul>
                            {serviceList.map((e, i) => {
                                return (
                                    <li key={`service${i}`}>{t(e.name)}</li>
                                );
                            })
                            }
                        </ul>
                    </li>
                </ul>
            )}
        </>
    );
};

export default NotificationGroupInfo;