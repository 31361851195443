import React, { useState, useEffect } from 'react';
import Topbar from '../../../shared/topbar/Topbar';
import TableList from '../../../shared/list/TableList';
import Group from '../../../models/Group';
import SimpleNotificationGroup from '../../../models/SimpleNotificationGroup';
import { ReactComponent as MoreSvg } from '../../../assets/common/moreBtn.svg';
import { ReactComponent as EditSvg } from '../../../assets/settings/iconEdit.svg';
import './NotificationsList.css';
import ContextMenu from '../../../shared/contextMenu/ContextMenu';
import * as GroupService from '../../../data/services/GroupService';
import Loader from '../../../shared/loader/Loader';
import PatchNotification from './components/patchNotification/patchNotification';
import PatchNotificationGroupEdit from './components/patchNotificationGroupEdit/patchNotificationGroupEdit';
import PatchNotificationUserAdd from './components/patchNotificationUserAdd/patchNotificationUserAdd';
import SearchBar from '../../../shared/searchbar/SearchBar';
import PatchNotificationDevice from './components/patchNotificationDevice/patchNotificationDevice';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import SimpleDevice from '../../../models/SimpleDevice';

const CONTEXT_MENU_WIDTH = 350;
const CHILD_INDENT = 20;

const NotificationsList: React.FC = () => {
    const [groupStructure, setGroupStructure] = useState<Group>();
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuCords, setContextMenuCords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [contextMenuIndex, setContextMenuIndex] = useState(-1);
    const [search, setSearch] = useState('');
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [showPatchGroup, setShowPatchGroup] = useState(false);
    const [showPatchDevice, setShowPatchDevice] = useState(false);
    const [currentSelectedGroup, setCurrentSelectedGroup] = useState<Group>();
    const { t }: UseTranslationResponse = useTranslation();
    const [notificationGroupSelected, setNotificationGroupSelected] = useState<SimpleNotificationGroup>();
    const [showPatchNotificationGroupEdit, setShowPatchNotificationGroupEdit] = useState(false);
    const [notificationGroupToUpdate, setNotificationGroupToUpdate] = useState<SimpleNotificationGroup>();
    const [notificationGroupToDelete, setNotificationGroupToDelete] = useState<SimpleNotificationGroup>();
    const [showPatchNotificationUserAdd, setShowPatchNotificationUserAdd] = useState(false);
    const [currentSelectedDevice, setCurrentSelectedDevice] = useState<SimpleDevice>();

    const fetchGroups = async () => {
        try {
            setLoadingGroups(true);
            const group = await GroupService.GetGroupsWithSelf();
            setGroupStructure(group);
        } finally {
            setLoadingGroups(false);
        }
    };

    const getChildrenFromStructure = () => {
        return groupStructure ? groupStructure.children : [];
    };

    useEffect(() => {
        fetchGroups().then(() => {
            return;
        });
    }, []);

    const openContextMenu = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, indexClicked: number) => {
        e.preventDefault();
        e.stopPropagation();

        if (indexClicked !== contextMenuIndex) {
            const boundingRect = e.currentTarget.getBoundingClientRect();
            setContextMenuCords({
                x: boundingRect.x + boundingRect.width / 2,
                y: boundingRect.y + boundingRect.height / 2,
            });
            setShowContextMenu(true);
            setContextMenuIndex(indexClicked);
        } else {
            closeContextMenu();
        }
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
        setContextMenuIndex(-1);
    };

    const filter = (group: Group) => {
        if (search.length === 0) {
            return true;
        }

        const searchWords = search.toLocaleLowerCase().split(' ');

        let meetsSearch = true;

        searchWords.forEach((element) => {
            if (!group.name.toLocaleLowerCase().includes(element)) {
                meetsSearch = false;
                return;
            }
        });

        return meetsSearch;
    };

    const filterTreeBySearch = (groups: Group[]) => {
        const matching: Group[] = [];
        groups.forEach((element) => {
            const matchingChildren = filterTreeBySearch(element.children);
            if (matchingChildren.length > 0) {
                element.children = matchingChildren;
                matching.push(element);
            } else if (filter(element)) {
                element.children = [];
                matching.push(element);
            }
        });
        return matching;
    };

    const onNotificationSubmit = () => {
        setShowPatchGroup(false);
        fetchGroups().then(() => {
            return;
        });
    };

    const onNotificationGroupDelete = () => {
        setNotificationGroupToDelete(notificationGroupSelected);
        setShowPatchNotificationGroupEdit(false);
        setNotificationGroupSelected(undefined);
    }

    const onNotificationGroupSubmit = (notificationGroup: SimpleNotificationGroup) => {
        setNotificationGroupToUpdate(notificationGroup);
        setShowPatchNotificationGroupEdit(false);
        setNotificationGroupSelected(undefined);
    };

    const groupsToRow = (parentGroup: Group, keyPrefix = 'groups'): React.ReactFragment => {
        return parentGroup.children.map((group, i) => {
            const key = `${keyPrefix}${i}`;
            return (
                <React.Fragment key={key}>
                    <tr>
                        <td className="notificationlist__name-col">
                            <span style={{ paddingLeft: `${(group.nestLevel - 2) * CHILD_INDENT}px` }}>{group.name}</span>
                        </td>
                        <td>{`${group.fleetSizeTotal} machine${group.fleetSizeTotal === 1 ? '' : 's'}`}</td>
                        <td>{`${group.notificationGroupUsersTotal} receiver${group.notificationGroupUsersTotal === 1 ? '' : 's'}`}</td>
                        <td>
                            <MoreSvg
                                title={t('CONTEXT_MENU.TITLE')}
                                className="notificationlist__more-btn"
                                onClick={(e) => {
                                    openContextMenu(e, i);
                                    setCurrentSelectedGroup(group);
                                }}
                            />
                        </td>
                    </tr>
                    {groupsToRow(group, key)}
                </React.Fragment>
            );
        });
    };

    const filteredGroupStructure = () => {
        if (groupStructure) {
            const filteredChildren = filterTreeBySearch(getChildrenFromStructure());
            const groupWithFilteredChildren = { ...groupStructure };
            groupWithFilteredChildren.children = filteredChildren;
            return groupsToRow(groupWithFilteredChildren);
        }
        return undefined;
    };

    const onNotificationGroupEdit = (notificationGroup?: SimpleNotificationGroup) => {
        setNotificationGroupSelected(notificationGroup);
        setCurrentSelectedDevice(notificationGroup?.device);
        setShowPatchNotificationGroupEdit(true);
    };

    const openPatchNotification = () => {
        setShowPatchGroup(true);
    };

    const openPatchNotificationDevice = () => {
        setShowPatchDevice(true);
    };

    const closePatchNotification = () => {
        setCurrentSelectedGroup(undefined);
        setShowPatchGroup(false);
        setShowPatchDevice(false);

        fetchGroups().then(() => {
            return;
        });
    }

    const addNotificationUser = (notificationGroup: SimpleNotificationGroup) => {
        setNotificationGroupSelected(notificationGroup);
        setCurrentSelectedDevice(notificationGroup?.device);
        setShowPatchNotificationUserAdd(true);
    };

    const notificationUsersUpdated = (notificationGroup: SimpleNotificationGroup) => {
        setShowPatchNotificationUserAdd(false);
    }

    return (
        <>
            <Topbar>
                <div className="notificationlist__searchfield">
                    <SearchBar
                        search={search}
                        onChange={(s: any) => setSearch(s)}
                        placeholder={t('GROUPS_LIST_SEARCH_BAR.PLACEHOLDER')}
                    ></SearchBar>
                </div>
            </Topbar>
            <div className="notificationlist__content" onClick={() => closeContextMenu()} onScroll={() => closeContextMenu()}>
                <div className="notificationlist__header">
                    <span className="notificationlist__title">{t('NOTIFICATIONS_LIST.TITLE')}</span>
                </div>
                <TableList
                    header={
                        <tr>
                            <th className="notificationlist__name-col">{t('NOTIFICATIONS_LIST.NAME')}</th>
                            <th>{t('NOTIFICATIONS_LIST.FLEET')}</th>
                            <th colSpan={2}>{t('NOTIFICATIONS_LIST.RECEIVERS')}</th>
                        </tr>
                    }
                >
                    {filteredGroupStructure()}
                </TableList>
                {loadingGroups && !groupStructure && <Loader mode="inline" />}
                <ContextMenu
                    hidden={!showContextMenu}
                    style={{
                        width: CONTEXT_MENU_WIDTH,
                    }}
                    yPlacement={contextMenuCords.y}
                    left={contextMenuCords.x - CONTEXT_MENU_WIDTH}
                    menuItems={[
                        {
                            content: (
                                <div>
                                    <EditSvg className="notificationlist__context-icon" />
                                    <span className="notificationlist__context-text">{t('CONTEXT_MENU.EDIT_NOTIFICATION')}</span>
                                </div>
                            ),
                            onClick: () => {
                                closeContextMenu();
                                openPatchNotification();
                            },
                        },
                        {
                            content: (
                                <div>
                                    <EditSvg className="notificationlist__context-icon" />
                                    <span className="notificationlist__context-text">{t('CONTEXT_MENU.EDIT_NOTIFICATION_DEVICE')}</span>
                                </div>
                            ),
                            onClick: () => {
                                closeContextMenu();
                                openPatchNotificationDevice();
                            },
                        }
                    ]}
                />
                {groupStructure && currentSelectedGroup && (
                    <PatchNotification
                        open={showPatchGroup}
                        handleClose={() => closePatchNotification()}
                        onSubmit={() => onNotificationSubmit()}
                        groupToEdit={currentSelectedGroup}
                        onNotificationGroupEdit={onNotificationGroupEdit}
                        notificationGroupToUpdate={notificationGroupToUpdate}
                        notificationGroupToDelete={notificationGroupToDelete}
                        onAddNotificationUser={addNotificationUser}
                    />
                )}

                {groupStructure && currentSelectedGroup && (
                    <PatchNotificationDevice
                        open={showPatchDevice}
                        handleClose={() => closePatchNotification()}
                        onSubmit={() => onNotificationSubmit()}
                        groupToEdit={currentSelectedGroup}
                        onNotificationDeviceEdit={onNotificationGroupEdit}
                        notificationDeviceToUpdate={notificationGroupToUpdate}
                        notificationDeviceToDelete={notificationGroupToDelete}
                        onAddNotificationUser={addNotificationUser}
                    />
                )}

                <PatchNotificationGroupEdit
                    open={showPatchNotificationGroupEdit}
                    notificationGroupHandleClose={() => setShowPatchNotificationGroupEdit(false)}
                    onSubmit={(notificationGroup: SimpleNotificationGroup) => onNotificationGroupSubmit(notificationGroup)}
                    groupToEdit={currentSelectedGroup}
                    notificationGroupToEdit={notificationGroupSelected}
                    onDelete={() => onNotificationGroupDelete()}
                    deviceToEdit={currentSelectedDevice}
                />

                <PatchNotificationUserAdd
                    open={showPatchNotificationUserAdd}
                    notificationGroupToEdit={notificationGroupSelected}
                    handleClose={() => setShowPatchNotificationUserAdd(false)}
                    onNotificationUsersUpdate={notificationUsersUpdated}
                    deviceToEdit={currentSelectedDevice}
                />
            </div>
        </>
    );
};

export default NotificationsList;