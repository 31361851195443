import React from 'react';
import './DropDownHeader.css';

export interface dropdownheaderprops {
  label?: string;
}
export const DropDownHeader: React.FC<dropdownheaderprops> = (props) => {
  return (
    <>
      <div className="dropdown-header-content">
        {props.label ? <label>{props.label}</label> : null}
        {props.children}
      </div>
    </>
  );
};

export default DropDownHeader;
