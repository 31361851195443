import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCheck } from '../../../assets/common/iconCheck.svg';
import { ReactComponent as IconExit } from '../../../assets/common/iconExitDanitechGrey.svg';
import { noop } from '../../../utils/noop';
import './ProfileTopbar.css';

export const ProfileTopbar: React.FC<{
  setShowProfileInfo: Dispatch<SetStateAction<boolean>>;
  savable: boolean;
  saveProfile: () => void;
}> = ({ setShowProfileInfo, savable, saveProfile }) => {
  const { t } = useTranslation();
  return (
    <div className="ProfileTopbar">
      <button className="ProfileTopbar__button ProfileTopbar__cancel" onClick={() => setShowProfileInfo(false)}>
        <label className="ProfileTopbar__label ProfileTopbar__cancel-label">{t('COMMON.CANCEL')}</label>
        <IconExit className="ProfileTopbar__exit-icon" />
      </button>

      <button
        className="ProfileTopbar__button ProfileTopbar__save"
        style={{ background: savable ? 'var(--acent)' : 'var(--accentgray)', cursor: savable ? 'pointer' : 'default' }}
        onClick={savable ? saveProfile : noop}
      >
        <label
          className="ProfileTopbar__label ProfileTopbar__save-label"
          style={{ cursor: savable ? 'pointer' : 'default' }}
        >
          {t('PROFILE_TOP_BAR.SAVE')}
        </label>
        <IconCheck className="ProfileTopbar__save-icon" />
      </button>
    </div>
  );
};
