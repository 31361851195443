import React, { useEffect, useState } from 'react';
import { OpenServiceTool } from '../../../../../data/services/ClientToolService';
import authenticator from '../../../../../services/Authenticator';
import { CloseButton } from '../../../../../shared/CloseButton/CloseButton';
import { OpenServiceToolButton } from '../../../../../shared/OpenServiceToolButton/OpenServiceToolButton';
import './DeviceDetailsTopBar.css';

interface DeviceDetailsTopBarProps {
  onClick: () => void;
  deviceId: string;
}

export const DeviceDetailsTopBar: React.FC<DeviceDetailsTopBarProps> = ({ onClick, deviceId }) => {
  const serviceToolAccess = authenticator.getAccount().idTokenClaims['extension_Custom_ServicetoolAccess'];
  const platform = navigator.platform.includes('Win');
  const [link, setLink] = useState<string>();

  useEffect(() => {
    OpenServiceTool(deviceId).then((url) => {
      setLink(url);
    });
  }, [deviceId]);
  return (
    <div className="DeviceDetailsTopBar">
      <div className="DeviceDetailsTopBar__service-tool">
        {serviceToolAccess && platform && <OpenServiceToolButton link={link || '#'} />}
      </div>
      <div className="DeviceDetailsTopBar__close">
        <CloseButton onClick={onClick} />
      </div>
    </div>
  );
};
