import React from 'react';
import { DeviceStateValues } from '../../../../../models/DeviceStateValues';
import { DeviceStateTagsDisplay } from '../../../../../shared/DeviceStateTagsDisplay/DeviceStateTagsDisplay';
import './DeviceDetailsHeader.css';

interface DeviceDetailsHeaderProps {
  id: string;
  deviceId: string;
  type: string;
  stateValues: DeviceStateValues;
}

export const DeviceDetailsHeader: React.FC<DeviceDetailsHeaderProps> = ({ id, type, stateValues, deviceId }) => {
  return (
    <div className="DeviceDetailsHeader">
      <div className="DeviceDetailsHeader__title">
        <div className="DeviceDetailsHeader__title--id">{`${id.substring(0, 6)}`}&nbsp;</div>
        <div className="DeviceDetailsHeader__title--type">{`· ${type}`}</div>
      </div>
      <div className="DeviceDetailsHeader__status">
        <DeviceStateTagsDisplay deviceId={deviceId} stateValues={stateValues} />
      </div>
    </div>
  );
};
