import React, { useEffect, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { PutDeviceGroup } from '../../../../data/services/DeviceService';
import Device from '../../../../models/Device';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import Group from '../../../../models/Group';
import UserType from '../../../../models/UserType';
import authentication from '../../../../services/Authenticator';
import ErrorBox from '../../../../shared/errorBox/ErrorBox';
import SlideInModal from '../../../../shared/slideInModal/SlideInModal';
import { FormatErrorsAndWarningsToList } from '../../../../utils/FormatErrorsAndWarningsToList';
import './DeviceDetails.css';
import { DeviceDetailsErrorCodes } from './DeviceDetailsErrorCodes/DeviceDetailsErrorCodes';
import { DeviceDetailsHeader } from './DeviceDetailsHeader/DeviceDetailsHeader';
import { DeviceDetailsInfo } from './DeviceDetailsInfo/DeviceDetailsInfo';
import { DeviceDetailsStatistics } from './DeviceDetailsStatistics/DeviceDetailsStatistics';
import { DeviceDetailsTopBar } from './DeviceDetailsTopBar/DeviceDetailsTopBar';

export interface DeviceDetailsProps {
    isOpen: boolean;
    toggleOpen: () => void;
    device: Device | null;
    deviceUpdated: () => void;
    stateValues: DeviceStateValues;
    groups: Group[];
}

export const DeviceDetails: React.FC<DeviceDetailsProps> = ({
    isOpen,
    toggleOpen,
    device,
    deviceUpdated,
    groups,
    stateValues,
}) => {
    const [selectedGroup, setSelectedGroup] = useState({ id: device?.group?.id, name: device?.group?.name });
    const [groupError, setGroupError] = useState(false);

    const { t }: UseTranslationResponse = useTranslation();

    useEffect(() => {
        if (device) {
            setSelectedGroup({ id: device.group?.id, name: device.group?.name });
        }
    }, [device]);

    useEffect(() => {
        if (!isOpen) {
            setGroupError(false);
        }
    }, [isOpen]);

    const handleSetGroup = (group: Group) => {
        if (device) {
            PutDeviceGroup(device.id, group.id)
                .then(() => {
                    setSelectedGroup({ id: group.id, name: group.name });
                    deviceUpdated();
                })
                .catch(() => setGroupError(true));
        }
    };

    const hasErrorRights = (): boolean => {
        const idTokenClaims = authentication.getAccount().idTokenClaims;
        const loggedInUserRole: UserType = Number.parseInt(idTokenClaims['extension_Custom_Role']) as UserType;
        return loggedInUserRole !== UserType.RegularUser;
    };

    const hasFileEditRights = (): boolean => {
	    const idTokenClaims = authentication.getAccount().idTokenClaims;
	    const loggedInUserRole: UserType = Number.parseInt(idTokenClaims['extension_Custom_Role']) as UserType;
	    return loggedInUserRole !== UserType.RegularUser;
    }
    
    return (
        <SlideInModal width={700} open={isOpen} handleClose={toggleOpen}>
            <DeviceDetailsTopBar onClick={() => toggleOpen()} deviceId={device?.id || ''} />
            <div className="DeviceDetails">
                <DeviceDetailsHeader
                    id={device?.machineId.substring(0, 6) || ''}
                    deviceId={device?.id || ''}
                    type={device?.type || ''}
                    stateValues={stateValues} />
                <DeviceDetailsInfo
                    group={{ id: selectedGroup?.id?.toString() || '', name: selectedGroup?.name || '' }}
                    device={device}
                    setGroup={handleSetGroup}
                    groups={groups}
                    deviceUpdated={deviceUpdated} />
                {groupError && (
                    <ErrorBox
                        className="DeviceDetails__error-box"
                        header={t('COMMON.ERROR')}
                        paragraphs={[t('DEVICE_DETAILS.CUSTOMER_ERROR')]}
                    />
                )}

                {hasErrorRights() &&
                    device &&
                    (FormatErrorsAndWarningsToList(device.deviceStatus).length > 0) && (
                        <DeviceDetailsErrorCodes deviceStatus={device.deviceStatus} />
                    )}
                {device && <DeviceDetailsStatistics device={device} hasStatisticsAccess={hasErrorRights()} hasFileEditRights={hasFileEditRights()} hasEventLogAccess={hasErrorRights()} />}
            </div>
        </SlideInModal>
    );
};
