import { LatLngBounds } from 'leaflet';
import React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import Device from '../../../../models/Device';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import { MapClusterView } from '../MapClusterView/MapClusterView';
import './DeviceMapView.css';

type DeviceMapViewProps = {
	selectedLocation: string;
	devices: Device[];
	setSelectedDevice: (device: Device) => void;
	setBounds: React.Dispatch<React.SetStateAction<LatLngBounds | undefined>>;
	stateValues: DeviceStateValues;
};

const DeviceMapView: React.FC<DeviceMapViewProps> = ({
	selectedLocation,
	devices,
	setSelectedDevice,
	setBounds,
	stateValues,
}) => {
	var position = { lng: 8.4135506, lat: 55.50607074, zoom: 8 };

	if (devices && devices.length > 0) {
		for (var i = 0; i < devices.length; i++) {
			if (devices[i].location && devices[i].location.longitude && devices[i].location.latitude) {
				position = { lng: devices[i].location.longitude || 8.4135506, lat: devices[i].location.latitude || 55.50607074, zoom: 8 };
				break;
			}
		}
	}

	return (
		<div className="DeviceMapView">
			<Map center={[position.lat, position.lng]} zoom={position.zoom} preferCanvas={true}>
				<TileLayer
					// attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url={`https://atlas.microsoft.com/map/tile?subscription-key=${process.env.REACT_APP_MAP_KEY}&api-version=2.0&tilesetId=microsoft.base.road&zoom={z}&x={x}&y={y}&tileSize=256&language=en-US&view=Auto`}
				/>
				<MapClusterView
					setBounds={setBounds}
					devices={devices || []}
					selectedLocation={selectedLocation}
					setSelectedDevice={setSelectedDevice}
					stateValues={stateValues}
				/>
			</Map>
		</div>
	);
};
export default DeviceMapView;
