import React, { CSSProperties } from 'react';
import { ReactComponent as IconError } from '../../assets/popup/iconError.svg';
import './ErrorBox.css';

export interface ErrorBoxProps {
  paragraphs: string[];
  header?: string;
  className?: string;
  style?: CSSProperties;
}

export const ErrorBox: React.FC<ErrorBoxProps> = ({ paragraphs, className, style, header }) => {
  return (
    <div className={`error-box__message-box ${className}`} style={style}>
      <div className="error-box__icon">
        <IconError />
      </div>
      {header && <p className="error-box__header">{header}</p>}
      {paragraphs.map((data, index) => {
        return <p key={index}>{data}</p>;
      })}
    </div>
  );
};

export default ErrorBox;
