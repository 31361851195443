import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateProfile } from '../../data/services/ProfileService';
import { logout, resetPassword } from '../../services/Authenticator';
import ErrorBox from '../../shared/errorBox/ErrorBox';
import { InputField } from '../../shared/InputField/InputField';
import { noop } from '../../utils/noop';
import './Profile.css';
import { ProfileTopbar } from './ProfileTopbar/ProfileTopbar';

type ProfileProps = {
  id: string;
  name: string;
  email: string;
  phone: string;
  setShowProfileInfo: Dispatch<SetStateAction<boolean>>;
  shown: boolean;
};

export const Profile: React.FC<ProfileProps> = ({ id, name, email, phone, setShowProfileInfo, shown }) => {
  const { t } = useTranslation();
  const [updateError, setUpdateError] = useState<Error | null>(null);

  const [localName, setLocalName] = useState(name);

  const [nameChanged, setNameChanged] = useState(false);

  // Check if name is changed
  useEffect(() => {
    if (name !== localName) {
      setNameChanged(true);
    } else {
      setNameChanged(false);
    }
  }, [localName, name]);

  const handleUpdateProfile = () => {
    UpdateProfile(localName, phone)
      .then(() => {
        setUpdateError(null);
        logout();
        setShowProfileInfo(false);
      })
      .catch((err) => setUpdateError(err));
  };

  useEffect(() => {
    // Reset when closed
    if (!shown) {
      setLocalName(name);
      setUpdateError(null);
    }
  }, [shown, name]);

  return (
    <div className="Profile">
      <div className="Profile__top">
        <ProfileTopbar
          setShowProfileInfo={setShowProfileInfo}
          savable={nameChanged}
          saveProfile={handleUpdateProfile}
        />
      </div>
      <div className="Profile__content">
        <div className="Profile__title">{t('PROFILE.YOUR_PROFILE')}</div>
        <div className="Profile__form">
          <InputField value={localName} setValue={(input) => setLocalName(input)} label={t('COMMON.NAME')} />
          <InputField value={email} setValue={noop} label={t('COMMON.EMAIL')} disabled={true} />
          <InputField value={phone} setValue={noop} label={t('PROFILE.PHONE_OPT')} disabled={true} />
        </div>
        {updateError && (
          <ErrorBox className="Profile__error" paragraphs={[t('PROFILE.ERROR_UPDATE')]} header={t('COMMON.ERROR')} />
        )}
        <div className="Profile__password">
          <div className="Profile__password-view">
            <InputField
              value="********"
              setValue={noop}
              label={t('PROFILE.PASSWORD')}
              disabled={true}
              type="password"
            />
            <div className="Profile__change-password-button">
              <button className="Profile__change-password-button__button" onClick={() => resetPassword()}>
                {t('PROFILE.CHANGEPW')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
