import React, { ReactElement } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { DeviceStatus } from '../../../../data/models/devices/DeviceStatus';
import Device from '../../../../models/Device';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import { DeviceIssuesDisplaySmall } from '../../../../shared/DeviceIssuesDisplaySmall/DeviceIssuesDisplaySmall';
import { DeviceLastUpdatedDisplay } from '../../../../shared/DeviceLastUpdatedDisplay/DeviceLastUpdatedDisplay';
import { DeviceStateTagsDisplay } from '../../../../shared/DeviceStateTagsDisplay/DeviceStateTagsDisplay';
import { FormatErrorsAndWarningsToList } from '../../../../utils/FormatErrorsAndWarningsToList';
import './MapPopupContent.css';

type MapPopupProps = {
  device: Device;
  id: string;
  title?: string;
  client?: string;
  status: DeviceStatus;
  stateValues: DeviceStateValues;
  setSelectedDevice: (device: Device) => void;
};

export const MapPopupContent: React.FC<MapPopupProps> = ({
  device,
  id,
  title = '',
  client,
  status,
  stateValues,
  setSelectedDevice,
}: MapPopupProps): ReactElement => {
  const allWarningAndErrorsFormatted = FormatErrorsAndWarningsToList(status);

  const { t }: UseTranslationResponse = useTranslation();

  return (
    <div className="MapPopup">
      <div className="MapPopupContent">
        <div className="MapPopupContent__header">
          <div className="MapPopupContent__header-title">
            <h2 className="MapPopupContent__header-title-text">{title}</h2>
          </div>
          <div className="MapPopupContent__header-state">
            <DeviceStateTagsDisplay deviceId={device.id} stateValues={stateValues} />
          </div>
          <p className="MapPopupContent__header-subtitle-text">
            {client || <i className="MapPopupContent__header-subtitle-text-no-customer">Unassigned</i>}
          </p>
          <div className="MapPopupContent__header-last-updated">
            {device.lastConnected && <DeviceLastUpdatedDisplay lastUpdated={device.lastConnected} />}
          </div>
        </div>
        <div className="MapPopupContent__events">
          {allWarningAndErrorsFormatted &&
            allWarningAndErrorsFormatted.length > 0 &&
            allWarningAndErrorsFormatted.map((warning, index) => (
              <DeviceIssuesDisplaySmall key={warning.label + index} status={warning.status} message={warning.label} />
            ))}
        </div>
      </div>
      <div className="MapPopupContent__action">
        <button className="MapPopupContent__action-button" onClick={() => setSelectedDevice(device)}>
          {t('MARKPOPUP.SEE_DETAILS')}
        </button>
      </div>
    </div>
  );
};
