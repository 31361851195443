export interface DeviceEventLogCode {
	deviceEventLogId: number;
    name: string;
    bitNumber: number;
    type: DeviceCodeType;
    date: string;
}

export enum DeviceCodeType {
    ERROR,
    WARNING,
    FILLLEVEL,
    SERVICE
}
