import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Location } from '../../../../../../data/models/devices/Location';
import ErrorBox from '../../../../../../shared/errorBox/ErrorBox';
import { ReactComponent as IconError } from '../../../../../../assets/popup/iconError.svg';
import './DeviceDetailsLocationField.css';

const LAT_MAX = 90;
const LAT_MIN = -90;
const LNG_MAX = 180;
const LNG_MIN = -180;

interface props {
  location: Location | undefined;
  updateLocation: (newLatitude: number, newLongtitude: number) => void;
  canEdit: boolean;
  failed: boolean;
  updating: boolean;
}

export const DeviceDetailsLocationField: React.FC<props> = ({
  location,
  updateLocation,
  canEdit,
  failed,
  updating,
}) => {
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editLatValue, setEditLatValue] = useState<string | undefined>(location?.latitude + '');
  const [editLngValue, setEditLngValue] = useState<string | undefined>(location?.longitude + '');

  const locationLink = `https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`;

  useEffect(() => {
    setEditLatValue(location?.latitude + '');
    setEditLngValue(location?.longitude + '');
    setIsEditMode(false);
  }, [location?.latitude, location?.longitude, failed]);

  const cancelEdit = () => {
    setEditLatValue(location?.latitude + '');
    setEditLngValue(location?.longitude + '');
    setIsEditMode(false);
  };

  const startEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (canEdit) {
      event.stopPropagation();
      setIsEditMode(true);
    }
  };

  const save = () => {
    if (editLatValue && editLngValue) {
      updateLocation(+editLatValue, +editLngValue);
    }
  };

  const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const isChanged =
    (!!editLatValue && +editLatValue !== location?.latitude) || (!!editLngValue && +editLngValue !== location?.longitude);

  const isInvalid =
    isEditMode &&
    ((!!editLatValue && (+editLatValue > LAT_MAX || +editLatValue < LAT_MIN)) ||
      (!!editLngValue && (+editLngValue > LNG_MAX || +editLngValue < LNG_MIN)));

  return (
    <>
      <div className="DeviceDetailsLocationField__container-left">
        <div className="DeviceDetailsLocationField__location-label">{t('D_DETAILS.POSITION')}</div>
        {isEditMode ? (
          <>
            <div onClick={cancelEdit} className="DeviceDetailsLocationField_overlay" />
            <form
              id="locationForm"
              className="DeviceDetailsLocationField_controls DeviceDetailsLocationField_input-container"
            >
              <input
                onClick={stopPropagation}
                type="number"
                className="DeviceDetailsLocationField__input DeviceDetailsLocationField__location-value"
                value={editLatValue}
                onChange={(v) => setEditLatValue(v.target.value)}
              />
              <span className="DeviceDetailsLocationField__location-value">,&nbsp;</span>
              <input
                onClick={stopPropagation}
                type="number"
                className="DeviceDetailsLocationField__input DeviceDetailsLocationField__location-value"
                value={editLngValue}
                onChange={(v) => setEditLngValue(v.target.value)}
              />
              {isInvalid && (
                <IconError
                  title={t('D_DETAILS.INVALID_LOCATION_TITLE')}
                  className="DeviceDetailsLocationField__location-invalid-icon"
                  style={{ height: '14px', marginLeft: '5px' }}
                />
              )}
            </form>
          </>
        ) : (
          <div
            onClick={startEdit}
            className="DeviceDetailsLocationField__location-value"
          >{`${location?.latitude?.toString()}, ${location?.longitude?.toString()}`}</div>
        )}
      </div>
      <div className="DeviceDetailsLocationField__container-right">
        <div className="DeviceDetailsLocationField__location-action-container">
          {isEditMode ? (
            <button
              disabled={updating || isInvalid || !isChanged}
              form="locationForm"
              className="DeviceDetailsLocationField_controls DeviceDetailsLocationField__location-action"
              onClick={save}
            >
              {t('COMMON.SAVE')}
            </button>
          ) : (
            <a
              href={locationLink}
              target="_blank"
              rel="noopener noreferrer"
              className="DeviceDetailsLocationField__location-action"
            >
              {t('D_DETAILS.GET_DIRECTIONS')}
            </a>
          )}
        </div>
      </div>
      {failed && (
        <ErrorBox
          className="DeviceDetailsLocationField__error"
          paragraphs={[t('D_DETAILS.UPDATE_LOCATION_ERROR')]}
          header={t('COMMON.ERROR')}
        />
      )}
    </>
  );
};
