import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SlideInModal from '../../../../../shared/slideInModal/SlideInModal';
import SearchBar from '../../../../../shared/searchbar/SearchBar';
import { ReactComponent as CancelSvg } from '../../../../../assets/common/iconExitGrey.svg';
import iconMinus from '../../../../../assets/common/iconMinus.png';
import iconPlus from '../../../../../assets/common/iconPlus.png';
import Button from '../../../../../shared/button/Button';
import Device from '../../../../../models/Device';
import './DeviceSelector.css';

export interface dialogDeviceSelectorProps {
    closeClick: () => void;
    saveClick: (deviceList: Device[]) => void;
    open: boolean;
    deviceListSelected: Device[];
    deviceList: Device[];
}

export const DeviceSelector: React.FC<dialogDeviceSelectorProps> = ({ ...props }) => {
    const { t } = useTranslation();
    const [devicesSelected, setDevicesSelected] = useState<Device[]>(props.deviceListSelected);
    const [devicesAvailable, setDevicesAvailable] = useState<Device[]>([]);
    const [devicesFiltered, setDevicesFiltered] = useState<Device[]>([]);
    const [search, setSearch] = useState('');
    const [selectAllDisabled, setSelectAllDisabled] = useState(true);

    const buildFilter = () => {
        let deviceFiltered = devicesAvailable.filter(c =>
            c.id.toLowerCase().includes(search.toLowerCase()) ||
            c.machineId.toLowerCase().includes(search.toLowerCase()) ||
            c.groupTree?.toLowerCase().includes(search.toLowerCase()) ||
            c.type.toLowerCase().includes(search.toLowerCase()) ||
            c.site?.toLowerCase().includes(search.toLowerCase()) ||
            c.customer?.name?.toLowerCase().includes(search.toLowerCase()) ||
            c.wasteFraction?.toLowerCase().includes(search.toLowerCase())
        );
        setDevicesFiltered([...deviceFiltered]);
    };

    useEffect(() => {
        if (props.open) {
            setSearch("");

            let _devicesAvailable: Device[] = [];

            for (var i = 0; i < props.deviceList.length; i++) {
                let deviceAvailableFindIndex = props.deviceListSelected.findIndex(c => c.id === props.deviceList[i].id);

                if (deviceAvailableFindIndex < 0) {
                    _devicesAvailable.push(props.deviceList[i]);
                }
            }

            setDevicesFiltered([..._devicesAvailable]);
            setDevicesAvailable([..._devicesAvailable]);
            setDevicesSelected([...props.deviceListSelected]);
        }
    }, [props.open]);

    useEffect(() => {
        buildFilter();
    },
        [search]);

    useEffect(() => {
            setSelectAllDisabled(devicesFiltered.length === 0);
        },
        [devicesFiltered]);

    const isValid = () => {
        return devicesSelected.findIndex(c => c.selected) > -1;
    }

    const selectDevice = (device: Device) => {
        devicesSelected.push(device);
        devicesSelected.sort((a, b) => (a.id > b.id) ? 1 : -1);

        let filterIndex = devicesFiltered.findIndex(c => c.id === device.id);
        if (filterIndex > -1) {
            devicesFiltered.splice(filterIndex, 1);
            devicesFiltered.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }

        let availableIndex = devicesAvailable.findIndex(c => c.id === device.id);
        if (availableIndex > -1) {
            devicesAvailable.splice(availableIndex, 1);
            devicesAvailable.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }

        setDevicesSelected([...devicesSelected]);
        setDevicesFiltered([...devicesFiltered]);
        setDevicesAvailable([...devicesAvailable]);
    }

    const removeSelectedDevice = (device: Device) => {
        devicesFiltered.push(device);
        devicesAvailable.push(device);

        devicesFiltered.sort((a, b) => (a.id > b.id) ? 1 : -1);
        devicesAvailable.sort((a, b) => (a.id > b.id) ? 1 : -1);

        let selectedIndex = devicesSelected.findIndex(c => c.id === device.id);
        if (selectedIndex > -1) {
            devicesSelected.splice(selectedIndex, 1);
            devicesSelected.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }

        setDevicesSelected([...devicesSelected]);
        setDevicesFiltered([...devicesFiltered]);
        setDevicesAvailable([...devicesAvailable]);
    }

    const selectAllDevices = () => {
        for (var i = 0; i < devicesFiltered.length; i++) {
            let deviceClone = JSON.parse(JSON.stringify(devicesFiltered[i])) as Device;
            devicesSelected.push(deviceClone);
        }

        devicesSelected.sort((a, b) => (a.id > b.id) ? 1 : -1);

        setDevicesSelected([...devicesSelected]);
        
        devicesAvailable.splice(0, devicesAvailable.length);
        devicesFiltered.splice(0, devicesFiltered.length);

        setDevicesSelected([...devicesSelected]);
        setDevicesFiltered([]);
        setDevicesAvailable([]);
    }

    const removeAllSelectedDevices = () => {
        setDevicesAvailable([]);

        for (var i = 0; i < devicesSelected.length; i++) {
            let deviceClone = JSON.parse(JSON.stringify(devicesSelected[i])) as Device;
            devicesAvailable.push(deviceClone);
        }

        devicesAvailable.sort((a, b) => (a.id > b.id) ? 1 : -1);

        setDevicesAvailable([...devicesAvailable]);
        setDevicesFiltered([...devicesAvailable]);

        devicesSelected.splice(0, devicesSelected.length);
        setDevicesSelected([]);
    }

    const saveClick = () => {
        props.saveClick(devicesSelected);
    }

    return (
        <SlideInModal width={700} open={props.open} handleClose={props.closeClick}>
            <div className="deviceselectorbox__header">
                <div className="deviceselectorbox__header-items">
                    <div className="deviceselectorbox_control__cancel" onClick={props.closeClick}>
                        <span>{t('COMMON.CANCEL')}</span>
                        <CancelSvg className="deviceselectorbox_control__cancel-icon" />
                    </div>
                    <Button className="deviceselectorbox_control__submit-button" disabled={!isValid()} onClick={saveClick}>
                        {t('PATCH_USER.SAVE')}
                    </Button>
                </div>
            </div>
            <div className="deviceselectorbox__body">
                <div className="deviceselectorbox__body-title">Select devices</div>

                <div className="deviceselectorbox__selectorbox">
                    <div className="deviceselectorbox__selectorbox_removeselect">
                        <Button className="deviceselectorbox_control__removeall-button" disabled={false} onClick={removeAllSelectedDevices}>
                            REMOVE ALL
                        </Button>
                    </div>
                    <div className="deviceselectorbox__selectorbox_removeselect">
                        <Button className="deviceselectorbox_control__selectall-button" disabled={selectAllDisabled} onClick={selectAllDevices}>
                            SELECT ALL
                        </Button></div>
                    <div className="deviceselectorbox__selectorbox_selected">
                        <div className="deviceselectorbox__devicelist">
                            {devicesSelected.map((d) => {
                                return (
                                    <React.Fragment key={d.id}>
                                        <a href="#" onClick={() => removeSelectedDevice(d)} className="deviceselectorbox__devicelist-deviceitem">
                                            <div>
                                                <div className="deviceselectorbox__devicelist-deviceitem_nametype">
                                                    <div><strong>{d.machineId.substring(0, 6)}</strong></div>
                                                    <div>{d.type}</div>
                                                </div>
                                                <div className="deviceselectorbox__devicelist-deviceitem_customersite">
                                                    <div>{d?.customer?.name || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}</div>
                                                    <div>{d.site || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}</div>
                                                </div>
                                            </div>
                                            <div className="deviceselectorbox__devicelist-deviceitem_addremove"><img src={iconMinus} /></div>
                                        </a>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                    <div className="deviceselectorbox__selectorbox_search">

                        <div className="deviceselectorbox_search">
                            <SearchBar
                                search={search}
                                onChange={(s) => setSearch(s)}
                                placeholder={t('REPORT.DEVICE_SEARCH_PLACEHOLDER')}
                                collapsed={false}
                            ></SearchBar>
                        </div>
                        <div className="deviceselectorbox_search_result">
                            {devicesFiltered.map((d) => {
                                return (
                                    <React.Fragment key={d.id}>
                                        <a href="#" onClick={() => selectDevice(d)} className="deviceselectorbox__devicelist-deviceitem">
                                            <div>
                                                <div className="deviceselectorbox__devicelist-deviceitem_nametype">
                                                    <div><strong>{d.machineId.substring(0, 6)}</strong></div>
                                                    <div>{d.type}</div>
                                                </div>
                                                <div className="deviceselectorbox__devicelist-deviceitem_customersite">
                                                    <div>{d?.customer?.name || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}</div>
                                                    <div>{d.site || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}</div>
                                                </div>
                                            </div>
                                            <div className="deviceselectorbox__devicelist-deviceitem_addremove"><img src={iconPlus} /></div>
                                        </a>
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </SlideInModal>
    );
};