import React, { ReactElement, useRef } from 'react';
import './ContextMenu.css';

interface ContextMenuProps {
  menuItems: { content: ReactElement; onClick: () => void }[];
  style?: React.CSSProperties;
  hidden?: boolean; // Use this when the context menu should go up when close to the bottom of the screen, instead of conditional rendering
  yPlacement: number;
  left?: number;
  right?: number;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  menuItems,
  style = {},
  hidden = false,
  yPlacement,
  left,
  right,
}) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const getYOffset = () => {
    const current = contextMenuRef.current;
    const boundingRect = current?.getBoundingClientRect();
    const isOutside = boundingRect && yPlacement + boundingRect.height > window.innerHeight;
    const invertDirection = boundingRect && isOutside && yPlacement - boundingRect.height >= 0;

    if (invertDirection && current) {
      // Go up
      return current.clientHeight;
    } else {
      // Go down (default)
      return 0;
    }
  };

  // Set styles
  style.position = 'absolute';
  style.top = yPlacement - getYOffset();
  if (hidden) {
    // Outside Screen to still be able to retrieve element height
    style.left = '-1000px';
  } else {
    left && (style.left = left);
    right && (style.right = right);
  }

  return (
    <div ref={contextMenuRef} style={style} className="contextmenu">
      {menuItems.map((m, i) => {
        return (
          <div key={`context-div-${i}`} onClick={() => m.onClick()}>
            {m.content}
          </div>
        );
      })}
    </div>
  );
};

export default ContextMenu;
