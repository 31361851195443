import Group from '../../models/Group';

export interface GroupStructureItem {
    id: number;
    name: string;
    level: number;
    userCount: number;
    deviceCount: number;
    notificationGroupUsersTotal: number;
    children: GroupStructureItem[];
    userTotal: number;
    deviceTotal: number;
}

export const convertToGroup = (dto: GroupStructureItem): Group => {
    return {
        id: dto.id,
        name: dto.name,
        nestLevel: dto.level,
        usersCount: dto.userCount,
        fleetSize: dto.deviceCount,
        notificationGroupUsersTotal: dto.notificationGroupUsersTotal,
        children: dto.children.map((c) => convertToGroup(c)),
        usersTotal: dto.userTotal,
        fleetSizeTotal: dto.deviceTotal
    };
};
