import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconWrenchGrey } from '../../assets/common/iconServiceDanitechGrey.svg';
import './OpenServiceToolButton.css';

interface OpenServiceToolButtonProps {
  link: string;
}

export const OpenServiceToolButton: React.FC<OpenServiceToolButtonProps> = ({ link = '#' }) => {
  const { t } = useTranslation();
  return (
    <a className="OpenServiceToolButton" href={link} target="_blank" rel="noopener noreferrer">
      <div className="OpenServiceToolButton__text">{t('SERVICE_TOOL.OPEN')}</div>
      <div className="OpenServiceToolButton__icon">
        <IconWrenchGrey />
      </div>
    </a>
  );
};
