import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { En as en } from '../translations/English';

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: 'en',
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'production' ? false : true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
