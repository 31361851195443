import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconError } from '../../../../../assets/popup/iconError.svg';
import { ReactComponent as IconWarning } from '../../../../../assets/popup/iconWarning.svg';
import { DeviceStatus } from '../../../../../data/models/devices/DeviceStatus';
import { Status } from '../../../../../models/Device';
import { FormatErrorsAndWarningsToList } from '../../../../../utils/FormatErrorsAndWarningsToList';
import { GetDeviceWarningColour } from '../../../../../utils/GetDeviceWarningColour';
import './DeviceDetailsErrorCodes.css';
import { DeviceErrorCodesNavigation } from './DeviceErrorCodesNavigation/DeviceErrorCodesNavigation';

type DeviceDetailsErrorCodesProps = {
  deviceStatus: DeviceStatus;
};

interface DeviceCodeVisual {
  bit: number;
  label: string;
  status: Status;
}

export const DeviceDetailsErrorCodes: React.FC<DeviceDetailsErrorCodesProps> = ({ deviceStatus }) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [deviceCodes, setDeviceCodes] = useState<DeviceCodeVisual[]>([]);
  const [visibleDeviceCodes, setVisibleDeviceCodes] = useState<DeviceCodeVisual[]>([]);
  const [totalNavigationGroups, setTotalNavigationGroups] = useState<number>(0);
  const AMOUNT_TO_SHOW = 4;

  const handlePagination = (direction: number) => {
    if (currentPage + direction <= totalNavigationGroups - 1 && currentPage + direction >= 0) {
      setCurrentPage(currentPage + direction);
    }
  };

  const handleToSpecificPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const newDeviceCodes = FormatErrorsAndWarningsToList(deviceStatus);
    setTotalNavigationGroups(Math.ceil(newDeviceCodes.length / AMOUNT_TO_SHOW));
    setCurrentPage(0);
    setDeviceCodes(newDeviceCodes);
  }, [deviceStatus]);

  useEffect(() => {
    if (deviceCodes.length > 0) {
      updateToPage(currentPage);
    }
  }, [currentPage, deviceCodes]);

  const updateToPage = (page: number) => {
    const currentCodePage: DeviceCodeVisual[] = [];
    for (let i = 0; i < AMOUNT_TO_SHOW; i++) {
      const deviceCode = deviceCodes[i + currentPage * AMOUNT_TO_SHOW];
      if (deviceCode) {
        currentCodePage.push(deviceCode);
      }
    }
    setVisibleDeviceCodes(currentCodePage);
  };

  return (
    <div className="DeviceDetailsErrorCodes">
      <div className="DeviceDetailsErrorCodes__list" ref={listRef}>
        {visibleDeviceCodes.length > 0 &&
          visibleDeviceCodes.map((errorCode) => (
            <ErrorCodeDisplay
              key={errorCode.bit}
              name={errorCode.label}
              severity={errorCode.status}
            />
          ))}
      </div>
      <div className="DeviceDetailsErrorCodes__navigation">
        <DeviceErrorCodesNavigation
          currentPage={currentPage}
          totalPages={totalNavigationGroups}
          toNextPage={handlePagination}
          toPrevPage={handlePagination}
          toSpecificPage={handleToSpecificPage}
        />
      </div>
    </div>
  );
};

type ErrorCodeDisplayProps = {
  name: string;
  severity: Status;
};

const ErrorCodeDisplay: React.FC<ErrorCodeDisplayProps> = ({ name, severity }) => {
  const { t } = useTranslation();
  const issueSeverityColour = GetDeviceWarningColour(severity);
  return (
    <div className="ErrorCodeDisplay__wrapper">
      <div
        className="ErrorCodeDisplay"
        style={{ backgroundColor: issueSeverityColour.background, borderColor: issueSeverityColour.border }}
      >
        <div className="ErrorCodeDisplay__icon">
          {severity === Status.error ? (
            <IconError></IconError>
          ) : severity === Status.serviceDue ? (
            <IconWarning></IconWarning>
          ) : null}
        </div>
        <div className="ErrorCodeDisplay__message" style={{ color: issueSeverityColour.textColor }}>
          {t(name)}
        </div>
      </div>
    </div>
  );
};
