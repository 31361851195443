import Leaflet, { DivIconOptions } from 'leaflet';
import { KFormatter } from './KFormatter';

interface CustomClusterIcon extends DivIconOptions {
  html: string;
}

export const CreateClusterCustomIcon = (cluster: Leaflet.MarkerCluster): Leaflet.DivIcon => {
  const clusterStyle = ['marker-cluster-custom'];
  const childMarkers = cluster.getAllChildMarkers();

  if (childMarkers.length < 10) {
    let warning = false;
    const error = childMarkers.some((marker) => {
      const icon = marker.getIcon();
      const iconOptions = icon.options as CustomClusterIcon;

      const iconHtml: string = iconOptions?.html;
      if (!warning) {
        warning = iconHtml.includes('var(--warning)');
      }
      return iconHtml.includes('var(--error)');
    });

    if (error) {
      clusterStyle.push('marker-cluster-custom--error');
    } else if (warning) {
      clusterStyle.push('marker-cluster-custom--warning');
    }
  } else {
    clusterStyle.push('marker-cluster-custom--many');
  }

  return Leaflet.divIcon({
    html: `<span>${KFormatter(cluster.getChildCount())}</span>`,
    className: clusterStyle.join(' '),
    iconSize: Leaflet.point(40, 40, true),
  });
};
