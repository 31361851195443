import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Device from '../../../../../models/Device';
import Group from '../../../../../models/Group';
import UserType from '../../../../../models/UserType';
import DropDown from '../../../../../shared/dropDown/DropDown';
import DropDownChildren from '../../../../../shared/dropDown/dropDownChildren/DropDownChildren';
import DropDownHeader from '../../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../../shared/dropDown/dropDownItem/DropDownItem';
import authenticator from '../../../../../services/Authenticator';
import './DeviceDetailsInfo.css';
import { DeviceDetailsLocationField } from './DeviceDetailsLocationField/DeviceDetailsLocationField';
import { PutDeviceLocation } from '../../../../../data/services/DeviceService';
import { getDeviceFillLevelIcon, getDeviceFillLevelTextKey } from '../../../../../utils/GetDeviceFillLevel';

type DeviceDetailsInfoProps = {
  device: Device | null;
  group: { id: string; name: string };
  setGroup: (group: Group) => void;
  deviceUpdated: () => void;
  groups: Group[];
};

export const DeviceDetailsInfo: React.FC<DeviceDetailsInfoProps> = ({
  device,
  group: group,
  setGroup: setGroup,
  deviceUpdated,
  groups,
}) => {
  const { t } = useTranslation();
  const [deviceLocationUpdateFailed, setDeviceLocationUpdateFailed] = useState(false);
  const [deviceLocationUpdating, setDeviceLocationUpdating] = useState(false);

  const isUserAdmin = () => {
    const loggedInUserType: UserType = Number.parseInt(
      authenticator.getAccount().idTokenClaims['extension_Custom_Role'],
    ) as UserType;

    return loggedInUserType === UserType.Admin;
  };

  const isSuperUserOrAdmin = () => {
    const loggedInUserType: UserType = Number.parseInt(
      authenticator.getAccount().idTokenClaims['extension_Custom_Role'],
    ) as UserType;

    return loggedInUserType >= UserType.SuperUser;
  };

  const updateLocation = (newLatitude: number, newLongtitude: number) => {
    if (device) {
      setDeviceLocationUpdating(true);
      PutDeviceLocation(newLatitude, newLongtitude, device.id)
        .then(() => {
          deviceUpdated();
          device.location.latitude = newLatitude;
          device.location.longitude = newLongtitude;
          setDeviceLocationUpdateFailed(false);
        })
        .catch(() => {
          setDeviceLocationUpdateFailed(true);
        })
        .finally(() => {
          setDeviceLocationUpdating(false);
        });
    }
  };

  const fillDropdown = (groups: Group[]) => {
    return groups.map((group) => (
      <DropDownChildren key={group.id}>
        <DropDownItem key={group?.id} item={group}>
          {group?.name}
        </DropDownItem>
        {group.children.length > 0 ? fillDropdown(group.children) : null}
      </DropDownChildren>
    ));
  };

  return (
    <div className="DeviceDetailsInfo">
      <div className="DeviceDetailsInfo__filllevel">
        <div className="DeviceDetailsInfo__label">{t('D_DETAILS.FILL_LEVEL')}</div>
        <div className="DeviceDetailsInfo__icon">
          <div className="DeviceDetailsInfo__icon-icon">
            {device ? getDeviceFillLevelIcon(device.fillLevel) : <div />}
          </div>
          <div className="DeviceDetailsInfo__icon-text">{device && t(getDeviceFillLevelTextKey(device.fillLevel))}</div>
        </div>
      </div>
      <div className="DeviceDetailsInfo__info">
        {isUserAdmin() && (
          <div className="DeviceDetailsInfo__customer-dropdown">
            <DropDown
              selected={group?.id}
              borderless={true}
              label={t('D_DETAILS.CUSTOMER')}
              selectedChange={(data: Group) => setGroup(data)}
              header={
                <DropDownHeader>
                  {group?.name || <i className="DeviceDetailsInfo__customer-none">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
                </DropDownHeader>
              }
            >
              {fillDropdown(groups)}
            </DropDown>
          </div>
        )}
        {!isUserAdmin() && (
          <div className="DeviceDetailsInfo__customer">
            <div className="DeviceDetailsInfo__customer-label">{t('D_DETAILS.CUSTOMER')}</div>
            <div className="DeviceDetailsInfo__customer-value">
              {group?.name || <i className="DeviceDetailsInfo__customer-none">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
            </div>
          </div>
        )}
        <div className="DeviceDetailsInfo__site">
          <div className="DeviceDetailsInfo__site-label">{t('D_DETAILS.SITE')}</div>
          <div className="DeviceDetailsInfo__site-value">{device?.site}</div>
        </div>
        <div className="DeviceDetailsInfo__location">
          <DeviceDetailsLocationField
            location={device?.location}
            updateLocation={updateLocation}
            canEdit={isSuperUserOrAdmin()}
            updating={deviceLocationUpdating}
            failed={deviceLocationUpdateFailed}
          />
        </div>
      </div>
    </div>
  );
};
