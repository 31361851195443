import React, { useEffect, useRef, useState } from 'react';
import SlideInModal from '../../../../shared/slideInModal/SlideInModal';
import './patchUser.css';
import { ReactComponent as CancelSvg } from '../../../../assets/common/iconExitGrey.svg';
import { ReactComponent as ToggleOffSvg } from '../../../../assets/addUser/toggleOff.svg';
import { ReactComponent as ToggleOnSvg } from '../../../../assets/addUser/toggleOn.svg';
import { ReactComponent as DeleteSvg } from '../../../../assets/settings/iconDeletefile.svg';
import UserType from '../../../../models/UserType';
import * as UserService from '../../../../data/services/UserService';
import * as GroupService from '../../../../data/services/GroupService';
import SimpleGroup from '../../../../models/SimpleGroup';
import DropDown from '../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../shared/dropDown/dropDownItem/DropDownItem';
import DropDownChildren from '../../../../shared/dropDown/dropDownChildren/DropDownChildren';
import { EnumModel } from '../../../../models/EnumModel';
import { nameOfEnum } from '../../../../utils/NameOfEnum';
import Button from '../../../../shared/button/Button';
import User, { ServiceToolAccessStatus } from '../../../../models/User';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import Loader from '../../../../shared/loader/Loader';
import { ErrorBox } from '../../../../shared/errorBox/ErrorBox';

export interface PatchUserProps {
    open: boolean;
    handleClose: () => void;
    mode?: 'add' | 'edit';
    userToEdit?: User;
    onSubmit?: () => void;
    onDelete?: () => void;
}

const PatchUser: React.FC<PatchUserProps> = ({ open, handleClose, mode = 'add', userToEdit, onSubmit, onDelete }) => {
    const [allowServiceTool, setAllowServiceTool] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumber, setPhoneNumber] = useState('');
    const [group, setGroup] = useState<SimpleGroup>();
    const [groupSelectList, setGroupSelectList] = useState<SimpleGroup[]>([]);
    const [errorGraphs, setErrorGraphs] = useState<string[]>([]);
    const [errorOccured, setErrorOccured] = useState(false);
    const roles = useRef<EnumModel[]>([
        { value: UserType.NotificationUser, name: nameOfEnum(UserType, UserType.NotificationUser) },
        { value: UserType.RegularUser, name: nameOfEnum(UserType, UserType.RegularUser) },
        { value: UserType.SuperUser, name: nameOfEnum(UserType, UserType.SuperUser) },
        { value: UserType.Admin, name: nameOfEnum(UserType, UserType.Admin) },
    ]);
    const [role, setRole] = useState<EnumModel>();
    const [loadSpinner, setLoadSpinner] = useState(false);

    const { t }: UseTranslationResponse = useTranslation();

    useEffect(() => {
        const fillInputs = (user: User) => {
            setAllowServiceTool(true);
            setName(user.Name);
            setPhoneNumber(user.PhoneNumber);
            setEmail(user.Email);
            setAllowServiceTool(user.ServiceToolAccess === ServiceToolAccessStatus.Access ? true : false);
            const simpleGroup: SimpleGroup = {
                id: user.Group.Id,
                name: user.Group.Name,
                nestLevel: 0,
                children: [],
            };
            setGroup(simpleGroup);
            setRole(roles.current.find((r) => r.value === user.Role));
        };

        if (open) {
            if (mode === 'edit') {
                if (userToEdit) {
                    fillInputs(userToEdit);
                }
                setErrorGraphs([t('PATCH_USER.ERROR_EDIT')]);
            } else {
                setErrorGraphs([t('PATCH_USER.ERROR_INVITE')]);
            }
        } else {
            setErrorOccured(false);
        }
    }, [open, mode, t, userToEdit, roles]);

    useEffect(() => {
        let isMounted = true;
        GroupService.GetGroups().then((groupStructure) => {
            if (isMounted) {
                setGroupSelectList([groupStructure]);
            }
        });

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (!open) {
            resetInputs();
        }
    }, [open]);

    const validInputs = () => {
        return name.length > 0 && email.length > 0 && group && role;
    };

    const editUserAndCloseModal = () => {
        if (validInputs() && role && group) {
            if (userToEdit) {
                setLoadSpinner(true);
                UserService.UpdateUser(name, group.id, role.value, phonenumber, allowServiceTool, userToEdit.B2cId)
                    .then(() => {
                        resetInputs();
                        if (onSubmit) {
                            onSubmit();
                        }
                        handleClose();
                    })
                    .catch(() => {
                        setErrorOccured(true);
                    })
                    .finally(() => {
                        setLoadSpinner(false);
                    });
            } else {
                //Not adding translation because this is an error being thrown
                throw new Error('When editing a user a userToEdit should be given');
            }
        }
    };

    const addUserAndCloseModal = () => {
        if (validInputs() && role && group) {
            setLoadSpinner(true);
            UserService.InviteNewUser(email, name, role.value, group.id, phonenumber, allowServiceTool)
                .then(() => {
                    resetInputs();
                    if (onSubmit) {
                        onSubmit();
                    }
                    handleClose();
                })
                .catch(() => {
                    setErrorOccured(true);
                })
                .finally(() => {
                    setLoadSpinner(false);
                });
        }
    };

    const resetInputs = () => {
        setAllowServiceTool(false);
        setName('');
        setEmail('');
        setPhoneNumber('');
        setRole(undefined);
        setGroup(undefined);
    };

    const fillDropdown = (data: SimpleGroup) => {
        return (
            <DropDownChildren key={data.id + '-wrap'}>
                <DropDownItem key={data.id} item={data}>
                    {data.name}
                </DropDownItem>
                {data.children.length > 0 ? data.children.map((subData: SimpleGroup) => fillDropdown(subData)) : null}
            </DropDownChildren>
        );
    };

    return (
        <SlideInModal width={540} open={open} handleClose={() => handleClose()}>
            <div className="patch-user__header">
                <div className="patch-user__header-items">
                    <div
                        className="patch-user__delete"
                        style={{ visibility: mode === 'add' ? 'hidden' : 'visible' }}
                        onClick={() => onDelete && onDelete()}
                    >
                        <span>{t('COMMON.DELETE')}</span>
                        <DeleteSvg className="patch-user__delete-icon" />
                    </div>
                    <div
                        className={`patch-user__cancel ${mode === 'add' ? 'patch-user__cancel-add' : 'patch-user__cancel-edit'}`}
                        onClick={() => handleClose()}
                    >
                        <span>{t('COMMON.CANCEL')}</span>
                        <CancelSvg className="patch-user__cancel-icon" />
                    </div>
                    <Button
                        className={`patch-user__submit-button ${mode === 'add' ? 'patch-user__invite' : 'patch-user__save'}`}
                        onClick={() => (mode === 'add' ? addUserAndCloseModal() : editUserAndCloseModal())}
                        disabled={!validInputs()}
                    >
                        {mode === 'add' ? t('PATCH_USER.INVITE_USER') : t('PATCH_USER.SAVE')}
                    </Button>
                </div>
            </div>
            <div className="patch-user__body">
                <div className="patch-user__body-title">
                    {mode === 'add' ? t('PATCH_USER.NEW_USER') : t('PATCH_USER.EDIT_USER')}
                </div>
                <div className="patch-user__body-form">
                    <form id="patch-user__body-form">
                        <div>
                            <input
                                type="text"
                                className={name === '' ? '' : 'patch-user__body-form-input-text'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required={true}
                            />
                            <label className="patch-user__body-form-input-label">{t('PATCH_USER.NAME')}</label>
                        </div>
                        <div>
                            <input
                                type="email"
                                className={email === '' ? '' : 'patch-user__body-form-input-text'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required={true}
                                disabled={mode !== 'add'}
                            />
                            <label className="patch-user__body-form-input-label">{t('PATCH_USER.EMAIL')}</label>
                        </div>
                        <div>
                            <input
                                type="text"
                                className={phonenumber === '' ? '' : 'patch-user__body-form-input-text'}
                                value={phonenumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required={false}
                            />
                            <label className="patch-user__body-form-input-label">{t('PATCH_USER.PHONE')}</label>
                        </div>
                        <div className="patch-user__body-form-select-box">
                            <DropDown
                                borderless={true}
                                selected={role}
                                label="Role"
                                selectedChange={setRole}
                                header={<DropDownHeader>{role ? role.name : t('PATCH_USER.PLEASE_SELECT')}</DropDownHeader>}
                            >
                                {roles?.current.map((role) => (
                                    <DropDownItem key={role.value} item={role}>
                                        {role.name}
                                    </DropDownItem>
                                ))}
                            </DropDown>
                        </div>
                        <div className="patch-user__body-form-select-box">
                            <DropDown
                                borderless={true}
                                label="Group"
                                selected={group}
                                selectedChange={(data: SimpleGroup) => setGroup(data)}
                                header={<DropDownHeader>{group ? group.name : t('PATCH_USER.SELECT_GROUP')}</DropDownHeader>}
                            >
                                {groupSelectList.map((data: SimpleGroup) => fillDropdown(data))}
                            </DropDown>
                        </div>
                        {role && (role.value !== UserType.NotificationUser) && (
                            <div>
                                <label className="patch-user__body-form-access-label">{t('PATCH_USER.SERVICE_TOOL')}</label>
                                <div className="patch-user__body-form-access-text">{t('PATCH_USER.ALLOW_SERVICE_TOOL')}</div>
                                {allowServiceTool ? (
                                    <ToggleOnSvg className="patch-user__body-form-access" onClick={() => setAllowServiceTool(false)} />
                                ) : (
                                    <ToggleOffSvg className="patch-user__body-form-access" onClick={() => setAllowServiceTool(true)} />
                                )}
                            </div>
                        )}
                    </form>
                </div>
                {errorOccured && (
                    <ErrorBox className="patch-user__error-box" paragraphs={errorGraphs} header={t('COMMON.ERROR')} />
                )}
            </div>
            {loadSpinner && <Loader mode="fill-parent" />}
        </SlideInModal>
    );
};

export default PatchUser;
