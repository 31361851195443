import React, { useEffect, useState } from 'react';
import { ReactComponent as IconDeleteFile } from '../../assets/draganddrop/iconDeletefile.svg';
import { ReactComponent as IconDownloadFile } from '../../assets/draganddrop/iconDownload.svg';
import { ReactComponent as IcondUploadFile } from '../../assets/draganddrop/upload.svg';
import * as AttachmentService from '../../data/services/AttachmentService';
import { AttachmentFile } from '../../models/AttachmentFile';
import Device from '../../models/Device';
import authenticator from '../../services/Authenticator';
import ConfirmDialog from '../confirmDialog/ConfirmDialog';
import * as MonthContainer from '../months/MonthContainer';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import './DragAndDrop.css';
import ErrorBox from '../errorBox/ErrorBox';

export interface DragAndDropProps {
	width: number;
	device: Device;
	fileEditRights: boolean;
}

export interface backgroundAndBorder {
	backgroundColor: string;
	border: string;
}

export const DragAndDrop: React.FC<DragAndDropProps> = (props) => {
	const [attIndex, setAttIndex] = useState(0);
	const [attName, setAttName] = useState('');
	const [showPopUp, setShowPopUp] = useState(false);
	const [attachmentFiles, setAttachmentFiles] = useState<AttachmentFile[]>([]);
	const [bab, setBab] = useState<backgroundAndBorder>({
		backgroundColor: 'var(--lightgray)',
		border: '1px solid var(--gray)',
	});
	const [errorOccured, setErrorOccured] = useState(false);
	const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);

	const [isDropping, setIsDropping] = useState(false);

	const { t }: UseTranslationResponse = useTranslation();

	useEffect(() => {
		AttachmentService.GetAttachments(props.device.id)
			.then((attachments) => {
				setAttachmentFiles(attachments);
				setErrorOccured(false);
			})
			.catch(() => {
				setErrorParagraphs([t('DRAG_AND_DROP.ERROR_GET')]);
				setErrorOccured(true);
			});
	}, [props.device.id, t]);

	useEffect(() => {
		if (isDropping) {
			setBab({
				backgroundColor: 'var(--semitransparentacent)',
				border: '1px solid var(--acent)',
			});
		} else {
			setBab({
				backgroundColor: 'var(--lightgray)',
				border: '1px solid var(--gray)',
			});
		}
	}, [isDropping]);

	const addFilesToBackend = async (file: File) => {
		const account = authenticator.getAccount();

		AttachmentService.PostAttachment(file, props.device.id)
			.then((postAttachment) => {
				const attachment: AttachmentFile = {
					attachmentId: postAttachment,
					filename: file.name,
					uploadedBy: account.name,
					lastModified: new Date(),
					canUserDelete: true,
				};

				attachmentFiles.push(attachment);
				setAttachmentFiles([...attachmentFiles]);
				setErrorOccured(false);
			})
			.catch(() => {
				setErrorParagraphs([t('DRAG_AND_DROP.ERROR_UPLOAD')]);
				setErrorOccured(true);
			});
	};

	const addFilesFromBrowser = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();

		if (event.target.files !== null) {
			const files = Array.from(event.target.files);

			for (let i = 0; i < files.length; i++) {
				await addFilesToBackend(files[i]);
			}
		}
	};

	const drop = async (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();

		setIsDropping(false);

		const file = event.dataTransfer.files[event.dataTransfer.files.length - 1];

		await addFilesToBackend(file);
	};

	const removeFile = (fileName: string, index: number) => {
		const file = attachmentFiles[index];
		if (file.filename === fileName) {
			AttachmentService.DeleteAttachment(props.device.id, file.attachmentId)
				.then(() => {
					attachmentFiles.splice(index, 1);
					setAttachmentFiles([...attachmentFiles]);
					setErrorOccured(false);
				})
				.catch(() => {
					setErrorParagraphs([t('DRAG_AND_DROP.ERROR_REMOVE')]);
					setErrorOccured(true);
				});
		}
	};

	const togglePopup = () => {
		setShowPopUp(!showPopUp);
	};

	const setAttToDelete = (attName: string, attIndex: number) => {
		setAttName(attName);
		setAttIndex(attIndex);
	};

	const openPopupForAttDelete = (attName: string, attIndex: number) => {
		setAttToDelete(attName, attIndex);
		togglePopup();
	};

	const downloadFile = (attachment: AttachmentFile) => {
		AttachmentService.GetAttachment(props.device.id, attachment)
			.then(() => setErrorOccured(false))
			.catch(() => {
				setErrorParagraphs([t('DRAG_AND_DROP.ERROR_DOWNLOAD')]);
				setErrorOccured(true);
			});
	};

	const getFormattedDateSpan = (data: AttachmentFile) => {
		const date = new Date(data.lastModified);
		return (
			<div className="draganddrop-date">
				{date.getDate()} {MonthContainer.getMonthInShort(date.getMonth())} {date.getFullYear()}, {date.getHours()}:
				{date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}
			</div>
		);
	};

	return (
		<>
			<div
				className="draganddrop-container"
				style={{
					maxWidth: props.width,
				}}
			>
				{errorOccured && (
					<ErrorBox className="draganddrop-error-box" header={t('COMMON.ERROR')} paragraphs={errorParagraphs} />
				)}
				{props.fileEditRights && (
					<div
						className="draganddrop-drop-container"
						onDragOver={(event) => {
							event.preventDefault();
							setIsDropping(true);
						}}
						onDragEnter={(event) => event.preventDefault()}
						onDragLeave={(event) => {
							event.preventDefault();
							setIsDropping(false);
						}}
						onDrop={(event) => drop(event)}
						style={{
							backgroundColor: bab.backgroundColor,
							border: bab.border,
						}}
					>
						<div className="draganddrop-drop-message">
							<IcondUploadFile className="draganddrop-upload-icon" />
							<span style={{ fontWeight: 'bold' }}>{t('DRAG_AND_DROP.FIRST_PART')}</span>
							<span> {t('DRAG_AND_DROP.SECOND_PART')} </span>
							<span
								style={{ cursor: 'pointer', textDecorationLine: 'underline' }}
								onClick={() => document.getElementById('draganddrop-file-browser')?.click()}
							>
								{t('DRAG_AND_DROP.THIRD_PART')}
							</span>
							<input
								type="file"
								id="draganddrop-file-browser"
								multiple={true}
								style={{ display: 'none' }}
								onChange={async (event) => await addFilesFromBrowser(event)}
							/>
						</div>
					</div>
				)}
				<div className="draganddrop-file-display-container">
					{attachmentFiles.map((data: AttachmentFile, index: number) => (
						<div className="draganddrop-file-status-bar" key={index}>
							<div className="draganddrop-file-labels">
								<div className="draganddrop-file-name">{data.filename}</div>
								{getFormattedDateSpan(data)}
							</div>
							<div className="draganddrop-file-buttons">
								{data.canUserDelete && props.fileEditRights && (
									<div className="draganddrop-remove-box">
										<span
											className="draganddrop-file-remove"
											onClick={() => openPopupForAttDelete(data.filename, index)}
										>
											{t('COMMON.DELETE')}
										</span>
										<IconDeleteFile className="draganddrop-file-remove-icon" />
									</div>
								)}
								<div
									className="draganddrop-download-box"
									onClick={(event) => {
										event.preventDefault();
										downloadFile(data);
									}}
								>
									<span className="draganddrop-download">{t('COMMON.DOWNLOAD_CAPS')}</span>
									<IconDownloadFile className="draganddrop-download-icon" />
								</div>
							</div>
						</div>
					))}
					<div className="draganddrop-line"></div>
				</div>
			</div>
			{showPopUp ? (
				<ConfirmDialog
					paragraphs={[t('DELETE_MODAL.FIRST_PARAGRAPH'), t('DELETE_MODAL.SECOND_PARAGRAPH_ATTACHMENT')]}
					primaryText={t('COMMON.DELETE')}
					primaryClick={() => {
						removeFile(attName, attIndex);
						togglePopup();
					}}
					secondaryText={t('COMMON.CANCEL')}
					secondaryClick={togglePopup}
				/>
			) : null}
		</>
	);
};

export default DragAndDrop;
