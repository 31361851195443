export interface IPostReport {
    devices: string[];
    periodStart: string;
    periodEnd: string;
    periodType: PeriodType;
    dataType: DataType;
    resultAs: ResultAs;
}

export enum PeriodType {
    WEEK = 0,
    MONTH = 1,
    YEAR = 2
}

export enum DataType {
    FillLevelIndex = 11,
    FullDetectCounter = 35,
    BaleEjectCounter = 52
}

export enum ResultAs {
    Chart = 0,
    Grid = 1
}