import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconOffline } from '../../assets/common/iconOffline.svg';
import './OfflineIndicator.css';

export const OfflineIndicator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="OfflineIndicator">
      <div className="OfflineIndicator__bar">
        <div className="OfflineIndicator__bar-icon">
          <IconOffline />
        </div>
        <div className="OfflineIndicator__bar-title">{t('OFFLINE.OFFLINE')}</div>
        <div className="OfflineIndicator__bar-dash">&nbsp;-&nbsp;</div>
        <div className="OfflineIndicator__bar-text">{t('OFFLINE.CHECK_CONNECTION')}</div>
      </div>
    </div>
  );
};
