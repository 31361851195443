import React from 'react';
import { cloneReactNodeAndProps } from '../../../utils/CloneReactNodeAndProps';

export interface dropdownchildrenprops {
  selectItem?: (item: any) => void;
  level?: number;
  itemIsSelected?: (item: any) => boolean;
}
export const DropDownChildren: React.FC<dropdownchildrenprops> = (props) => {
  return (
    <>
      <div className="dropdown-children-content">
        {React.Children.map(props.children, (child) => {
          return cloneReactNodeAndProps(child, {
            selectItem: props.selectItem,
            itemIsSelected: props.itemIsSelected,
            level: props.level ? props.level + 1 : 1,
          });
        })}
      </div>
    </>
  );
};

export default DropDownChildren;
