import { compact, flatten } from 'lodash';
import { DeviceStatus } from '../data/models/devices/DeviceStatus';
import { Status } from '../models/Device';

export const FormatErrorsAndWarningsToList = (deviceStatus: DeviceStatus): { bit: number, label: string; status: Status }[] => {
  const { errorCodesSet, fillLevelCodesSet, serviceCodeSet, warningCodesSet } = deviceStatus;

  const formattedErrors = errorCodesSet.map((error) => ({
    bit: error.bitNumber,
    label: error.name,
    status: Status.error,
  }));
  const formattedFillLevel = fillLevelCodesSet.map((level) => ({
    bit: level.bitNumber,
    label: level.name,
    status: Status.serviceDue,
  }));
  const formattedService = serviceCodeSet.map((service) => ({
    bit: service.bitNumber,
    label: service.name,
    status: Status.serviceDue,
  }));
  const formattedWarnings = warningCodesSet.map((warning) => ({
    bit: warning.bitNumber,
    label: warning.name,
    status: Status.serviceDue,
  }));

  return compact(flatten([formattedErrors, formattedFillLevel, formattedService, formattedWarnings]));
};
