import React from 'react';
import { ReactComponent as EmptyNormalSvg } from '../../../../../assets/deviceList/empty/iconEmptyNormal.svg';
import { ReactComponent as EmptyOverFullSvg } from '../../../../../assets/deviceList/empty/iconEmptyOverfull.svg';
import { ReactComponent as ServiceDueSvg } from '../../../../../assets/deviceList/service/iconServiceDue.svg';
import { ReactComponent as ServiceNormalSvg } from '../../../../../assets/deviceList/service/iconServiceNormal.svg';
import { ReactComponent as ChevronDown } from '../../../../../assets/common/chevron-down.svg';
import Device, { FillLevel } from '../../../../../models/Device';
import { DeviceOrderBy } from '../../../../../models/DeviceOrderingEnum';
import './DeviceList.css';
import { DeviceStateTagsDisplay } from '../../../../../shared/DeviceStateTagsDisplay/DeviceStateTagsDisplay';
import { DeviceStateValues } from '../../../../../models/DeviceStateValues';
import { OrderValue } from '../../../../../models/OrderValue';
import { useTranslation } from 'react-i18next';
import { getDeviceFillLevelIcon } from '../../../../../utils/GetDeviceFillLevel';

interface DeviceListProps {
  devices: Device[];
  collapsed?: boolean;
  showLocateButton?: boolean;
  setListOrderBy: (orderBy: DeviceOrderBy) => void;
  listOrderAttribute: string;
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onDeviceClicked: (device: Device) => void;
  sortOrder: OrderValue;
  stateValues: DeviceStateValues;
}

const DeviceList: React.FC<DeviceListProps> = ({
  devices,
  setListOrderBy,
  listOrderAttribute,
  handleScroll,
  onDeviceClicked,
  sortOrder,
  stateValues,
}) => {
  const { t } = useTranslation();
  const CalculateDaysNow = (date: Date) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date.valueOf() - Date.now()) / oneDay));
  };

  return (
    <div className="devicelist__table-wrappper" onScroll={handleScroll}>
      <table className="devicelist__table">
        <thead className="devicelist__thead">
          <tr className="devicelist__row">
            <th
              className={`devicelist__fill-col devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.FILL ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.FILL)}
            >
              {t('DEVICE_LIST_HEAD.FILL')}
            </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.ID ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.ID)}
              colSpan={2}
            >
              {t('DEVICE_LIST_HEAD.ID')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.TYPE ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.TYPE)}
            >
              {t('DEVICE_LIST_HEAD.TYPE')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.ID ? 'devicelist__thead-col-underlined' : ''
              }`}
            ></th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.CUSTOMER ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.CUSTOMER)}
            >
              {t('DEVICE_LIST_HEAD.CUSTOMER')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.SITE ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.SITE)}
            >
              {t('DEVICE_LIST_HEAD.SITE')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
	          <th
		          className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.WASTEFRACTION ? 'devicelist__thead-col-underlined' : ''
              }`}
		          onClick={() => setListOrderBy(DeviceOrderBy.WASTEFRACTION)}
	          >
		          {t('DEVICE_LIST_HEAD.WASTEFRACTION')}
		          <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
	          </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.LASTEMPTY ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.LASTEMPTY)}
            >
              {t('DEVICE_LIST_HEAD.LAST_EMPTY')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
            <th
              className={`devicelist__thead-col ${
                listOrderAttribute === DeviceOrderBy.LASTSERVICE ? 'devicelist__thead-col-underlined' : ''
              }`}
              onClick={() => setListOrderBy(DeviceOrderBy.LASTSERVICE)}
            >
              {t('DEVICE_LIST_HEAD.LAST_SERVICE')}
              <ChevronDown className={`devicelist__sorting-chevron devicelist__sorting-chevron-${sortOrder}`} />
            </th>
          </tr>
        </thead>
        <tbody className="devicelist__tbody">
          {devices.map((d) => {
            return (
              <React.Fragment key={d.id}>
                <tr className="devicelist__row devicelist__body-row" onClick={() => onDeviceClicked(d)}>
                  <td className="devicelist__fill-col">{getDeviceFillLevelIcon(d.fillLevel)}</td>
                  <td className="devicelist__id-col devicelist__dark-bold-text">{d.machineId.substring(0, 6)}</td>
                  <td className="devicelist__hyphen devicelist__dark-bold-text">&nbsp;·&nbsp;</td>
                  <td className="devicelist__dark-bold-text">{d.type}</td>
                  <td>
                    <DeviceStateTagsDisplay deviceId={d.id} stateValues={stateValues} />
                  </td>
                  <td>
                    {d?.customer?.name || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
                  </td>
                  <td className="devicelist__site">
                    {d.site || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
                  </td>
	                <td className="devicelist__wasteFraction">
		                {d.wasteFraction || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
	                </td>
                  <td className="devicelist__last-empty">
                    {d.fillLevel === FillLevel.tooFullToBaleOut || d.fillLevel === FillLevel.overFull ? (
                      <EmptyOverFullSvg className="devicelist__icon" />
                    ) : (
                      <EmptyNormalSvg className="devicelist__icon" />
                    )}
                    {`${CalculateDaysNow(new Date(d.lastEmpty))} ${t('COMMON.DAYS')}`}
                  </td>
                  <td>
                    {d.deviceStatus.serviceCodeSet.length > 0 ? (
                      <ServiceDueSvg className="devicelist__icon" />
                    ) : (
                      <ServiceNormalSvg className="devicelist__icon" />
                    )}
                    {`${CalculateDaysNow(new Date(d.lastService))} ${t('COMMON.DAYS')}`}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DeviceList;
