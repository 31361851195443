import React, { useEffect, useState } from 'react';
import './NotificationGroupDeviceCodeList.css';
import { ReactComponent as ToggleOffSvg } from '../../../../../../assets/addUser/toggleOff.svg';
import { ReactComponent as ToggleOnSvg } from '../../../../../../assets/addUser/toggleOn.svg';
import SimpleNotificationGroupDeviceCode from '../../../../../../models/SimpleNotificationGroupDeviceCode';


export interface NotificationGroupDeviceCodeListProps {
    notificationGroupDeviceCodeList: SimpleNotificationGroupDeviceCode[];
    label: string;
    onSelectedItem: (simpleNotificationGroupItem: SimpleNotificationGroupDeviceCode) => void;
}

const NotificationGroupDeviceCodeList: React.FC<NotificationGroupDeviceCodeListProps> = ({
    notificationGroupDeviceCodeList,
    label,
    onSelectedItem
}) => {
    const [listOpened, setListOpened] = useState(false);
    const [list, setList] = useState<SimpleNotificationGroupDeviceCode[]>([]);

    useEffect(() => {
        setList(notificationGroupDeviceCodeList);
    }, [notificationGroupDeviceCodeList]);

    const setItemSelected =
        (selectedItem: SimpleNotificationGroupDeviceCode, selected: boolean) => {
            selectedItem.selected = selected;
            onSelectedItem(selectedItem);
        };

    return (
        <>
            <div className="notificationgroupitemlist__card">
                <div className="notificationgroupitemlist__card-header">
                    <div className="notificationgroupitemlist__card-header-info">
                        <a
                            href="#"
                            onClick={() => {
                                setListOpened(!listOpened);
                            }}>
                            <div className={`${listOpened ? 'notificationgroupitemlist__icon-opened' : 'notificationgroupitemlist__icon-closed'}`}></div>
                        </a>
                    </div>
                    <div className="notificationgroupitemlist__card-header-headline">
                        <a
                            href="#"
                            onClick={() => {
                                setListOpened(!listOpened);
                            }}>
                            {label}
                        </a>
                    </div>
                </div>
                <div className="notificationgroupitemlist__card-body" style={{ display: listOpened ? "block" : "none" }}>
                    <table>
                        <tbody>
                            {notificationGroupDeviceCodeList.map((u, i) => {
                                return (
                                    <tr key={`item${u.bitNumber}`}>
                                        <td>
                                            <div className="notificationgroupitemlist__notification-group-item">
                                                <a href="#" onClick={
                                                    () => setItemSelected(u, !u.selected)}>{u.name}</a>
                                                {u.selected
                                                    ? (
                                                        <ToggleOnSvg className="notificationgroupitemlist__messageselect-item-toggle" onClick={
                                                            () => setItemSelected(u, false)} />
                                                    )
                                                    : (
                                                        <ToggleOffSvg className="notificationgroupitemlist__messageselect-item-toggle" onClick={
                                                            () => setItemSelected(u, true)} />
                                                    )}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default NotificationGroupDeviceCodeList
