import { Status } from '../models/Device';

export const GetDeviceEventLogColour = (status: Status): { background: string; border: string; textColor: string } => {
	switch (status) {
		case Status.error:
			return { background: 'transparent', border: 'transparent', textColor: 'var(--black)' };

		case Status.serviceDue:
			return {
				background: 'var(--semitransparentwarning)',
				border: 'var(--lightwarning)',
				textColor: 'var(--warning)',
			};

		default:
			return { background: 'transparent', border: 'transparent', textColor: 'var(--black)' };
	}
};
