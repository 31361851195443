import React from 'react';
import Device from '../../../../../models/Device';
import DragAndDrop from '../../../../../shared/draganddrop/DragAndDrop';
import './DeviceDetailsStatisticsFiles.css';

type DeviceDetailsStatisticsFilesProps = {
    device: Device;
    fileEditRights: boolean;
};

export const DeviceDetailsStatisticsFiles: React.FC<DeviceDetailsStatisticsFilesProps> = ({ device, fileEditRights }) => {
  return (
    <div className="DeviceDetailsStatisticsFiles">
          <DragAndDrop device={device} width={560} fileEditRights={fileEditRights}></DragAndDrop>
    </div>
  );
};
