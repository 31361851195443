import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Statistics } from '../../../../assets/common/iconStatistics.svg';
import { ReactComponent as Grid } from '../../../../assets/common/iconGrid.svg';
import iconGrid from '../../../../assets/common/iconGrid.png';
import iconDownload from '../../../../assets/common/iconDownload.png';
import { IPostReport, DataType, PeriodType, ResultAs } from "../../../../data/models/PostReport";
import './ReportFilter.css';
import { DeviceSelector } from "./DeviceSelector/DeviceSelector";
import Device from '../../../../models/Device';
import moment from "moment";
import DropDown from '../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../shared/dropDown/dropDownItem/DropDownItem';
import { CSVLink } from "react-csv";

export interface dialogReportFilterProps {
    deviceList: Device[];
    deviceListSelected: Device[];
    startDate: Date;
    endDate: Date;
    periodType: PeriodType;
    dataType: DataType;
    updated: (postReport: IPostReport) => void;
    exportData: string[][] | undefined;
}

const dataTypeOptions = [
    { value: 'fillLevelIndex', label: "Fill Level" },
    { value: 'fullDetectCounter', label: 'Full Counter' },
    { value: 'baleEjectCounter', label: 'Bale out' }
];

const periodTypeOptions: IOptionValue[] = [
    { value: 'week', label: 'Week' },
    { value: 'month', label: 'Month' },
    { value: 'year', label: 'Year' }
];

export interface IOptionValue {
    value: string;
    label: string;
}

export const ReportFilter: React.FC<dialogReportFilterProps> = ({ ...props }) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(props.startDate);
    const [endDate, setEndDate] = useState(props.endDate);
    const [periodDate, setPeriodDate] = useState();
    const [showDeviceSelector, setShowDeviceSelector] = useState(false);
    const [periodTypeSelected, setPeriodTypeSelected] = useState<PeriodType>(props.periodType);
    const [dataTypeSelected, setDataTypeSelected] = useState<DataType>(props.dataType);
    const [init, setInit] = useState(true);
    const [showChartIsActive, setShowChartIsActive] = useState(true);
    const [showGridIsActive, setShowGridIsActive] = useState(false);
    const [showResultAs, setShowResultAs] = useState<ResultAs>(ResultAs.Chart);
    const [dataTypeOptionSelected, setDataTypeOptionSelected] = useState<IOptionValue>({
        label: "Fill Level",
        value: "fillLevelIndex"
    });
    const [periodTypeOptionSelected, setPeriodTypeOptionSelected] = useState<IOptionValue>({
        label: "Week",
        value: "week"
    });
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [downloadFilename, setDownloadFilename] = useState("export.csv");

    const [deviceListSelected, setDeviceListSelected] = useState<Device[]>([]);
    const [deviceList, setDeviceList] = useState<Device[]>([]);

    useEffect(() => {
        setDownloadEnabled(props.exportData !== undefined);

        if (props.exportData) {
            setDownloadFilename(moment(new Date()).format("YYYY-MM-DD_HH:mm:ss") + ".csv");
        }
    },
        [props.exportData]);

    useEffect(() => {
        if (!init) {
            let result: IPostReport = {
                dataType: dataTypeSelected,
                devices: deviceListSelected.map(c => c.id),
                periodEnd: moment(endDate).format("YYYY-MM-DD"),
                periodStart: moment(startDate).format("YYYY-MM-DD"),
                periodType: periodTypeSelected,
                resultAs: showResultAs
            };

            props.updated(result);
        } else {
            setDeviceListSelected(props.deviceListSelected);
            setDeviceList(props.deviceList);
            setStartDate(props.startDate);
            setEndDate(props.endDate);

            if (props.dataType === DataType.BaleEjectCounter) {
                setDataTypeOptionSelected(dataTypeOptions[2]);
            } else if (props.dataType === DataType.FillLevelIndex) {
                setDataTypeOptionSelected(dataTypeOptions[0]);
            } else if (props.dataType === DataType.FullDetectCounter) {
                setDataTypeOptionSelected(dataTypeOptions[1]);
            }

            if (props.periodType === PeriodType.WEEK) {
                setPeriodTypeOptionSelected(periodTypeOptions[0]);
            } else if (props.periodType === PeriodType.MONTH) {
                setPeriodTypeOptionSelected(periodTypeOptions[1]);
            } else if (props.periodType === PeriodType.YEAR) {
                setPeriodTypeOptionSelected(periodTypeOptions[2]);
            }


        }
        setInit(false);
    },
        [periodDate, periodTypeSelected, dataTypeSelected, deviceListSelected, showResultAs]);

    const onDatePickerChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (dates[0] && dates[1]) {
            setPeriodDate(dates);
            localStorage.setItem("reportPeriodStart", moment(start).format("YYYY-MM-DD"));
            localStorage.setItem("reportPeriodEnd", moment(end).format("YYYY-MM-DD"));
        }
    };

    const onDeviceSelecting = () => {
        setShowDeviceSelector(true);
    };

    const onDeviceSelectorSave = (deviceList: Device[]) => {
        localStorage.setItem("deviceSelected", JSON.stringify(deviceList));

        setDeviceListSelected(deviceList);
        setShowDeviceSelector(false);
    }

    const onDataTypeOptionSelected = (data: IOptionValue) => {
        setDataTypeOptionSelected(data);

        if (data.value === "fillLevelIndex") {
            setDataTypeSelected(DataType.FillLevelIndex);
            localStorage.setItem("reportDataType", JSON.stringify(DataType.FillLevelIndex));
        } else if (data.value === "fullDetectCounter") {
            setDataTypeSelected(DataType.FullDetectCounter);
            localStorage.setItem("reportDataType", JSON.stringify(DataType.FullDetectCounter));
        } else if (data.value === "baleEjectCounter") {
            setDataTypeSelected(DataType.BaleEjectCounter);
            localStorage.setItem("reportDataType", JSON.stringify(DataType.BaleEjectCounter));
        }
    };

    const onPeriodTypeOptionSelected = (data: IOptionValue) => {
        setPeriodTypeOptionSelected(data);

        if (data.value === "week") {
            setPeriodTypeSelected(PeriodType.WEEK);
            localStorage.setItem("reportPeriodType", JSON.stringify(PeriodType.WEEK));
        } else if (data.value === "month") {
            setPeriodTypeSelected(PeriodType.MONTH);
            localStorage.setItem("reportPeriodType", JSON.stringify(PeriodType.MONTH));
        } else if (data.value === "year") {
            setPeriodTypeSelected(PeriodType.YEAR);
            localStorage.setItem("reportPeriodType", JSON.stringify(PeriodType.YEAR));
        }
    };

    const onShowChart = () => {
        setShowChartIsActive(true);
        setShowGridIsActive(false);

        setShowResultAs(ResultAs.Chart);
    }

    const onShowGrid = () => {
        setShowChartIsActive(false);
        setShowGridIsActive(true);

        setShowResultAs(ResultAs.Grid);
    }

    return (
        <>
            <div className="reportfilter_container">
                <div className="reportfilter__deviceselector">
                    <label>Devices</label>
                    <a href="#" onClick={() => onDeviceSelecting()}>{deviceListSelected.length} devices selected</a>
                </div>

                <div className="reportfilter__datatypeselector">
                    <DropDown
                        borderless={true}
                        selected={dataTypeOptionSelected}
                        label={t("REPORT.DATATYPE_SELECT")}
                        selectedChange={onDataTypeOptionSelected}
                        header={<DropDownHeader>{dataTypeOptionSelected ? dataTypeOptionSelected.label : t('PATCH_USER.PLEASE_SELECT')}</DropDownHeader>}
                    >
                        {dataTypeOptions?.map((dataType) => (
                            <DropDownItem key={dataType.value} item={dataType}>
                                {dataType.label}
                            </DropDownItem>
                        ))}
                    </DropDown>
                </div>

                <div className="reportfilter__periodtypeselector">
                    <DropDown
                        borderless={true}
                        selected={periodTypeOptionSelected}
                        label={t("REPORT.PERIODTYPE_SELECT")}
                        selectedChange={onPeriodTypeOptionSelected}
                        header={<DropDownHeader>{periodTypeOptionSelected ? periodTypeOptionSelected.label : t('PATCH_USER.PLEASE_SELECT')}</DropDownHeader>}
                    >
                        {periodTypeOptions?.map((periodType) => (
                            <DropDownItem key={periodType.value} item={periodType}>
                                {periodType.label}
                            </DropDownItem>
                        ))}
                    </DropDown>
                </div>


                <div className="reportfilter__periodselector">
                    <label>{t("REPORT.PERIOD_SELECT")}</label>
                    {periodTypeSelected === PeriodType.WEEK && (
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onDatePickerChange}
                            maxDate={new Date()}
                            className="reportfilter__datepicker"
                            calendarClassName="reportfilter__datepicker_calendar"
                            calendarStartDay={1}
                        />
                    )}
                    {periodTypeSelected === PeriodType.MONTH && (
                        <DatePicker
                            dateFormat="yyyy-MM"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onDatePickerChange}
                            maxDate={new Date()}
                            className="reportfilter__datepicker"
                            calendarClassName="reportfilter__datepicker_calendar"
                            calendarStartDay={1}
                            showMonthYearPicker
                        />
                    )}
                    {periodTypeSelected === PeriodType.YEAR && (
                        <DatePicker
                            dateFormat="yyyy"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onDatePickerChange}
                            maxDate={new Date()}
                            className="reportfilter__datepicker"
                            calendarClassName="reportfilter__datepicker_calendar"
                            calendarStartDay={1}
                            showYearPicker
                        />
                    )}

                </div>
                <div className="reportfilter__showas">
                    <div className="reportfilter__showas_chart">
                        <button onMouseDown={(e) => e.preventDefault()} title="Reports"
                            style={
                                showChartIsActive
                                    ? { backgroundColor: 'rgba(65,65,65,0.2)' }
                                    : { backgroundColor: 'transparent' }
                            }
                            onClick={onShowChart}>
                            <Statistics />
                        </button>
                    </div>
                    <div className="reportfilter__showas_grid">
                        <button onMouseDown={(e) => e.preventDefault()} title="Reports"
                            style={
                                showGridIsActive
                                    ? { backgroundColor: 'rgba(65,65,65,0.2)' }
                                    : { backgroundColor: 'transparent' }
                            }
                            onClick={onShowGrid}>
                            <img src={iconGrid} />
                        </button>
                    </div>
                </div>
                <div className="reportfilter__export">
                    {downloadEnabled && props.exportData ? (
                        <CSVLink filename={downloadFilename} data={props.exportData} separator={";"}><img src={iconDownload} /></CSVLink>
                    ) : (
                        <div><img src={iconDownload} style={{ opacity: 0.2 }} /></div>
                    )}
                </div>
            </div>
            <DeviceSelector closeClick={() => setShowDeviceSelector(false)} open={showDeviceSelector} deviceList={deviceList} saveClick={(deviceListx: Device[]) => onDeviceSelectorSave(deviceListx)} deviceListSelected={deviceListSelected} />
        </>
    );
};