import authenticator from '../../services/Authenticator';
import axios, { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

const serviceToolInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVICETOOL_API_URL,
});

// Axios instance with baseurl and token
export const authorizedAxiosInstance = async (): Promise<AxiosInstance> => {
  //update the token since it may have been refreshed
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + authenticator.getBearerToken();
  return instance;
};

export const serviceToolAuthorizedAxiosInstance = async (): Promise<AxiosInstance> => {
  //update the token since it may have been refreshed
  serviceToolInstance.defaults.headers.common['Authorization'] = 'Bearer ' + authenticator.getBearerToken();
  return serviceToolInstance;
};
