import React from 'react';
import { ReactComponent as IconExit } from '../../assets/common/iconExitGrey.svg';
import './CloseButton.css';

export interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <button className="CloseButton" onClick={onClick}>
      <div className="CloseButton__text">Close</div>
      <div className="CloseButton__icon">
        <IconExit />
      </div>
    </button>
  );
};
