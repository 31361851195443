import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AppInvalidLogin from './AppInvalidLogin';
import './index.css';
import authenticator from './services/Authenticator';
import './services/Translation';
import * as serviceWorker from './serviceWorker';

authenticator.initialize({
  clientId: process.env.REACT_APP_CLIENT_ID || '',
  signinAuth: process.env.REACT_APP_SIGNIN_AUTH || '',
  resetAuth: process.env.REACT_APP_RESET_PASSWORD_AUTH || '',
});

authenticator.run(
  () => {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById('root'),
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  },
  () => {
    ReactDOM.render(<AppInvalidLogin />, document.getElementById('root'));
  },
);
