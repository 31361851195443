import React, { useEffect, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import SimpleUser from '../../../../../models/SimpleUser';
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import Group from '../../../../../models/Group';
import NotificationGroupUserEdit from '../NotificationGroupUserEdit/NotificationGroupUserEdit';
import * as UserService from '../../../../../data/services/UserService';
import './NotificationGroupUserList.css';
import SimpleDevice from '../../../../../models/SimpleDevice';

export interface NotificationGroupUserListProps {
    onEditGroup: (notificationGroup: SimpleNotificationGroup) => void;
    dataSource?: SimpleNotificationGroup[];
    groupToEdit: Group;
    deviceToEdit?: SimpleDevice;
    onAddNotificationUser: (notificationGroup: SimpleNotificationGroup) => void;
}

const NotificationGroupUserList: React.FC<NotificationGroupUserListProps> = ({ onEditGroup, dataSource, groupToEdit, deviceToEdit, onAddNotificationUser }) => {
    const { t }: UseTranslationResponse = useTranslation();
    const [userAvailableList, setUserAvailableList] = useState<SimpleUser[]>([]);
    const [notificationGroupList, setNotificationGroupList] = useState<SimpleNotificationGroup[]>([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setLoading(true);
        UserService.GetUsersByGroupIncludeSelf(groupToEdit.id)
            .then((e) => {
                setUserAvailableList(e);
                setLoading(false);
            });
        
        if (dataSource) {
            setNotificationGroupList(dataSource);
        }
    }, [dataSource, groupToEdit]);

    const onAddUser = (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => {
        const notificationGroupIndex = notificationGroupList.indexOf(notificationGroup);
        if (notificationGroupIndex > -1) {
            const userAvailableIndex = userAvailableList.indexOf(user);

            if (userAvailableIndex > -1) {
                if (!notificationGroupList[notificationGroupIndex].users) {
                    notificationGroupList[notificationGroupIndex].users = [];
                }

                const users = notificationGroupList[notificationGroupIndex].users;

                if (users) {
                    users.push(user);
                    setNotificationGroupList([...notificationGroupList]);
                }
            }
        }
    }

    const onRemoveUser = (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => {
        const notificationGroupIndex = notificationGroupList.indexOf(notificationGroup);
        if (notificationGroupIndex > -1) {

            const users = notificationGroupList[notificationGroupIndex].users;
            if (users) {
                const userIndex = users.indexOf(user);

                if (userIndex > -1) {
                    users.splice(userIndex, 1);
                    setNotificationGroupList([...notificationGroupList]);
                }
            }
        }
    }

    const onEditGroupSelect = (notificationGroup: SimpleNotificationGroup) => {
        onEditGroup(notificationGroup);
    }

    const onToggleChildGroup = (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => {
        const notificationGroupIndex = notificationGroupList.indexOf(notificationGroup);
        if (notificationGroupIndex > -1) {

            const users = notificationGroupList[notificationGroupIndex].users;
            if (users) {
                const userIndex = users.indexOf(user);

                if (userIndex > -1) {
                    users[userIndex].Selected = user.Selected;
                    setNotificationGroupList([...notificationGroupList]);
                }
            }
        }
    }

    const onAddNotificationUserSelect = (notificationGroup: SimpleNotificationGroup) => {
        onAddNotificationUser(notificationGroup);
    };

    return (
        <div className="notificationgrouplist__content">
            {notificationGroupList.map((u, i) => {
                return (
                    <NotificationGroupUserEdit
                        key={`group${i}`}
                        notificationGroup={u}
                        userAvailable={userAvailableList}
                        onAddUser={onAddUser}
                        onRemoveUser={onRemoveUser}
                        onEditGroup={onEditGroupSelect}
                        onToggleChildGroup={onToggleChildGroup}
                        onAddNotificationUser={onAddNotificationUserSelect}
                    />
                );
            })}
        </div>
    );
};

export default NotificationGroupUserList;