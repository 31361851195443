import React, { useEffect, useState } from 'react';
import Button from '../../../../../shared/button/Button';
import SlideInModal from '../../../../../shared/slideInModal/SlideInModal';
import NotificationGroupDeviceCodeList from './NotificationGroupDeviceCodeList/NotificationGroupDeviceCodeList';
import './patchNotificationGroupEdit.css';
import { ReactComponent as CancelSvg } from '../../../../../assets/common/iconExitGrey.svg';
import { ReactComponent as DeleteSvg } from '../../../../../assets/settings/iconDeletefile.svg';
import DropDown from '../../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../../shared/dropDown/dropDownItem/DropDownItem';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import Group from '../../../../../models/Group';
import { DeviceCodeType, DeviceCode } from '../../../../../data/models/devices/DeviceCode'
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import SimpleNotificationGroupDeviceCode from '../../../../../models/SimpleNotificationGroupDeviceCode';
import Loader from '../../../../../shared/loader/Loader';
import ErrorBox from '../../../../../shared/errorBox/ErrorBox';
import * as NotificationGroupService from '../../../../../data/services/NotificationGroupService';
import * as DeviceService from '../../../../../data/services/DeviceService';
import { DeviceStatusItem } from "../../../../../models/Device";
import ConfirmDialog from '../../../../../shared/confirmDialog/ConfirmDialog';
import SimpleDevice from '../../../../../models/SimpleDevice';


export interface PatchNotificationGroupEditProps {
	open: boolean;
	notificationGroupHandleClose: () => void;
	onSubmit: (notificationGroup: SimpleNotificationGroup) => void;
	groupToEdit?: Group;
	notificationGroupToEdit?: SimpleNotificationGroup;
	onDelete?: () => void;
	deviceToEdit?: SimpleDevice;
}

const PatchNotificationGroupEdit: React.FC<PatchNotificationGroupEditProps> = ({
	open,
	notificationGroupHandleClose,
	onSubmit,
	groupToEdit,
	notificationGroupToEdit,
	onDelete,
	deviceToEdit
}) => {
	const [groupName, setGroupName] = useState('');
	const [errorOccured, setErrorOccured] = useState(false);
	const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const { t }: UseTranslationResponse = useTranslation();
	const [notificationGroupPreselectedList, setNotificationGroupPreselectedList] = useState<SimpleNotificationGroup[]>([]);
	const [notificationGroupPreselected, setNotificationGroupPreselected] = useState<SimpleNotificationGroup>();
	const [name, setName] = useState('');
	const [id, setId] = useState(-1);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [lockPreselected, setLockPreselected] = useState(false);
	const [notificationGroupDeviceCodeList, setNotificationGroupDeviceCodeList] = useState<SimpleNotificationGroupDeviceCode[]>([]);

	const [errorSelectedCount, setErrorSelectedCount] = useState(0);
	const [warningSelectedCount, setWarningSelectedCount] = useState(0);
	const [fillLevelSelectedCount, setFillLevelSelectedCount] = useState(0);
	const [serviceSelectedCount, setServiceSelectedCount] = useState(0);
	const [nameTakenList, setNameTakenList] = useState<any[]>([]);
	const [allowDelete, setAllowDelete] = useState(false);
	const [deviceName, setDeviceName] = useState('');
	const [deviceId, setDeviceId] = useState('');

	const updateSelectedCount = () => {
		const selectedErrorTotal = notificationGroupDeviceCodeList.filter(el => el.selected && el.type === DeviceCodeType.ERROR);
		setErrorSelectedCount(selectedErrorTotal.length);

		const selectedWarningTotal = notificationGroupDeviceCodeList.filter(el => el.selected && el.type === DeviceCodeType.WARNING);
		setWarningSelectedCount(selectedWarningTotal.length);

		const selectedFillLevelTotal = notificationGroupDeviceCodeList.filter(el => el.selected && el.type === DeviceCodeType.FILLLEVEL);
		setFillLevelSelectedCount(selectedFillLevelTotal.length);

		const selectedServiceTotal = notificationGroupDeviceCodeList.filter(el => el.selected && el.type === DeviceCodeType.SERVICE);
		setServiceSelectedCount(selectedServiceTotal.length);
	};

	const fetchNotificationGroup = async () => {
		try {
			if (!groupToEdit) {
				return;
			}

			const notificationGroupPresetList = await NotificationGroupService.GetPresetNotificationGroups(groupToEdit.id);
			setNotificationGroupPreselectedList(notificationGroupPresetList);
		} finally {

		}
	}

	const fetchNotificationGroupNames = async () => {
		try {
			let notificationGroup: SimpleNotificationGroup[] = [];
			if (groupToEdit && !deviceToEdit) {
				notificationGroup = await NotificationGroupService.GetNotificationGroups(groupToEdit.id);
			} else if (groupToEdit && deviceToEdit) {
				notificationGroup = await NotificationGroupService.GetNotificationDevices(groupToEdit.id, deviceToEdit.deviceId);
			}

			let _nameList: any[] = [];

			if (notificationGroup.length > 0) {
				notificationGroup.forEach((d) => {
					_nameList.push({
						"name": d.name,
						"id": d.id
					});
				});
			}

			setNameTakenList(_nameList);
		} finally {

		}
	}

	useEffect(() => {
		if (open) {
			fetchNotificationGroup();
			fetchNotificationGroupNames();

			if (groupToEdit) {
				setGroupName(groupToEdit.name);
			}

			setAllowDelete(false);
			setLockPreselected(false);

			if (notificationGroupToEdit && notificationGroupToEdit.device) {
				setDeviceName(notificationGroupToEdit.device.id);
				setDeviceId(notificationGroupToEdit.device.deviceId);
			}

			if (notificationGroupToEdit && notificationGroupToEdit.id > 0) {
				setAllowDelete(
					notificationGroupToEdit.users === undefined || notificationGroupToEdit.users.length === 0);

				if (notificationGroupToEdit.isDefault) {
					setNotificationGroupPreselected(notificationGroupToEdit);
					setLockPreselected(true);
				}

				setName(notificationGroupToEdit.name);
				setId(notificationGroupToEdit.id);
			} else {
				notificationGroupDeviceCodeList.map((e) => {
					e.selected = false;
				});
				setNotificationGroupDeviceCodeList(notificationGroupDeviceCodeList);

				setId(-1);
				setNotificationGroupPreselected(undefined);
				setName('');
				updateSelectedCount();
				setOpenDeleteModal(false);
			}

			setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);
		} else {
			setErrorOccured(false);
		}
	}, [open, groupToEdit, t, notificationGroupToEdit]);

	useEffect(() => {
		try {
			DeviceService.GetDeviceCodes().then((r) => {
				const groupItemList: SimpleNotificationGroupDeviceCode[] = [];
				r.deviceCodeItems.forEach((d) => {
					const item: SimpleNotificationGroupDeviceCode = {
						bitNumber: d.bitNumber,
						name: t(d.name),
						selected: false,
						type: d.type
					};

					if (notificationGroupToEdit) {
						let checkExist =
							notificationGroupToEdit.notificationGroupDeviceCodes.find(
								(c) => c.bitNumber === d.bitNumber);

						if (checkExist) {
							item.selected = true;
						}
					}

					groupItemList.push(item);
				});

				setNotificationGroupDeviceCodeList([...groupItemList]);

				const selectedErrorTotal = groupItemList.filter(el => el.selected && el.type === DeviceCodeType.ERROR);
				setErrorSelectedCount(selectedErrorTotal.length);

				const selectedWarningTotal = groupItemList.filter(el => el.selected && el.type === DeviceCodeType.WARNING);
				setWarningSelectedCount(selectedWarningTotal.length);

				const selectedFillLevelTotal = groupItemList.filter(el => el.selected && el.type === DeviceCodeType.FILLLEVEL);
				setFillLevelSelectedCount(selectedFillLevelTotal.length);

				const selectedServiceTotal = groupItemList.filter(el => el.selected && el.type === DeviceCodeType.SERVICE);
				setServiceSelectedCount(selectedServiceTotal.length);
			});
		} catch (e) {
			console.error(e);
		}
	}, [open]);

	useEffect(() => {
		if (notificationGroupPreselected !== undefined && notificationGroupPreselected.name !== name) {
			setNotificationGroupPreselected(undefined);
			setId(0);

			if (notificationGroupToEdit) {
				notificationGroupToEdit.isDefault = false;
			}
		}
	},
		[name]);


	const isValid = () => {
		let _valid = name.length > 0 && notificationGroupDeviceCodeList.filter(c => c.selected).length > 0;

		if (_valid && nameTakenList.length > 0) {
			let currentId = -1;

			if (notificationGroupToEdit) {
				currentId = notificationGroupToEdit.id;
			}

			let exists = nameTakenList.find((e) => e.name === name.trim());
			if (exists && exists.id !== currentId) {
				return false;
			}
		}

		return _valid;
	};

	const updateGroup = () => {
		if (!groupToEdit) {
			return;
		}

		const model: SimpleNotificationGroup = {
			name: name,
			id: id,
			isDefault: false,
			users: [],
			notificationGroupDeviceCodes: notificationGroupDeviceCodeList.filter(c => c.selected),
			isMaster: false,
			groupId: groupToEdit.id,
			deviceId: deviceId
		};

		if (deviceToEdit) {
			model.device = deviceToEdit;
			model.deviceId = deviceToEdit.deviceId;
		}

		setLoading(true);

		if (id > 0) {
			NotificationGroupService.PutNotificationGroup(
				model.id,
				model.name,
				groupToEdit.id,
				model.notificationGroupDeviceCodes)
				.then(() => {
					onSubmit(model);
				})
				.catch(() => {
					setErrorOccured(true);
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			let preselectedId = 0;
			if (notificationGroupPreselected !== undefined) {
				preselectedId = notificationGroupPreselected.id;
			}

			NotificationGroupService.PostNotificationGroup(
				preselectedId,
				groupToEdit.id,
				model.name,
				model.notificationGroupDeviceCodes,
				model.deviceId)
				.then((response) => {
					model.id = response.notificationGroupID;
					model.isDefault = false;
					onSubmit(model);
				})
				.catch(() => {
					setErrorOccured(true);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const loadPreset = (data: SimpleNotificationGroup) => {
		setNotificationGroupPreselected(data);

		notificationGroupDeviceCodeList.map((e) => {
			e.selected = false;
		});

		data.notificationGroupDeviceCodes.forEach(c => {
			const item = notificationGroupDeviceCodeList.find(a => a.bitNumber === c.bitNumber);
			if (item) {
				const index = notificationGroupDeviceCodeList.indexOf(item);
				if (index > -1) {
					notificationGroupDeviceCodeList[index].selected = true;
				}
			}
		});

		setNotificationGroupDeviceCodeList([...notificationGroupDeviceCodeList]);

		setName(data.name);

		updateSelectedCount();
	}

	const setItemSelected = (selectedItem: SimpleNotificationGroupDeviceCode) => {
		const index = notificationGroupDeviceCodeList.indexOf(selectedItem);
		notificationGroupDeviceCodeList[index] = selectedItem;

		setNotificationGroupDeviceCodeList([...notificationGroupDeviceCodeList]);

		updateSelectedCount();
	}

	const deleteGroup = () => {
		setOpenDeleteModal(true);
	};

	const deleteCurrentSelectedNotificationGroup = () => {
		if (onDelete && notificationGroupToEdit && groupToEdit) {
			NotificationGroupService.DeleteNotificationGroup(
				notificationGroupToEdit.id,
				groupToEdit.id)
				.then(() => {
					setId(-1);
					setNotificationGroupPreselected(undefined);
					setName('');
					updateSelectedCount();
					onDelete();
					setOpenDeleteModal(false);
				})
				.catch(() => {
					setOpenDeleteModal(false);
					setErrorOccured(true);
				})
				.finally(() => {
					setOpenDeleteModal(false);
					setLoading(false);
				});
		}
	};



	return (
		<>
			<SlideInModal width={700} open={open} handleClose={() => notificationGroupHandleClose()}>
				<div className="patch-notificationgroup__header">
					<div className="patch-notificationgroup__header-items">
						<div
							className="patch-user__delete"
							style={{ visibility: allowDelete ? 'visible' : 'hidden' }}
							onClick={() => deleteGroup()}
						>
							<span>{t('COMMON.DELETE')}</span>
							<DeleteSvg className="patch-user__delete-icon" />
						</div>
						<div
							className={`patch-notificationgroup__cancel patch-notificationgroup__cancel-edit`}
							onClick={() => notificationGroupHandleClose()}
						>
							<span>{t('COMMON.CANCEL')}</span>
							<CancelSvg className="patch-notificationgroup__cancel-icon" />
						</div>
						<Button
							className={`patch-notificationgroup__submit-button patch-notificationgroup__save`}
							onClick={() => updateGroup()}
							disabled={!isValid()}
						>
							{notificationGroupToEdit ? t('PATCH_NOTIFICATION.SAVE') : t('PATCH_NOTIFICATION.ADD')}
						</Button>
					</div>
				</div>
				<div className="patch-notificationgroup__body">
					<div className="patch-notificationgroup__body-title">
						{notificationGroupToEdit ? t('PATCH_NOTIFICATION.EDIT_NOTIFICATION_GROUP_LOWER') : t('PATCH_NOTIFICATION.ADD_NOTIFICATION_GROUP_LOWER')}
					</div>
					<div className="patch-notificationgroup__group_device-name-section">
						<div className="patch-notificationgroup__group-name-section">
							<div className="patch-notificationgroup__group-name-label">{t('PATCH_NOTIFICATION.GROUP_NAME')}</div>
							<div className="patch-notificationgroup__group-name">{groupName}</div>
						</div>
						{deviceId && (
							<div className="patch-notificationgroup__device-name-section">
								<div className="patch-notificationgroup__device-name-label">{t('PATCH_NOTIFICATION.DEVICE_NAME')}</div>
								<div className="patch-notificationgroup__device-name">{deviceName}</div>
							</div>
						)}
					</div>

					<div className="patch-notificationgroup__content">

						<div className="patch-notificationgroup__body-form">
							<form id="patch-user__body-form">
								{!lockPreselected && (
									<div className="patch-notificationgroup__body-form-select-box">
										<DropDown
											borderless={true}
											selected={notificationGroupPreselected}
											label={t('PATCH_NOTIFICATION.SELECT_EXISTING_NOTIFICATION_GROUP')}
											selectedChange={(data: SimpleNotificationGroup) =>
												loadPreset(data)}
											header={<DropDownHeader>{notificationGroupPreselected ? notificationGroupPreselected.name : t('PATCH_NOTIFICATION.SELECT_A_EXISTING')}</DropDownHeader>}>
											{notificationGroupPreselectedList.map((notificationGroup) => (
												<DropDownItem key={notificationGroup.id} item={notificationGroup}>
													{notificationGroup.name}
												</DropDownItem>
											))}
										</DropDown>

									</div>)}
								<div>
									<input
										type="text"
										className={name === '' ? '' : 'patch-user__body-form-input-text'}
										value={name}
										onChange={(e) => setName(e.target.value)}
										required={true}
									/>
									<label className="patch-user__body-form-input-label">{t('PATCH_USER.NAME')}</label>
								</div>

							</form>
						</div>

						<div className="patch-notificationgroup__notification-group-list">

							<NotificationGroupDeviceCodeList
								notificationGroupDeviceCodeList={notificationGroupDeviceCodeList.filter(c => c.type === DeviceCodeType.ERROR).sort((e, f) => e.name.localeCompare(f.name))}
								label={`${t('PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_ERROR')} (${errorSelectedCount} ${t('COMMON.SELECTED')})`}
								onSelectedItem={setItemSelected}
							/>

							<NotificationGroupDeviceCodeList
								notificationGroupDeviceCodeList={notificationGroupDeviceCodeList.filter(c => c.type === DeviceCodeType.WARNING).sort((e, f) => e.name.localeCompare(f.name))}
								label={`${t('PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_WARNING')} (${warningSelectedCount} ${t('COMMON.SELECTED')})`}
								onSelectedItem={setItemSelected}
							/>

							<NotificationGroupDeviceCodeList
								notificationGroupDeviceCodeList={notificationGroupDeviceCodeList.filter(c => c.type === DeviceCodeType.FILLLEVEL).sort((e, f) => e.name.localeCompare(f.name))}
								label={`${t('PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_FILLLEVEL')} (${fillLevelSelectedCount} ${t('COMMON.SELECTED')})`}
								onSelectedItem={setItemSelected}
							/>

							<NotificationGroupDeviceCodeList
								notificationGroupDeviceCodeList={notificationGroupDeviceCodeList.filter(c => c.type === DeviceCodeType.SERVICE).sort((e, f) => e.name.localeCompare(f.name))}
								label={`${t('PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_SERVICE')} (${serviceSelectedCount} ${t('COMMON.SELECTED')})`}
								onSelectedItem={setItemSelected}
							/>

						</div>
					</div>
					{openDeleteModal && (
						<ConfirmDialog
							paragraphs={[
								t('DELETE_MODAL.FIRST_PARAGRAPH'),
								t('DELETE_MODAL.SECOND_PARAGRAPH_NOTIFICATION_GROUP')
							]}
							primaryText={t('DELETE_MODAL.CONFIRM_GROUP')}
							secondaryText={t('COMMON.CANCEL')}
							primaryClick={() => {
								deleteCurrentSelectedNotificationGroup();
							}}
							secondaryClick={() => setOpenDeleteModal(false)}
							error={errorOccured}
							errorHeader={t('COMMON.ERROR')}
							errorParagraphs={[t('DELETE_MODAL.ERROR_NOTIFICATION_GROUP')]}
						></ConfirmDialog>
					)}
					{errorOccured && (
						<ErrorBox className="patch-group_error-box" paragraphs={errorParagraphs} header={t('COMMON.ERROR')} />
					)}
				</div>
				{loading && <Loader mode="fill-parent" />}
			</SlideInModal>
		</>
	);
};

export default PatchNotificationGroupEdit