import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconExitDanitechGrey } from '../../assets/common/iconExitDanitechGrey.svg';
import { Manual } from '../../models/Manual';
import { GetHelpLinks } from '../../data/services/HelpLinkService';
import SearchBar from '../../shared/searchbar/SearchBar';
import Topbar from '../../shared/topbar/Topbar';
import { ManualItem } from './ManualItem/ManualItem';
import './Manuals.css';

const FRONTPAGE = '/';

export const Manuals: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredResult, setFilteredResult] = useState<Manual[]>([]);
  const [manuals, setManuals] = useState<Manual[]>([]);

  useEffect(() => {
    GetHelpLinks()
      .then((data) => {
        setManuals(data);
        setFilteredResult(data);
      })
      .catch((err) => console.error(err));
  }, []);

  const onSearch = (s: string) => {
    setSearchTerm(s);
    if (s !== '') {
      const filteredManuals = manuals.filter(
        (item: Manual) =>
          item.title.toLowerCase().includes(s.toLowerCase()) ||
          item.description.toLowerCase().includes(s.toLowerCase()),
      );

      setFilteredResult(filteredManuals);
    } else {
      setFilteredResult(manuals);
    }
  };

  return (
    <div className="Manuals">
      <Topbar>
        <div className="home__view-type-buttons-container"></div>
        <div className="Manuals__close">
          <button className="Manuals__close-button" onClick={() => history.push(FRONTPAGE)}>
            <p>{t('COMMON.CLOSE')}</p>
            <IconExitDanitechGrey />
          </button>
        </div>
      </Topbar>
      <div className="Manuals_container">
        <div className="Manuals__body" style={{ paddingTop: filteredResult.length > 0 ? '5%' : '15%' }}>
          <div className="Manuals__body-title">
            <h1 className="Manuals__body-title-text">{t('MANUALS.FIND_YOUR_MANUAL')}</h1>
          </div>
          <div className="Manuals__body-search">
            <SearchBar search={searchTerm} onChange={onSearch} placeholder={t('MANUALS.SEARCH_MANUAL')} />
            {searchTerm === '' && <p>{t('MANUALS.SEARCH_PLACEHOLDER')}</p>}
            {searchTerm !== '' && (
              <p>
                <b>{t('MANUALS.RESULTS_AMOUNT', { amount: filteredResult.length })}</b>&nbsp;
                {t('MANUALS.RESULTS_FOR', { searchTerm })}
              </p>
            )}
          </div>
        </div>
        <div className="Manuals__body-results">
          {filteredResult.length > 0 &&
            filteredResult.map((manual, index) => (
              <ManualItem
                key={manual.id + index}
                title={manual.title}
                format={manual.fileType}
                size={manual.size}
                description={manual.description}
                downloadLink={manual.link}
                index={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
