import { PutProfile } from '../models/PutProfile';
import { authorizedAxiosInstance } from './AuthorizedAxios';

export const UpdateProfile = async (name: string, phoneNumber: string): Promise<void> => {
  const putProfile: PutProfile = {
    name,
    phoneNumber,
  };
  const authorizedAxios = await authorizedAxiosInstance();
  await authorizedAxios.put(`/api/profile`, putProfile);
  return;
};

export const InformProfileLoggedIn = async (): Promise<void> => {
  const authorizedAxios = await authorizedAxiosInstance();
  await authorizedAxios.post(`/api/profile/logged-in`);
  return;
};
