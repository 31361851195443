import { GetDeviceDetails as GetDeviceDetailsObject } from '../models/devices/GetDeviceDetails';
import { GetDevices as GetDevicesObject } from '../models/devices/GetDevices';
import { Location as DeviceLocation } from '../models/devices/Location';
import { GetDeviceCodes as GetDeviceCodesObject } from '../models/devices/GetDeviceCodes';
import { GetDeviceEventLogCodes as GetDeviceEventLogCodesObject } from '../models/devices/GetDeviceEventLogCodes';
import { authorizedAxiosInstance } from './AuthorizedAxios';

//TODO Should not return DTO GetDevicesObject should return internal model instead
export const GetDevices = async (): Promise<GetDevicesObject> => {
  const response = await (await authorizedAxiosInstance()).get<GetDevicesObject>('/api/devices');
  return response.data;
};

//TODO Should not return DTO GetDeviceDetailsObject should return internal model instead
export const GetDeviceDetails = async (deviceId: string): Promise<GetDeviceDetailsObject> => {
  const response = await (await authorizedAxiosInstance()).get<GetDeviceDetailsObject>(
    `/api/devices/${deviceId}/details`,
  );
  return response.data;
};

export const PutDeviceLocation = async (lat: number, lon: number, deviceId: string): Promise<void> => {
  const location: DeviceLocation = {
    lat: lat,
    lon: lon,
  };

  await (await authorizedAxiosInstance()).put(`/api/devices/${deviceId}/location`, location);
};

export const PutDeviceGroup = async (deviceId: string, groupId: number): Promise<void> => {
  await (await authorizedAxiosInstance()).put(`/api/devices/${deviceId}/group/${groupId}`);
};

export const GetDeviceCodes = async (): Promise<GetDeviceCodesObject> => {
    const response = await (await authorizedAxiosInstance()).get<GetDeviceCodesObject>('/api/devicecodes');
    return response.data;
};

export const TriggerNotification = async (deviceId: string): Promise<void> => {
    await (await authorizedAxiosInstance()).put(`/api/devicestriggernotification/${deviceId}`);
};

export const GetDeviceEventLogCodes = async (deviceId: string): Promise<GetDeviceEventLogCodesObject> => {
	const response = await (await authorizedAxiosInstance()).get<GetDeviceEventLogCodesObject>(`/api/devices/${deviceId}/eventlog`);
	return response.data;
};
export const GetDevicesByGroup = async (groupId: number): Promise<GetDevicesObject> => {
  const response = await (await authorizedAxiosInstance()).get<GetDevicesObject>(
    `/api/devices/${groupId}`,
  );
  return response.data;
};
