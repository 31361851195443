import React, { ReactNode, useEffect, useState } from 'react';
import { cloneReactNodeAndProps } from '../../utils/CloneReactNodeAndProps';
import './DropDown.css';
import DropDownSearch from './dropDownSearch/DropDownSearch';
import { getItemsList } from './helpers/GetItemsList';

export interface dropdownprops {
  selected: any;
  selectedChange?: (selectedItem: any) => void;
  label?: string;
  borderless?: boolean;
  header: ReactNode;
}

export const DropDown: React.FC<dropdownprops> = (props) => {
  let domHeaderRef: HTMLDivElement;
  let domChildrenRef: HTMLDivElement;
  let domContainerRef: HTMLDivElement;
  let domMaskRef: HTMLDivElement | null = null;
  const maskDeploymentReference: string = 'mask-' + new Date() + '-' + Math.random();

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const toggleOpen = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      setDropdownChildrenPosition();
    } else {
      setSearch('');
    }
  };

  useEffect(() => {
    setKeyEventListener();

    return () => {
      removeKeyEventListener();
    };
  });

  useEffect(() => {
    //Move mask
    if (domMaskRef) {
      domMaskRef.setAttribute('id', maskDeploymentReference);
      document.body.appendChild(domMaskRef);
    }

    return () => {
      //Move mask back
      const foundMaskReference = document.getElementById(maskDeploymentReference);
      if (foundMaskReference) document.body.removeChild(foundMaskReference);
    };
  }, [maskDeploymentReference, domMaskRef]);

  const setDropdownChildrenPosition = () => {
    const relevantSpace = findRelevantSpace();

    domChildrenRef.style.left = relevantSpace.left + 'px';
    domChildrenRef.style.width = relevantSpace.width + 'px';

    if (relevantSpace.placement === 'above') {
      domChildrenRef.style.bottom = relevantSpace.spaceBelow + relevantSpace.height + 5 + 'px';
      domChildrenRef.style.maxHeight = relevantSpace.spaceAbove - 15 + 'px';
      domChildrenRef.style.top = 'auto';
      domChildrenRef.classList.add('open-above');
      domChildrenRef.classList.remove('open-below');
    } else {
      domChildrenRef.style.top = relevantSpace.spaceAbove + relevantSpace.height + 5 + 'px';
      domChildrenRef.style.maxHeight = relevantSpace.spaceBelow - 15 + 'px';
      domChildrenRef.style.bottom = 'auto';
      domChildrenRef.classList.add('open-below');
      domChildrenRef.classList.remove('open-above');
    }
  };

  const keyEventListener = (evt: KeyboardEvent) => {
    const keyPressed = evt.key;
    if (isOpen) {
      if (keyPressed === 'Enter') {
        toggleOpen();
        evt.preventDefault();
      } else if (keyPressed === 'ArrowDown') {
        setNextSelected();
      } else if (keyPressed === 'ArrowUp') {
        setPrevSelected();
      } else if (keyPressed === 'Tab') {
        toggleOpen();
      } else if (keyPressed.length === 1) {
        getInput().focus();
      }
    }
  };

  const setNextSelected = () => {
    const list = getItemsList({ props: props }, search);
    if (props.selected) {
      //select next index in list, start over if end of list:
      const selectedIndex = list.indexOf(props.selected);
      const nextIndex = list.length > selectedIndex + 1 ? selectedIndex + 1 : 0;
      itemSelected(list[nextIndex]);
    } else {
      //just select first in list:
      itemSelected(list[0]);
    }
  };

  const setPrevSelected = () => {
    const list = getItemsList({ props: props }, search);
    if (props.selected) {
      //select prev index in list, take last if start of list:
      const selectedIndex = list.indexOf(props.selected);
      const prevIndex = selectedIndex > 0 ? selectedIndex - 1 : list.length - 1;
      itemSelected(list[prevIndex]);
    } else {
      //just select last in list:
      itemSelected(list[list.length - 1]);
    }
  };

  const isSelected = (item: any) => {
    return props.selected === item;
  };

  const getInput = () => {
    return domContainerRef.getElementsByClassName('dropdown-search-input')[0] as HTMLInputElement;
  };

  const setKeyEventListener = () => {
    window.addEventListener('keydown', keyEventListener);
  };

  const removeKeyEventListener = () => {
    window.removeEventListener('keydown', keyEventListener);
  };

  const findRelevantSpace = () => {
    const boundRect = domHeaderRef.getBoundingClientRect();

    const winHeight = window.innerHeight;
    const spaceAbove = boundRect.top;
    const spaceBelow = winHeight - boundRect.bottom;
    const minAllowedSpace = 400;
    let placement = '';

    //if there is enough space below we prefer below dropdown:
    if (spaceBelow >= minAllowedSpace) {
      placement = 'below';
    }
    //else we find the place with most space:
    else {
      placement = spaceBelow >= spaceAbove ? 'below' : 'above';
    }

    return {
      placement,
      spaceBelow,
      spaceAbove,
      height: boundRect.height,
      left: boundRect.left,
      right: boundRect.right,
      width: boundRect.width,
    };
  };

  const itemSelected = (item: any) => {
    if (props.selectedChange) {
      props.selectedChange(item);
    }
  };

  const searchChanged = (value: string) => {
    if (!isOpen) {
      toggleOpen();
    }
    setSearch(value);
  };

  const onInputFocus = () => {
    if (!isOpen) {
      toggleOpen();
    }
  };

  return (
    <>
      <div
        className={
          'dropdown-container ' +
          (isOpen ? 'dropdown-container-open ' : 'dropdown-container-closed ') +
          (props.borderless ? 'dropdown-container-borderless' : '')
        }
        ref={(c) => (domContainerRef = c as HTMLDivElement)}
      >
        {search.length === 0 ? (
          <div className="dropdown-header" ref={(c) => (domHeaderRef = c as HTMLDivElement)} onClick={toggleOpen}>
            {cloneReactNodeAndProps(props.header, { label: props.label })}
          </div>
        ) : null}

        <div className={'dropdown-search ' + (search ? 'dropdown-search-open' : 'dropdown-search-closed')}>
          <DropDownSearch
            inputFocused={onInputFocus}
            label={props.label}
            searchChanged={searchChanged}
            value={search}
          />
        </div>
      </div>

      <div className="dropdown-mask-placeholder">
        <div
          className={'dropdown-mask ' + (isOpen ? 'dropdown-mask-open' : 'dropdown-mask-closed')}
          onClick={toggleOpen}
          ref={(c) => (domMaskRef = c as HTMLDivElement)}
        >
          <div className="dropdown-children" ref={(c) => (domChildrenRef = c as HTMLDivElement)}>
            {React.Children.map(props.children, (child) => {
              const newEle = cloneReactNodeAndProps(child, {
                selectItem: itemSelected,
                itemIsSelected: isSelected,
                level: 0,
              });

              if (newEle) {
                if (search) {
                  const res = getItemsList(newEle, search);
                  return res.length > 0 ? newEle : null;
                }
                return newEle;
              }

              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DropDown;
