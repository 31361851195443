import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Device from '../../../../../../models/Device';
import './DeviceDetailsStatisticsInfo.css';
import '../../../../../../utils/DateFormat'
import { getFormattedDateString } from '../../../../../../utils/DateFormat';

type DeviceDetailsStatisticsInfoProps = {
	device: Device;
};

export const DeviceDetailsStatisticsInfo: React.FC<DeviceDetailsStatisticsInfoProps> = ({ device }) => {
	const { t } = useTranslation();

	const formattedTimeSinceLastEmpty = `${differenceInCalendarDays(
		new Date(),
		new Date(device.lastEmpty),
	).toString()} ${t('COMMON.DAYS_AGO')} (${format(new Date(device.lastEmpty), 'dd MMM yyyy')})`;

	let baleEjectCounter: number = device?.statistics?.find((x) => x.key === 'BaleEjectCounter')?.value || 0;

	return (
		<div className="DeviceDetailsStatisticsInfo">
			<DeviceDetailsListItem
				label={t('DEVICE.LAST_UPDATED')}
				value={getFormattedDateString(new Date(device.lastConnected))}
			/>
			<DeviceDetailsListItem label={t('DEVICE_DETAILS.TIME_SINCE_LAST_EMPTY')} value={formattedTimeSinceLastEmpty} />
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.TIME_SINCE_LAST_SERVICE')}
				value={`${differenceInCalendarDays(new Date(), new Date(device.lastService)).toString()} ${t('COMMON.DAYS')}`}
			/>
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.PRESS_CYCLES')}
				value={device?.statistics?.find((x) => x.key === 'PressCycleCounter')?.value?.toString() || '-'}
			/>
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.PRESS_CYCLES_SINCE_EMPTY')}
				value={device?.statistics?.find((x) => x.key === 'PressCyclesSinceEmpty')?.value?.toString() || '-'}
			/>
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.FULL_DETECTED_COUNTER')}
				value={device?.statistics?.find((x) => x.key === 'FullDetectCounter')?.value?.toString() || '-'}
			/>
			{(baleEjectCounter > 0) && (
				<DeviceDetailsListItem
					label={t('DEVICE_DETAILS.BALE_EJECT_COUNTER')}
					value={device?.statistics?.find((x) => x.key === 'BaleEjectCounter')?.value?.toString() || '-'}
				/>
			)}
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.RUNNING_HOURS_SINCE_SERVICE')}
				value={device?.statistics?.find((x) => x.key === 'RunningHoursSinceService')?.value?.toString() || '-'}
			/>
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.RUNNING_HOURS_TOTAL')}
				value={device?.statistics?.find((x) => x.key === 'RunningHours')?.value?.toString() || '-'}
			/>
			<DeviceDetailsListItem
				label={t('DEVICE_DETAILS.CELLULAR_REMAINING')}
				value={device?.cellularDataRemaining?.toString()+'%'|| '-'}
			/>
		</div>
	);
};

type DeviceDetailsListItemProps = {
	label: string;
	value: string;
};

const DeviceDetailsListItem: React.FC<DeviceDetailsListItemProps> = ({ label, value }) => (
	<div className="DeviceDetailsListItem">
		<div className="DeviceDetailsListItem__label">{label}</div>
		<div className="DeviceDetailsListItem__value">{value}</div>
	</div>
);
