import React from 'react';
import { ReactComponent as EmptyNormalSvg } from '../../../../../assets/deviceList/empty/iconEmptyNormal.svg';
import { ReactComponent as EmptyOverFullSvg } from '../../../../../assets/deviceList/empty/iconEmptyOverfull.svg';
import { ReactComponent as ServiceDueSvg } from '../../../../../assets/deviceList/service/iconServiceDue.svg';
import { ReactComponent as ServiceNormalSvg } from '../../../../../assets/deviceList/service/iconServiceNormal.svg';
import { ReactComponent as ChevronDown } from '../../../../../assets/common/chevron-down.svg';
import Device, { FillLevel } from '../../../../../models/Device';
import { DeviceOrderBy } from '../../../../../models/DeviceOrderingEnum';
import { LocateButton } from '../../../../../shared/LocateButton/LocateButton';
import './DeviceListCollapsed.css';
import { DeviceStateTagsDisplay } from '../../../../../shared/DeviceStateTagsDisplay/DeviceStateTagsDisplay';
import { DeviceStateValues } from '../../../../../models/DeviceStateValues';
import { OrderValue } from '../../../../../models/OrderValue';
import { useTranslation } from 'react-i18next';
import { getDeviceFillLevelIcon } from '../../../../../utils/GetDeviceFillLevel';

interface DeviceListCollapsedProps {
  devices: Device[];
  onClickLocate: (id: string) => void;
  setListOrderBy: (orderBy: DeviceOrderBy) => void;
  listOrderAttribute: string;
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onDeviceClicked: (device: Device) => void;
  showMapLocateButton: boolean;
  sortOrder: OrderValue;
  stateValues: DeviceStateValues;
}

export const DeviceListCollapsed: React.FC<DeviceListCollapsedProps> = ({
  devices,
  onClickLocate,
  setListOrderBy,
  listOrderAttribute,
  handleScroll,
  onDeviceClicked,
  showMapLocateButton,
  sortOrder,
  stateValues,
}) => {
  const { t } = useTranslation();
  const CalculateDaysNow = (date: Date) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((date.valueOf() - Date.now()) / oneDay));
  };

  return (
    <div className="DeviceListCollapsed">
      <div className="DeviceListCollapsed__header">
        <div
          className="DeviceListCollapsed__header-item DeviceListCollapsed__header-item--fill"
          onClick={() => setListOrderBy(DeviceOrderBy.FILL)}
          style={{ textDecoration: listOrderAttribute === DeviceOrderBy.FILL ? 'underline' : 'none' }}
        >
          {t('DEVICE_LIST_HEAD.FILL')}
        </div>
        <div className="DeviceListCollapsed__header-item DeviceListCollapsed__header-item--type">
          <span
            className="DeviceListCollapsed__header-item--type-id DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.ID)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.ID ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.ID')}
          </span>
          <span>{', '}</span>
          <span
            className="DeviceListCollapsed__header-item--type-type DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.TYPE)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.TYPE ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.TYPE')}
            <ChevronDown
              visibility={
                listOrderAttribute === DeviceOrderBy.ID || listOrderAttribute === DeviceOrderBy.TYPE
                  ? 'visible'
                  : 'hidden'
              }
              className={`DeviceListCollapsed__sorting-chevron DeviceListCollapsed__sorting-chevron-${sortOrder}`}
            />
          </span>
        </div>
        <div className="DeviceListCollapsed__header-item DeviceListCollapsed__header-item--customer">
          <span
            className="DeviceListCollapsed__header-item--customer-customer DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.CUSTOMER)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.CUSTOMER ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.CUSTOMER')}
          </span>
          <span>{', '}</span>
          <span
            className="DeviceListCollapsed__header-item--customer-site DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.SITE)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.SITE ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.SITE')}
            <ChevronDown
              visibility={
                listOrderAttribute === DeviceOrderBy.CUSTOMER || listOrderAttribute === DeviceOrderBy.SITE
                  ? 'visible'
                  : 'hidden'
              }
              className={`DeviceListCollapsed__sorting-chevron DeviceListCollapsed__sorting-chevron-${sortOrder}`}
            />
          </span>
        </div>
        <div className="DeviceListCollapsed__header-item DeviceListCollapsed__header-item--lastempty">
          <span
            className="DeviceListCollapsed__header-item--lastempty-empty DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.LASTEMPTY)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.LASTEMPTY ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.LAST_EMPTY')}
          </span>
          <span>{', '}</span>
          <span
            className="DeviceListCollapsed__header-item--lastempty-service DeviceListCollapsed__header--text"
            onClick={() => setListOrderBy(DeviceOrderBy.LASTSERVICE)}
            style={{ textDecoration: listOrderAttribute === DeviceOrderBy.LASTSERVICE ? 'underline' : 'none' }}
          >
            {t('DEVICE_LIST_HEAD.LAST_SERVICE')}
            <ChevronDown
              visibility={
                listOrderAttribute === DeviceOrderBy.LASTEMPTY || listOrderAttribute === DeviceOrderBy.LASTSERVICE
                  ? 'visible'
                  : 'hidden'
              }
              className={`DeviceListCollapsed__sorting-chevron DeviceListCollapsed__sorting-chevron-${sortOrder}`}
            />
          </span>
        </div>
        <div className="DeviceListCollapsed__header-item DeviceListCollapsed__header-item--locate"></div>
      </div>
      <div className="DeviceListCollapsed__body" onScroll={handleScroll}>
        {devices.map((device: Device) => {
          return (
            <div key={device.id} className="DeviceListCollapsed__item-wrapper">
              <div className="DeviceListCollapsed__centering-wrapper">
                <div className="DeviceListCollapsed__flexing-wrapper">
                  <div
                    className="DeviceListCollapsed__item DeviceListCollapsed__item--fill"
                    onClick={() => onDeviceClicked(device)}
                  >
                    <div className="DeviceListCollapsed__fill">{getDeviceFillLevelIcon(device?.fillLevel)}</div>
                  </div>
                  <div
                    className="DeviceListCollapsed__item DeviceListCollapsed__item--idtype"
                    onClick={() => onDeviceClicked(device)}
                  >
                    <div className="DeviceListCollapsed__idtype">
                      {device?.machineId.substring(0, 6)}&nbsp;·&nbsp;{device?.type}
                    </div>
                    <div className="DeviceListCollapsed__status">
                      <DeviceStateTagsDisplay deviceId={device.id} stateValues={stateValues} />
                    </div>
                  </div>
                  <div
                    className="DeviceListCollapsed__item DeviceListCollapsed__item--customer"
                    onClick={() => onDeviceClicked(device)}
                  >
                    <div className="DeviceListCollapsed__customer">
                      {device?.customer?.name || (
                        <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>
                      )}
                    </div>
                    <div className="DeviceListCollapsed__site">
                      {device?.site || <i className="devicelist__unassigned">{t('DEVICE.UNASSIGNED_CUSTOMER')}</i>}
                    </div>
                  </div>
                  <div className="DeviceListCollapsed__item" onClick={() => onDeviceClicked(device)}>
                    <div className="DeviceListCollapsed__lastempty">
                      {device?.fillLevel === FillLevel.tooFullToBaleOut || device?.fillLevel === FillLevel.overFull ? (
                        <EmptyOverFullSvg className="devicelist__icon" />
                      ) : (
                        <EmptyNormalSvg className="devicelist__icon" />
                      )}
                      {`${CalculateDaysNow(new Date(device?.lastEmpty))} ${t('COMMON.DAYS')}`}
                    </div>
                    <div className="DeviceListCollapsed__lastservice">
                      {device?.deviceStatus.serviceCodeSet.length > 0 ? (
                        <ServiceDueSvg className="devicelist__icon" />
                      ) : (
                        <ServiceNormalSvg className="devicelist__icon" />
                      )}
                      {`${CalculateDaysNow(new Date(device?.lastService))} ${t('COMMON.DAYS')}`}
                    </div>
                  </div>
                  {showMapLocateButton && (
                    <div className="DeviceListCollapsed__item DeviceListCollapsed__item--locate">
                      <LocateButton onClick={() => onClickLocate(device?.id)} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
