import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as SignoutIcon } from '../../assets/common/iconSignout.svg';
import { ReactComponent as EditIcon } from '../../assets/settings/iconEdit.svg';
import { ReactComponent as HelpIcon } from '../../assets/topbar/iconHelp.svg';
import { ReactComponent as SettingsIcon } from '../../assets/topbar/iconSettings.svg';
import { ReactComponent as ProfileIcon } from '../../assets/topbar/iconUser.svg';
import { ReactComponent as DisplayStatistics } from '../../assets/common/iconStatistics.svg';
import UserType from '../../models/UserType';
import { Profile } from '../../pages/Profile/Profile';
import authenticator from '../../services/Authenticator';
import ContextMenu from '../contextMenu/ContextMenu';
import SlideInModal from '../slideInModal/SlideInModal';
import { isBrowser } from 'react-device-detect';
import './Topbar.css';

const HELP_ROUTE = '/help';
const SETTINGS_ROUTE = '/settings';
const REPORTS_ROUTE = '/reports';

const Topbar: React.FC = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null);
    const profileButtonRef = useRef<HTMLButtonElement>(null);
    const { t } = useTranslation();
    const [showContextMenu, setShowContextMenu] = useState<boolean>(false);
    const [showProfileInfo, setShowProfileInfo] = useState<boolean>(false);
    const CONTEXT_MENU_WIDTH = 212;
    const history = useHistory();
    const location = useLocation();
    const profileInfo = authenticator.getAccount();

    const hasAccess = () => {
        if (profileInfo) {
            const loggedInUserType: UserType =
                (Number.parseInt(profileInfo.idTokenClaims['extension_Custom_Role']) as UserType) || UserType.RegularUser;
            return loggedInUserType === UserType.Admin;
        } else {
            return false;
        }
    };

    const clickedOutside = (event: MouseEvent | TouchEvent) => {
        //Check that click or touch event was not on div containing context menu
        if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
            //Also check that user didnt click on the profile button
            if (profileButtonRef.current && !profileButtonRef.current.contains(event.target as HTMLElement)) {
                setShowContextMenu(false);
            }
        }
    };

    useEffect(() => {
        //To different events so feature is supported on windows and mobile
        document.addEventListener('mousedown', clickedOutside);
        document.addEventListener('touchend', clickedOutside);
    }, [ref]);

    return (
        <div className="top-bar">
            <div
                className="topbar__changeable-area"
                style={{ width: `calc(100% - ${hasAccess() && isBrowser ? '290px' : '145px'})` }}
            >
                {children}
            </div>



            <div className="topbar__fixed-area" style={{ width: hasAccess() && isBrowser ? '188px' : '144px' }}>
                {/* TODO Set "selected" background color only on active button (based on route) */}
                <button
                    onMouseDown={(e) => e.preventDefault()}
                    id="topbar__help-button"
                    className="topbar__button"
                    onClick={() => history.push(HELP_ROUTE)}
                    style={
                        location.pathname.includes(HELP_ROUTE)
                            ? { backgroundColor: 'rgba(65,65,65,0.2)' }
                            : { backgroundColor: 'transparent' }
                    }
                >
                    <HelpIcon className="topbar__icon" title="" />
                </button>
                {hasAccess() && isBrowser && (
                    <button
                        onMouseDown={(e) => e.preventDefault()}
                        id="topbar__settings-button"
                        className="topbar__button"
                        onClick={() => history.push(SETTINGS_ROUTE)}
                        style={
                            location.pathname.includes(SETTINGS_ROUTE)
                                ? { backgroundColor: 'rgba(65,65,65,0.2)' }
                                : { backgroundColor: 'transparent' }
                        }
                    >
                        <SettingsIcon className="topbar__icon" title="" />
                    </button>
                )}
                <button
                    ref={profileButtonRef}
                    id="topbar__profile-button"
                    className="topbar__button"
                    onClick={() => setShowContextMenu(!showContextMenu)}
                >
                    <ProfileIcon className="topbar__icon" title="" />
                </button>
            </div>
            {hasAccess() && isBrowser && (
                <div className="topbar__statistics">
                    <button
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => history.push(REPORTS_ROUTE)}
                        title="Reports"
                        style={
                            location.pathname.includes(REPORTS_ROUTE)
                                ? { backgroundColor: 'rgba(65,65,65,0.2)' }
                                : { backgroundColor: 'transparent' }
                        }
                    >
                        <DisplayStatistics />
                    </button>
                </div>
            )}
            {showContextMenu && (
                <div ref={ref}>
                    <ContextMenu
                        style={{
                            width: CONTEXT_MENU_WIDTH,
                            zIndex: 8,
                        }}
                        right={50}
                        yPlacement={42}
                        menuItems={[
                            {
                                content: (
                                    <div className="topbar__context-item">
                                        <EditIcon className="topbar__context-item__icon" />
                                        <span>{t('CONTEXT_MENU.MANAGE_PROFILE')}</span>
                                    </div>
                                ),
                                onClick: () => {
                                    setShowContextMenu(false);
                                    setShowProfileInfo(!showProfileInfo);
                                },
                            },
                            {
                                content: (
                                    <div className="topbar__context-item">
                                        <SignoutIcon className="topbar__context-item__icon" />
                                        <span>{t('CONTEXT_MENU.SIGNOUT')}</span>
                                    </div>
                                ),
                                onClick: () => {
                                    setShowContextMenu(false);
                                    authenticator.signOut();
                                },
                            },
                        ]}
                    ></ContextMenu>
                </div>
            )}

            {profileInfo && (
                <SlideInModal open={showProfileInfo} handleClose={() => setShowProfileInfo(!showProfileInfo)} width={540}>
                    <Profile
                        id={profileInfo.accountIdentifier || ''}
                        name={profileInfo.name || ''}
                        email={profileInfo.idTokenClaims?.emails[0] || ''}
                        phone={profileInfo.idTokenClaims?.extension_Custom_Phone || ''}
                        setShowProfileInfo={setShowProfileInfo}
                        shown={showProfileInfo}
                    />
                </SlideInModal>
            )}

            <div className="topbar__vertical-line"></div>
        </div>
    );
};

export default Topbar;
