export interface GetUser {
  id: number;
  b2cId: string;
  fullName: string;
  userName: string;
  groupId: number;
  groupName: string;
  hasLoggedIn: boolean;
  role: UserRole;
  phoneNumber: string;
  serviceToolAccess: boolean;
  selected?: boolean;
}

export default GetUser;

export enum UserRole {
  RegularUser = 0,
  SuperUser = 1,
  Admin = 2,
  NotificationUser = -1
}
