import { TFunction } from 'i18next';
import { LatLng, LatLngBounds } from 'leaflet';
import { orderBy, union } from 'lodash';
import Device from '../models/Device';
import { DeviceOrderBy } from '../models/DeviceOrderingEnum';
import { DeviceStateValues } from '../models/DeviceStateValues';
import { FilterValue } from '../models/FilterValue';
import { OrderValue } from '../models/OrderValue';

export const OrderDevicesBy = (
  devices: Device[],
  orderedBy: DeviceOrderBy,
  searchTerm: string,
  orderDirection: OrderValue = OrderValue.ASCENDING,
  selectedFilters: FilterValue[],
  stateValues: DeviceStateValues,
  mapBounds: LatLngBounds | undefined,
  searchFillArray: TFunction,
): Device[] => {
  let devicesToFilter: string[] = [];
  let filteredDevices =
    searchTerm !== ''
      ? devices.filter((device) => {
          return (
            device.customer?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.machineId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.site?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.wasteFraction?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            device.groupTree?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            SearchByFullness(device, searchTerm, searchFillArray)
          );
        })
      : devices;

  if (selectedFilters.includes(FilterValue.EMPTY)) devicesToFilter = union(devicesToFilter, stateValues.emptyCodesSet, stateValues.almostEmptyCodesSet, stateValues.halfFullCodesSet);
  if (selectedFilters.includes(FilterValue.ALMOST_FULL))
    devicesToFilter = union(devicesToFilter, stateValues.almostFullCodesSet);
  if (selectedFilters.includes(FilterValue.FULL))
    devicesToFilter = union(
      devicesToFilter,
      stateValues.fullLevelCodesSet,
      stateValues.overFullLevelCodesSet,
      stateValues.tooFullToBaleOutCodesSet,
    );
  if (selectedFilters.includes(FilterValue.ERRORS))
    devicesToFilter = union(devicesToFilter, stateValues.errorsCodesSet);
  if (selectedFilters.includes(FilterValue.SERVICE_DUE))
    devicesToFilter = union(devicesToFilter, stateValues.serviceDueCodesSet);
  if (selectedFilters.includes(FilterValue.OFFLINE))
    devicesToFilter = union(devicesToFilter, stateValues.offlineCodesSet);
  if (selectedFilters.includes(FilterValue.OK)) devicesToFilter = union(devicesToFilter, stateValues.okCodesSet);

  if (selectedFilters.length > 0) {
    if (!selectedFilters.includes(FilterValue.MAP_VIEW) || selectedFilters.length > 1) {
      //Should not run this if selectedFilters only includes mapView
      filteredDevices = filteredDevices.filter((device) => {
        return devicesToFilter.findIndex((d) => d === device.id) !== -1;
      });
    }
  }

  if (selectedFilters.includes(FilterValue.MAP_VIEW)) filteredDevices = FilterByMapView(mapBounds, filteredDevices);

  return orderBy(filteredDevices, [orderedBy], [orderDirection]);
};

const FilterByMapView = (mapBounds: LatLngBounds | undefined, devices: Device[]): Device[] => {
  if (mapBounds) {
    return devices.reduce<Device[]>((values, d) => {
      const latlng = new LatLng(d?.location?.latitude || 0, d?.location?.longitude || 0);
      if (mapBounds.contains(latlng)) {
        values.push(d);
      }
      return values;
    }, []);
  } else {
    return devices;
  }
};

const SearchByFullness = (device: Device, searchTerm: string, searchFillArray: TFunction): boolean => {
  let fillLevelString: string;
  switch (device.fillLevel) {
    case 0:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.EMPTY');
      break;

    case 1:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.ALMOST_EMPTY');
      break;

    case 2:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.HALF_FULL');
      break;
  
    case 3:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.ALMOST_FULL');
      break;

    case 4:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.FULL');
      break;

    case 5:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.OVER_FULL');
      break;

    case 6:
      fillLevelString = searchFillArray('SEARCH_FILL_LEVEL.TOO_FULL_TO_BALE_OUT');
      break;

    default:
      fillLevelString = '';
  }

  return fillLevelString.toLowerCase().includes(searchTerm.toLowerCase());
};
