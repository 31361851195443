import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileIcon } from '../../../../../assets/common/iconFile.svg';
import { ReactComponent as StatisticsIcon } from '../../../../../assets/common/iconStatistics.svg';
import { ReactComponent as EventLogIcon } from '../../../../../assets/common/iconEventLog.svg';
import Device from '../../../../../models/Device';
import { DeviceDetailsStatisticsFiles } from '../DeviceDetailsStatisticsFiles/DeviceDetailsStatisticsFiles';
import './DeviceDetailsStatistics.css';
import { DeviceDetailsStatisticsInfo } from './DeviceDetailsStatisticsInfo/DeviceDetailsStatisticsInfo';
import { DeviceDetailsStatisticsEventLog } from './DeviceDetailsStatisticsEventLog/DeviceDetailsStatisticsEventLog';

type DeviceDetailsStatisticsProps = {
	device: Device;
	hasStatisticsAccess: boolean;
	hasEventLogAccess: boolean;
	hasFileEditRights: boolean;
};

enum SelectedField {
	STATISTICS,
	EVENTLOG,
	FILES,
}

export const DeviceDetailsStatistics: React.FC<DeviceDetailsStatisticsProps> = ({ device, hasStatisticsAccess, hasEventLogAccess, hasFileEditRights }) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState<SelectedField>(SelectedField.STATISTICS);

	useEffect(() => {
		if (!hasStatisticsAccess) {
			setSelected(SelectedField.FILES);
		} else {
			setSelected(SelectedField.STATISTICS);
		}
	}, [device]);

	return (
		<div className="DeviceDetailsStatistics">
			<div className="DeviceDetailsStatistics__selector">
				{hasStatisticsAccess && (
					<>
						<div
							className="DeviceDetailsStatistics__selector-statistics"
							style={{ color: selected === SelectedField.STATISTICS ? 'var(--error)' : 'var(--darkgrary)' }}
							onClick={() => setSelected(SelectedField.STATISTICS)}
						>
							<div className="DeviceDetailsStatistics__selector-statistics--icon">
								<StatisticsIcon />
							</div>
							<div className="DeviceDetailsStatistics__selector-statistics--text">{t('DEVICE_DETAILS.STATISTICS')}</div>
							<div
								className="DeviceDetailsStatistics__selector-statistics--indicator"
								style={{ backgroundColor: selected === SelectedField.STATISTICS ? 'var(--error)' : 'transparent' }}
							></div>
						</div>
					</>
				)}
				{hasEventLogAccess && (
					<>
						<div
							className="DeviceDetailsStatistics__selector-eventlog"
							style={{ color: selected === SelectedField.EVENTLOG ? 'var(--error)' : 'var(--darkgrary)' }}
							onClick={() => setSelected(SelectedField.EVENTLOG)}
						>
							<div className="DeviceDetailsStatistics__selector-eventlog--icon" style={{ color: selected === SelectedField.EVENTLOG ? 'var(--error)' : 'var(--darkgrary)' }}>
								<EventLogIcon />
							</div>
							<div className="DeviceDetailsStatistics__selector-eventlog--text">{t('DEVICE_DETAILS.EVENTLOG')}</div>
							<div
								className="DeviceDetailsStatistics__selector-eventlog--indicator"
								style={{ backgroundColor: selected === SelectedField.EVENTLOG ? 'var(--error)' : 'transparent' }}
							></div>
						</div>
					</>
				)}

				<div
					className="DeviceDetailsStatistics__selector-files"
					style={{ color: selected === SelectedField.FILES ? 'var(--error)' : 'var(--darkgrary)', width: !hasStatisticsAccess ? '100%' : 'none' }}
					onClick={() => setSelected(SelectedField.FILES)}
				>
					<div className="DeviceDetailsStatistics__selector-files--icon">
						<FileIcon />
					</div>
					<div className="DeviceDetailsStatistics__selector-files--text">{t('DEVICE_DETAILS.FILES')}</div>
					<div
						className="DeviceDetailsStatistics__selector-files--indicator"
						style={{ backgroundColor: selected === SelectedField.FILES ? 'var(--error)' : 'transparent' }}
					></div>
				</div>
			</div>


			<div className="DeviceDetailsStatistics__display-area">
				{selected === SelectedField.STATISTICS && <DeviceDetailsStatisticsInfo device={device} />}
				{selected === SelectedField.EVENTLOG && <DeviceDetailsStatisticsEventLog device={device} />}
				{selected === SelectedField.FILES && <DeviceDetailsStatisticsFiles device={device} fileEditRights={hasFileEditRights} />}
			</div>
		</div>
	);
};
