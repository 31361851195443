import { differenceInCalendarDays, format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Device from '../../../../../../models/Device';
import './DeviceDetailsStatisticsEventLog.css';
import '../../../../../../utils/DateFormat'
import { getFormattedDateString } from '../../../../../../utils/DateFormat';
import { DeviceDetailsStatisticsEventLogCodes } from './DeviceDetailsStatisticsEventLogCodes/DeviceDetailsStatisticsEventLogCodes';

type DeviceDetailsStatisticsEventLogProps = {
	device: Device;
};

export const DeviceDetailsStatisticsEventLog: React.FC<DeviceDetailsStatisticsEventLogProps> = ({ device }) => {
	const { t } = useTranslation();

	return (
		<>
			<DeviceDetailsStatisticsEventLogCodes device={device} />
		</>
	);
};