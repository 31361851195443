export function getItemsList(fromComponent?: any, searchStr?: string): any[] {
  const list: any[] = [];
  buildItemsList(fromComponent, list, searchStr);
  return list;
}

function buildItemsList(fromComponent: any, list: string[], searchStr?: string): void {
  if (fromComponent && fromComponent.props) {
    if (fromComponent.props.item) {
      const foundItem = fromComponent.props.item;
      if (searchStr) {
        if (typeof foundItem === 'object') {
          for (const propertyKey in foundItem) {
            const itemProp = foundItem[propertyKey];
            if (itemProp.toString().toLowerCase().indexOf(searchStr) === 0) {
              list.push(foundItem);
              break;
            }
          }
        } else if (foundItem.toString().trim().toLowerCase() === searchStr) {
          list.push(foundItem);
        }
      } else {
        list.push(foundItem);
      }
    } else if (Array.isArray(fromComponent.props.children)) {
      for (const child of fromComponent.props.children) {
        buildItemsList(child, list, searchStr);
      }
    }
  } else if (Array.isArray(fromComponent)) {
    for (const child of fromComponent) {
      buildItemsList(child, list, searchStr);
    }
  }
}
