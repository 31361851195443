export const En = {
    translations: {
        'See details': 'See details',
        'PATCH_USER.NEW_USER': 'New user',
        'PATCH_USER.EDIT_USER': 'Edit user',
        'PATCH_USER.INVITE_USER': 'INVITE USER',
        'PATCH_USER.SAVE': 'SAVE',
        'PATCH_USER.NAME': 'Name',
        'PATCH_USER.EMAIL': 'E-mail',
        'PATCH_USER.PHONE': 'Phone (Optional)',
        'PATCH_USER.SERVICE_TOOL': 'Service tool',
        'PATCH_USER.ALLOW_SERVICE_TOOL': 'Allow service tool',
        'PATCH_USER.PLEASE_SELECT': 'Please select',
        'PATCH_USER.SELECT_GROUP': 'Select group',
        'PATCH_USER.ERROR_INVITE': 'Could not invite new user',
        'PATCH_USER.ERROR_EDIT': 'Could not edit user',
        'COMMON.DELETE': 'DELETE',
        'DELETE_MODAL.FIRST_PARAGRAPH': 'Are you sure you want',
        'DELETE_MODAL.SECOND_PARAGRAPH_USER': 'to delete this user?',
        'DELETE_MODAL.SECOND_PARAGRAPH_GROUP': 'to delete this group?',
        'DELETE_MODAL.SECOND_PARAGRAPH_NOTIFICATION_GROUP': 'to delete this notification group?',
        'DELETE_MODAL.SECOND_PARAGRAPH_NOTIFICATION_GROUP_USER':'to remove this user from this group?',
        'DELETE_MODAL.THIRD_PARAGRAPH_GROUP': 'All children of this group will also be deleted!',
        'DELETE_MODAL.SECOND_PARAGRAPH_ATTACHMENT': 'to delete the file?',
        'DELETE_MODAL.CONFIRM_USER': 'DELETE USER',
        'DELETE_MODAL.ERROR_GROUP': 'Could not delete selected group',
        'DELETE_MODAL.ERROR_USER': 'Could not delete selected user',
        'DELETE_MODAL.ERROR_NOTIFICATION_GROUP': 'Could not delete selected notification group',
        'COMMON.CANCEL': 'CANCEL',
        'DELETE_MODAL.CONFIRM_GROUP': 'DELETE GROUP',
        'DELETE_MODAL.CONFIRM_REMOVE_USER': 'REMOVE USER',
        'DRAG_AND_DROP.FIRST_PART': 'Drag and drop files',
        'DRAG_AND_DROP.SECOND_PART': 'or',
        'DRAG_AND_DROP.THIRD_PART': 'click here to upload',
        'DRAG_AND_DROP.ERROR_GET': 'Could not get existing files from backend',
        'DRAG_AND_DROP.ERROR_UPLOAD': 'Could not upload file',
        'DRAG_AND_DROP.ERROR_REMOVE': 'Could not remove file',

        //Locate button
        'LOCATE_BUTTON.LOCATE': 'Locate',

        //Add button
        'ADD_BUTTON.ADD_NEW': 'Add new',

        // Common
        'COMMON.NAME': 'Name',
        'COMMON.EMAIL': 'E-mail',
        'COMMON.DOWNLOAD_CAPS': 'DOWNLOAD',
        'COMMON.ERROR': 'Error occured',
        'COMMON.DAYS': 'days',
        'COMMON.DAYS_AGO': 'days ago',
        'COMMON.SAVE': 'Save',
        'COMMON.SELECTED': 'selected',
        'COMMON.REMOVE': 'Remove',
        'COMMON.SUBGROUP': 'Sub group',

        // Profile
        'PROFILE_TOP_BAR.SAVE': 'Save',
        'PROFILE.YOUR_PROFILE': 'Your profile',
        'PROFILE.PHONE_OPT': 'Phone (Optional)',
        'PROFILE.CHANGEPW': 'Change password',
        'PROFILE.SIGNOUT': 'Signout',
        'PROFILE.PASSWORD': 'Password',
        'PROFILE.SAVEPW': 'Save password',
        'PROFILE.CONFIRMPW': 'Confirm current password',
        'PROFILE.ENTERNEWPW': 'Enter new password',
        'PROFILE.RETYPENEWPW': 'Retype new password',
        'PROFILE.ERROR_UPDATE': 'Could not update profile',
        'COMMON.CLOSE': 'Close',
        'COMMON.DOWNLOAD': 'Download',
        'COMMON.CUSTOMER': 'Customer',
        'COMMON.FILL_LEVEL': 'Fill level',

        'MARKPOPUP.SEE_DETAILS': 'See details',

        'MANUALS.SEARCH_MANUAL': 'Search manual…',
        'MANUALS.SEARCH_PLACEHOLDER': 'Type your machine type or ID to find your manual',
        'MANUALS.RESULTS_AMOUNT': '{{amount}} results',
        'MANUALS.RESULTS_FOR': "for '{{searchTerm}}'",
        'MANUALS.FIND_YOUR_MANUAL': 'Find your manual',

        // Status codes from devices
        STATUS_MACHINE_IS_ALMOST_FULL: 'Machine almost full',
        STATUS_FSMP_OIL_LEVEL_ERROR: 'Oil level error',
        STATUS_FSMP_FEEDBACK_FAILURE: 'Feedback failure',
        STATUS_FSMP_PARAMS_UNSAFE: 'Params unsafe',
        STATUS_FSMP_MOTOR_THERMO_NOT_OK: 'Motor thermo not ok',
        STATUS_FSMP_MORE_THAN_1_BTN_ACTIVE: 'More than 1 button active',
        STATUS_FSMP_OIL_TEMPERATURE_ERROR: 'Oil temperature error',
        STATUS_FSMP_GUILLOTINE_SENSOR_ERROR: 'Guillotine sensor error',
        STATUS_FSMP_DOOR_OPEN_SW_ERROR: 'Door open SW error',
        STATUS_FSMP_DOOR_FULLY_OPEN_SW_ERROR: 'Door fully open SW error',
        STATUS_FSMP_DOOR_FULLY_OPEN_ERROR: 'Door fully open error',
        STATUS_FSMP_FRONT_BLOCK_ERROR: 'Front block error',
        STATUS_FSMP_OIL_LEAKAGE_DETECTED: 'Oil leakage detected',
        STATUS_OSSD_TEST_FAILED: 'OSSD test failed',
        STATUS_RM42_FLASH_CRC_VALIDATION_FAILED: 'RM42 Flash CRC validation failed',
        STATUS_POWER_RAIL_FAILURE: 'Power rail failure',
        STATUS_POWER_OFF_SIGNAL_FAILURE: 'Power off signal failure',
        STATUS_CONTACTOR_FAILURE: 'Contactor failure',
        STATUS_TRAVEL_TIMES_ERROR: 'Travel times error',
        STATUS_REBOOT_PENDING_RM42: 'Reboot pending RM42',
        STATUS_OIL_TRANSDUCER_ERROR: 'Oil transducer error',
        STATUS_PHASE_DETECT_OPERATION_BLOCKED: 'Phase detect operation blocked',
        STATUS_MAX_VALVE_CURRENT_ERROR: 'Max valve current error',
        STATUS_BACK_SWITCH_INACTIVE_AT_BACK: 'Back switch inactive at back',
        STATUS_FSMP_MACHINE_IS_FULL: 'Machine is full',
        STATUS_FSMP_MACHINE_IS_OVERFULL: 'Machine is overfull',
        STATUS_TOO_FULL_TO_BALE_OUT: 'Too full to bale out',
        STATUS_SERVICE_IS_DUE: 'Service due',
        STATUS_FSMP_START_BUTTON_BLOCKED: 'Start button blocked',
        STATUS_FSMP_RAM_OPERATION_BLOCKED: 'Ram operation blocked',
        STATUS_FSMP_ESTOP_PRESSED: 'Estop pressed',
        STATUS_FSMP_CONTAINER_NOT_PRESENT: 'Container not present',
        STATUS_FSMP_CLOSE_NON_CRITICAL_DOOR: 'Close non-critical door',
        STATUS_FSMP_DOOR_OPEN_SW_ACTIVE: 'Door open SW active',
        STATUS_SERVICE_MODE_ACTIVE: 'Service mode active',
        STATUS_WAITING_FOR_RESET_AFTER_ESTOP: 'Waiting for reset after estop',
        STATUS_BLOCK_OPERATION_HOURS_ACTIVE: 'Block operation hours active',
        STATUS_AUTOSTART_AWAITING_MANUAL_START: 'Autostart awaiting manual start',
        STATUS_BACK_SWITCH_ACTIVE_AT_FRONT: 'Back Switch Active  - Ram in Front',
        STATUS_SHARK_LOADING_BIN_ERROR: 'Shark Loading Bin Error',
        STATUS_SHARK_PRESSING_RAM_ERROR: 'Shark Pressing Ram Error',
        BACK_BLOCK_DETECT_ERROR: 'Back Block Detect',
        INPUT_TIMING_ERROR: 'Input timing error',
        CT_SCRAPE_ERROR: 'Scrape Error',
        SHARK_READY_FOR_AUTOMATIC_RUN: 'Shark ready for automatic run',
        STATUS_MACHINE_IN_ERROR: 'General error',
        STATUS_SOFT_STARTER_COOLING_DOWN: 'Soft starter cooling down',
        TIPPING_ARM_UP_STOPPED_ON_MAX_PRESSURE: 'Tipping arm up stopped on max pressure',
        STATUS_BALED_OUT: 'Baled out',
        STATUS_FILL_LEVEL_0_ACTIVE: 'Fill Level 0 active',
        STATUS_FILL_LEVEL_1_ACTIVE: 'Fill Level 1 active',
        STATUS_FILL_LEVEL_2_ACTIVE: 'Fill Level 2 active',
        STATUS_FILL_LEVEL_3_ACTIVE: 'Fill Level 3 active',
        STATUS_EMPTY_DETECTED: 'Empty Detected',



        //Patch group
        'PATCH_GROUP.CREATE_GROUP': 'Create group',
        'PATCH_GROUP.EDIT_GROUP': 'Edit group',
        'PATCH_GROUP.CREATE': 'CREATE',
        'PATCH_GROUP.SAVE': 'SAVE',
        'PATCH_GROUP.GROUP_NAME': 'Group name',
        'PATCH_GROUP.NEST_UNDER': 'Nest group under',
        'PATCH_GROUP.SELECT_GROUP': 'Select group',
        'PATCH_GROUP.ERROR_EDIT': 'Could not edit group',
        'PATCH_GROUP.ERROR_ADD': 'Could not add group',

        //Groups list
        'GROUPS_LIST.TITLE': 'Groups',
        'GROUPS_LIST.NAME': 'Name',
        'GROUPS_LIST.USERS': 'Users',
        'GROUPS_LIST.FLEET': 'Fleet',

        //Users list
        'USERS_LIST.USER_ACTIVE': 'Active',
        'USERS_LIST.USER_INVITE_PENDING': 'Inivte Pending',
        'USERS_LIST.USER_ACTIVE_ALL_CAPS': 'ACTIVE',
        'USERS_LIST.USER_INVITE_PENDING_ALL_CAPS': 'INVITE PENDING',
        'USERS_LIST.REGULAR_USER_ROLE': 'Regular User',
        'USERS_LIST.SUPER_USER_ROLE': 'Super User',
        'USERS_LIST.ADMIN_ROLE': 'Admin',
        'USERS_LIST.NOTIFICATION_USER_ROLE': 'Notification User',
        'USERS_LIST.TITLE': 'Users',
        'USERS_LIST.NAME': 'Name',
        'USERS_LIST.ROLE': 'Role',
        'USERS_LIST.GROUP': 'Group',
        'USERS_LIST.STATUS': 'Status',

        //Notifications list
        'NOTIFICATIONS_LIST.TITLE': 'Notifications',
        'NOTIFICATIONS_LIST.NAME': 'Name',
        'NOTIFICATIONS_LIST.USERS': 'Users',
        'NOTIFICATIONS_LIST.FLEET': 'Associated machines',
        'NOTIFICATIONS_LIST.RECEIVERS': 'Receivers',

        //Patch notification
        'PATCH_NOTIFICATION.EDIT_NOTIFICATION': 'Edit group notification',
        'PATCH_NOTIFICATION.SAVE': 'SAVE',
        'PATCH_NOTIFICATION.GROUP_NAME': 'Group name',
        'PATCH_NOTIFICATION.ERROR_EDIT': 'Could not edit group',
        'PATCH_NOTIFICATION.ADD_USER_GROUP': 'Add user to group',
        'PATCH_NOTIFICATION.SELECT_USER': 'Select user',
        'PATCH_NOTIFICATION.ADD': 'ADD',
        'PATCH_NOTIFICATION.ADD_NOTIFICATION_GROUP': 'ADD NOTIFICATION GROUP',
        'PATCH_NOTIFICATION.ADD_NOTIFICATION_GROUP_LOWER': 'Add notification group',
        'PATCH_NOTIFICATION.EDIT_NOTIFICATION_GROUP_LOWER': 'Edit notification group',
        'PATCH_NOTIFICATION.SELECT_EXISTING_NOTIFICATION_GROUP': 'Select existing notification group',
        'PATCH_NOTIFICATION.SELECT_A_EXISTING': 'Select an existing',
        'PATCH_NOTIFICATION.PRESET_GROUP_SERVICE': 'Service',
        'PATCH_NOTIFICATION.PRESET_GROUP_WASTE_HANDLING': 'Waste handling',
        'PATCH_NOTIFICATION.PRESET_GROUP_ONSITE_MANAGER': 'On-site manager',
        'PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_ERROR': 'Error',
        'PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_WARNING': 'Warning',
        'PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_FILLLEVEL': 'Fill Level',
        'PATCH_NOTIFICATION.PRESET_GROUP_ITEMLIST_SERVICE': 'Service',
        'PATCH_NOTIFICATION.DEVICE_NAME': 'Machine name',
        'PATCH_NOTIFICATION.EDIT_NOTIFICATION_DEVICE': 'Edit machine notification',
        'PATCH_NOTIFICATION.SELECT_MACHINE': 'Select machine',

        //Context menu
        'CONTEXT_MENU.TITLE': 'Actions',
        'CONTEXT_MENU.EDIT_GROUP': 'Edit Group',
        'CONTEXT_MENU.DELETE_GROUP': 'Delete Group',
        'CONTEXT_MENU.EDIT_USER': 'Edit User',
        'CONTEXT_MENU.DELETE_USER': 'Delete User',
        'CONTEXT_MENU.MANAGE_PROFILE': 'Manage profile',
        'CONTEXT_MENU.SIGNOUT': 'Signout',
        'CONTEXT_MENU.EDIT_NOTIFICATION': 'Edit Group Notification',
        'CONTEXT_MENU.EDIT_NOTIFICATION_DEVICE': 'Edit Machine Notification',

        'DEVICE.UNASSIGNED_CUSTOMER': 'Unassigned',
        'DEVICE.LAST_UPDATED': 'Last updated',

        'DEVICE_DETAILS.STATISTICS': 'Statistics',
        'DEVICE_DETAILS.FILES': 'Files',
        'DEVICE_DETAILS.EVENTLOG': 'Event log',

        'DEVICE_DETAILS.TIME_SINCE_LAST_EMPTY': 'Last empty',
        'DEVICE_DETAILS.TIME_SINCE_LAST_SERVICE': 'Time since last service',
        'DEVICE_DETAILS.PRESS_CYCLES': 'Press cycles',
        'DEVICE_DETAILS.PRESS_CYCLES_SINCE_EMPTY': 'Press cycles since empty',
        'DEVICE_DETAILS.FULL_DETECTED_COUNTER': 'Full detected counter',
        'DEVICE_DETAILS.BALE_EJECT_COUNTER': 'Bale eject counter',
        'DEVICE_DETAILS.RUNNING_HOURS_SINCE_SERVICE': 'Running hours since service',
        'DEVICE_DETAILS.RUNNING_HOURS_TOTAL': 'Running hours total',
        'DEVICE_DETAILS.CUSTOMER_ERROR': 'Could not change customer on current device',
        'DEVICE_DETAILS.CELLULAR_REMAINING':'Cellular data remaining',

        // Device details
        'D_DETAILS.FILL_LEVEL': 'Fill level',
        'D_DETAILS.CUSTOMER': 'Group',
        'D_DETAILS.SITE': 'Site',
        'D_DETAILS.POSITION': 'Position',
        'D_DETAILS.GET_DIRECTIONS': 'Get directions',
        'D_DETAILS.UPDATE_LOCATION_ERROR': 'Could not update location',
        'D_DETAILS.INVALID_LOCATION_TITLE': 'Invalid location value',

        // Device list
        'DEVICE_LIST.NO_DEVICES': 'Your group currently contains no devices',
        'DEVICE_LIST_HEAD.FILL': 'Fill',
        'DEVICE_LIST_HEAD.ID': 'ID',
        'DEVICE_LIST_HEAD.TYPE': 'Type',
        'DEVICE_LIST_HEAD.CUSTOMER': 'Customer',
        'DEVICE_LIST_HEAD.SITE': 'Site',
        'DEVICE_LIST_HEAD.LAST_EMPTY': 'Last empty',
        'DEVICE_LIST_HEAD.LAST_SERVICE': 'Last service',
        'DEVICE_LIST_HEAD.WASTEFRACTION': 'Waste fraction',

        //Device Filters
        'DEVICE_FILTER.ERRORS': 'Errors',
        'DEVICE_FILTER.SERVICE_DUE': 'Service due',
        'DEVICE_FILTER.OFFLINE': 'Offline',
        'DEVICE_FILTER.FULL': 'Full',
        'DEVICE_FILTER.HALF_FULL': 'Half full',
        'DEVICE_FILTER.ALMOST_FULL': 'Almost full',
        'DEVICE_FILTER.OK': 'OK',
        'DEVICE_FILTER.EMPTY_AND_LOW': 'Low',
        'DEVICE_FILTER.FILTER_MAP': 'Filter by map view',
        'DEVICE_FILTER.SHOW_ALL': 'Show all ({{amount}})',
        'DEVICE_FILTER.UNKNOWN': 'Unknown',

        //Device Filter Button
        'DEVICE_FILTER_BUTTON.SHOW': 'Show filters',
        'DEVICE_FILTER_BUTTON.HIDE': 'Hide filters',
        'DEVICE_LIST.NO_SEARCH_RESULT': 'No results',

        // Fill levels
        'FILL_LEVEL.EMPTY': 'Empty',
        'FILL_LEVEL.ALMOST_EMPTY': 'Almost empty',
        'FILL_LEVEL.HALF_FULL': 'Half full',
        'FILL_LEVEL.ALMOST_FULL': 'Almost full',
        'FILL_LEVEL.FULL': 'Full',
        'FILL_LEVEL.OVERFULL': 'Over full',
        'FILL_LEVEL.TOOFULL': 'Too full to bale out',
        'FILL_LEVEL.FILL_LEVEL_0': 'Fill level 0',
        'FILL_LEVEL.FILL_LEVEL_1': 'Fill level 1',
        'FILL_LEVEL.FILL_LEVEL_2': 'Fill level 2',
        'FILL_LEVEL.FILL_LEVEL_3': 'Fill level 3',

        'OFFLINE.OFFLINE': 'Offline',
        'OFFLINE.CHECK_CONNECTION': 'Please check your network connection',
        // Service tool
        'SERVICE_TOOL.OPEN': 'Open service tool',

        //Settings
        'SETTINGS.CLOSE': 'CLOSE SETTINGS',
        'SETTINGS.USERS': 'Users',
        'SETTINGS.GROUPS': 'Groups',
        'SETTINGS.NOTIFICATIONS': 'Notifications',

        //Search bar
        'DEVICE_SEARCH_BAR.PLACEHOLDER': 'Filter by ID, type, customer, ...',
        'GROUPS_LIST_SEARCH_BAR.PLACEHOLDER': 'Filter by name, ...',
        'USERS_LIST_SEARCH_BAR.PLACEHOLDER': 'Filter by username, group, role, ...',

        //Search inputs
        'SEARCH_FILL_LEVEL.EMPTY': 'Empty',
        'SEARCH_FILL_LEVEL.ALMOST_EMPTY': 'Almost empty',
        'SEARCH_FILL_LEVEL.HALF_FULL': 'Half full',
        'SEARCH_FILL_LEVEL.ALMOST_FULL': 'Almost full',
        'SEARCH_FILL_LEVEL.FULL': 'Full',
        'SEARCH_FILL_LEVEL.OVER_FULL': 'Over full',
        'SEARCH_FILL_LEVEL.TOO_FULL_TO_BALE_OUT': 'Too full to bale out',

        //State tags
        'STATE_TAG_ALL_CAPS.OK': 'OK',
        'STATE_TAG_ALL_CAPS.SERVICE_DUE': 'SERVICE DUE',
        'STATE_TAG_ALL_CAPS.OFFLINE': 'OFFLINE',
        'STATE_TAG_ALL_CAPS.ERROR': 'ERROR',
        'STATE_TAG_ALL_CAPS.FULL': 'FULL',

        //Invalid Origin no Acces
        'INVALID_ORIGIN.NO_ACCESS': 'No Access',

        //Event log
        'EVENT_LOG.NO_RECORDS': 'No records',

        //Report
        'REPORT.LABEL_TOTAL': 'Total',
        'REPORT.LABEL_PERCENTAGE': 'Percentage',
        'REPORT.DATATYPE_FILLLEVELINDEX': 'Fill Level',
        'REPORT.DATATYPE_FULLDETECTCOUNTER': 'Full Counter',
        'REPORT.DATATYPE_BALEEJECTCOUNTER': 'Bale out',
        'REPORT.MONTH_JANUARY': 'January',
        'REPORT.MONTH_FEBRUARY': 'February',
        'REPORT.MONTH_MARCH': 'March',
        'REPORT.MONTH_APRIL': 'April',
        'REPORT.MONTH_MAY': 'May',
        'REPORT.MONTH_JUNE': 'June',
        'REPORT.MONTH_JULY': 'July',
        'REPORT.MONTH_AUGUST': 'August',
        'REPORT.MONTH_SEPTEMBER': 'September',
        'REPORT.MONTH_OCTOBER': 'October',
        'REPORT.MONTH_NOVEMBER': 'November',
        'REPORT.MONTH_DECEMBER': 'December',
        'REPORT.DATATYPE_SELECT': 'Data type',
        'REPORT.PERIODTYPE_SELECT': 'Period type',
        'REPORT.PERIOD_SELECT':'Period',
        'REPORT.DEVICE_SEARCH_PLACEHOLDER': 'Filter by ID, type, customer,...'
    },
};
