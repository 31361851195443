import React from 'react';
import './DropDownSearch.css';

export interface dropdownsearchprops {
  value: string;
  searchChanged: (value: string) => void;
  label?: string;
  inputFocused: () => void;
}
export const DropDownSearch: React.FC<dropdownsearchprops> = (props) => {
  return (
    <>
      <div className="dropdown-search-content">
        {props.label ? <label>{props.label}</label> : null}
        <input
          className="dropdown-search-input"
          type="text"
          value={props.value}
          onChange={(evt) => {
            props.searchChanged(evt.target.value);
          }}
          onFocus={() => {
            props.inputFocused();
          }}
        />
      </div>
    </>
  );
};

export default DropDownSearch;
