import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import UserType from '../../models/UserType';
import authentication from '../../services/Authenticator';

export interface AccesRouteProps {
  requiredRole: UserType;
  component: React.FC;
  path: string;
}

export const AccessRoute: React.FC<AccesRouteProps> = ({ requiredRole, component, path }) => {
  const idTokenClaims = authentication.getAccount().idTokenClaims;
  const loggedInUserRole: UserType = Number.parseInt(idTokenClaims['extension_Custom_Role']) as UserType;
  return loggedInUserRole >= requiredRole ? <Route path={path} component={component} /> : <Redirect to="/" />;
};

export default AccessRoute;
