import L from 'leaflet';
import Leaflet from 'leaflet';
import { DeviceStateValues } from '../models/DeviceStateValues';

export const GetCustomMarkerIconByStatus = (stateValues: DeviceStateValues, deviceId: string): Leaflet.DivIcon => {
  const normalPointerSvg = `<svg
xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 29">
<g fill-rule="nonzero" fill="none">
  <path d="M9 .5a8.44 8.44 0 016.01 2.508 8.573 8.573 0 012.49 6.059c0 2.961-2.437 8.288-7.226 15.991a1.5 1.5 0 01-2.547.001h0l-.29-.466C2.842 17.14.5 11.968.5 9.067c0-2.366.952-4.509 2.49-6.06A8.44 8.44 0 019 .5z" stroke="#FFF" fill="var(--accentgray)"/>
  <circle fill="#FFF" cx="9" cy="9" r="2"/>
</g>
</svg>`;

  const errorPointerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 29">
<g fill-rule="nonzero" fill="none">
  <path d="M9 .5a8.44 8.44 0 016.01 2.508 8.573 8.573 0 012.49 6.059c0 2.961-2.437 8.288-7.226 15.991a1.5 1.5 0 01-2.547.001h0l-.29-.466C2.842 17.14.5 11.968.5 9.067c0-2.366.952-4.509 2.49-6.06A8.44 8.44 0 019 .5z" stroke="#FFF" fill="var(--error)"/>
  <circle fill="#FFF" cx="9" cy="9" r="2"/>
</g>
</svg>`;

  const serviceDuePointerSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="29" viewBox="0 0 18 29">
  <g fill="none" fill-rule="evenodd">
    <path fill="var(--accentgray)" fill-rule="nonzero" stroke="#FFF" d="M9,0.5 C11.3467907,0.5 13.4712414,1.45855448 15.0091057,3.00780826 C16.5483003,4.55840226 17.5,6.70061382 17.5,9.06665462 C17.5,12.0284926 15.0625045,17.3551971 10.2739249,25.0584186 C10.1523745,25.2539524 9.9874449,25.418882 9.79191108,25.5404324 C9.44012419,25.7591148 9.03300236,25.8145048 8.65915664,25.7272775 C8.28531092,25.6400501 7.94474131,25.4102054 7.72652262,25.059166 L7.72652262,25.059166 L7.43785363,24.5929684 C2.84070988,17.1397491 0.5,11.9681188 0.5,9.06665462 C0.5,6.70061382 1.45169971,4.55840226 2.99089434,3.00780826 C4.52875858,1.45855448 6.65320933,0.5 9,0.5 Z"/>
    <path fill="#FFF" fill-rule="nonzero" d="M10.390408,5.94252822 C11.870923,5.94252822 13.1635735,6.74687146 13.8552095,7.94240778 L10.390408,7.94252822 L9,9.94252822 L10.390408,11.9425282 L13.8546373,11.9436374 C13.1628607,13.1386285 11.8705148,13.9425282 10.390408,13.9425282 C8.8176317,13.9425282 7.45686372,13.0348129 6.8034063,11.7146844 L4.89122232,11.7137918 C4.33893757,11.7137918 3.89122232,11.2660766 3.89122232,10.7137918 L3.89122232,9.71379181 C3.89122232,9.16150706 4.33893757,8.71379181 4.89122232,8.71379181 L6.58266564,8.71381952 C7.10115354,7.10582097 8.60992996,5.94252822 10.390408,5.94252822 Z" transform="rotate(-45 8.873 9.943)"/>
  </g>
</svg>
`;

  const warningPointerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 29">
<g fill-rule="nonzero" fill="none">
  <path d="M9 .5a8.44 8.44 0 016.01 2.508 8.573 8.573 0 012.49 6.059c0 2.961-2.437 8.288-7.226 15.991a1.5 1.5 0 01-2.547.001h0l-.29-.466C2.842 17.14.5 11.968.5 9.067c0-2.366.952-4.509 2.49-6.06A8.44 8.44 0 019 .5z" stroke="#FFF" fill="#E1A11B"/>
  <circle fill="#FFF" cx="9" cy="9" r="2"/>
</g>
</svg>`;

  const offlinePointerSvg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 29">
    <g fill-rule="nonzero" fill="none">
     <path d="M9 .5a8.44 8.44 0 016.01 2.508 8.573 8.573 0 012.49 6.059c0 2.961-2.437 8.288-7.226 15.991a1.5 1.5 0 01-2.547.001h0l-.29-.466C2.842 17.14.5 11.968.5 9.067c0-2.366.952-4.509 2.49-6.06A8.44 8.44 0 019 .5z" stroke="#FFF" fill="var(--offlinemarker)"/>
     <path d="M9.76 12.745l.3-6.75H7.765l.315 6.75h1.68zm-.84 3.405c.66 0 1.215-.555 1.215-1.2a1.23 1.23 0 00-1.215-1.215c-.66 0-1.2.555-1.2 1.215 0 .645.54 1.2 1.2 1.2z" fill="#FFF"/>
    </g>
  </svg>
   `;

  const getMarker = (pointerSVG: string): Leaflet.DivIcon => {
    const divIcon = Leaflet.divIcon({
      html: Leaflet.Util.template(pointerSVG, {
        mapIconUrl: pointerSVG,
        mapIconColor: '#000',
        mapIconColorInnerCircle: '#fff',
        pinInnerCirleRadius: 10,
      }),
      iconSize: new L.Point(18, 29),
      iconAnchor: new L.Point(9, 27),
      popupAnchor: new L.Point(0, -20),
    });

    return divIcon;
  };

  if (stateValues.okCodesSet.includes(deviceId)) {
    return getMarker(normalPointerSvg);
  } else if (stateValues.offlineCodesSet.includes(deviceId)) {
    return getMarker(offlinePointerSvg);
  } else if (stateValues.errorsCodesSet.includes(deviceId)) {
    return getMarker(errorPointerSvg);
  } else if (stateValues.serviceDueCodesSet.includes(deviceId)) {
    return getMarker(serviceDuePointerSvg);
  } else if (
    stateValues.fullLevelCodesSet.includes(deviceId) ||
    stateValues.overFullLevelCodesSet.includes(deviceId) ||
    stateValues.tooFullToBaleOutCodesSet.includes(deviceId)
  ) {
    return getMarker(warningPointerSvg);
  } else {
    return getMarker(normalPointerSvg);
  }
};
