import React from 'react';
import './DropDownItem.css';

export interface dropdownitemprops {
  item: any;
  selectItem?: (item: any) => void;
  level?: number;
  itemIsSelected?: (item: any) => boolean;
}
export const DropDownItem: React.FC<dropdownitemprops> = (props) => {
  return (
    <>
      <div
        style={{ paddingLeft: (props.level ? props.level * 14 : 14) + 'px' }}
        className={
          'dropdown-item-content ' +
          (props.itemIsSelected && props.itemIsSelected(props.item)
            ? 'dropdown-item-content-selected'
            : 'dropdown-item-content-unselected')
        }
        onClick={() => {
          if (props.selectItem) {
            props.selectItem(props.item);
          }
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default DropDownItem;
