import React, { ReactNode } from 'react';

export function cloneReactNodeAndProps(reactNode: ReactNode, props: any): any {
  if (React.isValidElement(reactNode)) {
    const reactNodeProps = Object.assign({}, reactNode.props, props);
    const newEle = React.cloneElement(reactNode, reactNodeProps);
    return newEle;
  }

  return null;
}
