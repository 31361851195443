import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDownload } from '../../../assets/common/iconDownload.svg';
import './ManualItem.css';

type ManualItemProps = {
  title: string;
  format: string;
  size: number;
  description: string;
  downloadLink?: string;
  index: number;
};

export function sizeOf(bytes: number): string {
  if (bytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
}

export const ManualItem: React.FC<ManualItemProps> = ({
  title,
  format,
  size,
  description,
  downloadLink = '#',
  index,
}) => {
  const { t } = useTranslation();

  return (
    <div className="ManualItem" style={{ animationDelay: `${150 * index}ms` }}>
      <div>
        <div className="ManualItem__left">
          <div className="ManualItem__title">{title}</div>
          <div className="ManualItem__technical">{`${format} · ${sizeOf(size)}`}</div>
        </div>
        <a href={downloadLink} className="ManualItem__download">
          <div className="ManualItem__download-text">{t('COMMON.DOWNLOAD')}</div>
          <div className="ManualItem__download-icon">
            <IconDownload />
          </div>
        </a>
      </div>
      <div className="ManualItem__description">{description}</div>
    </div>
  );
};
