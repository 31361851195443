import { convertToGroup, GroupStructureItem } from '../models/GroupStructureItem';
import PostGroup from '../models/PostGroup';
import { PutGroup } from '../models/PutGroup';
import { authorizedAxiosInstance } from './AuthorizedAxios';
import { AxiosResponse } from 'axios';
import Group from '../../models/Group';

//TODO Should have type, and maybe not return AxiosResponse
export const CreateGroup = async (groupName: string, parentId: number): Promise<AxiosResponse<unknown>> => {
  const group: PostGroup = {
    name: groupName,
    parentId: parentId,
  };

  const response = await (await authorizedAxiosInstance()).post('/api/groups', group);
  return response;
};

//TODO Should have type, and maybe not return AxiosResponse
export const UpdateGroup = async (
  groupId: number,
  groupName: string,
  parentId: number,
): Promise<AxiosResponse<unknown>> => {
  const group: PutGroup = {
    name: groupName,
    parentId: parentId,
  };

  const response = await (await authorizedAxiosInstance()).put(`/api/groups/${groupId}`, group);
  return response;
};

export const DeleteGroup = async (groupId: number): Promise<void> => {
  await (await authorizedAxiosInstance()).delete(`/api/groups/${groupId}`);
};

export const GetGroups = async (): Promise<Group> => {
  const axios = await authorizedAxiosInstance();
  const response = await axios.get('/api/overview/structured/group');
  const groupDTO: GroupStructureItem = response.data;
  return convertToGroup(groupDTO);
};

export const GetGroupsWithSelf = async (): Promise<Group> => {
    const axios = await authorizedAxiosInstance();
    const response = await axios.get('/api/overview/structured/groupwithself');
    const groupDTO: GroupStructureItem = response.data;
    return convertToGroup(groupDTO);
};