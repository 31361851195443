export enum FilterValue {
  EMPTY,
  ALMOST_FULL,
  FULL,
  ERRORS,
  SERVICE_DUE,
  OFFLINE,
  OK,
  MAP_VIEW,
  SHOW_ALL,
}
