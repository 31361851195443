import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../../shared/button/Button';
import DropDown from '../../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../../shared/dropDown/dropDownItem/DropDownItem';
import DropDownChildren from '../../../../../shared/dropDown/dropDownChildren/DropDownChildren';
import SlideInModal from '../../../../../shared/slideInModal/SlideInModal';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import './patchNotificationUserAdd.css';
import { ReactComponent as CancelSvg } from '../../../../../assets/common/iconExitGrey.svg';
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import ErrorBox from '../../../../../shared/errorBox/ErrorBox';
import Loader from '../../../../../shared/loader/Loader';
import { EnumModel } from '../../../../../models/EnumModel';
import { nameOfEnum } from '../../../../../utils/NameOfEnum';
import UserType from '../../../../../models/UserType';
import * as UserService from '../../../../../data/services/UserService';
import SimpleDevice from '../../../../../models/SimpleDevice';

export interface PatchNotificationUserAddProps {
    open: boolean;
    handleClose: () => void;
    notificationGroupToEdit?: SimpleNotificationGroup;
    onNotificationUsersUpdate: (notificationGroup: SimpleNotificationGroup) => void;
    deviceToEdit?: SimpleDevice;
}

const PatchNotificationUserAdd: React.FC<PatchNotificationUserAddProps> = ({
    open,
    handleClose,
    notificationGroupToEdit,
    onNotificationUsersUpdate,
    deviceToEdit
}) => {
    const [groupName, setGroupName] = useState('');
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phonenumber, setPhoneNumber] = useState('');
    const [errorOccured, setErrorOccured] = useState(false);
    const { t }: UseTranslationResponse = useTranslation();
    const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const roles = useRef<EnumModel[]>([
        { value: UserType.NotificationUser, name: nameOfEnum(UserType, UserType.NotificationUser) },
        { value: UserType.RegularUser, name: nameOfEnum(UserType, UserType.RegularUser) },
        { value: UserType.SuperUser, name: nameOfEnum(UserType, UserType.SuperUser) },
        { value: UserType.Admin, name: nameOfEnum(UserType, UserType.Admin) }
    ]);
    const [role, setRole] = useState<EnumModel>();
    const [loadSpinner, setLoadSpinner] = useState(false);

    const isValid = () => {
        let emailRegexp = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");

        return name.length > 0 && email.length > 0 && emailRegexp.test(email) && role;
    }

    const resetInputs = () => {
        setName('');
        setEmail('');
        setPhoneNumber('');
        setRole(undefined);
    };

    const add = () => {
        if (notificationGroupToEdit && isValid() && role) {
            setLoadSpinner(true);
            UserService.InviteNewUser(email, name, role.value, notificationGroupToEdit.groupId, phonenumber, false)
                .then((r) => {
                    resetInputs();
                    
                    if (notificationGroupToEdit && !notificationGroupToEdit.users) {
                        notificationGroupToEdit.users = [];
                    }

                    notificationGroupToEdit.users?.push({
                        Email: email,
                        Name: name,
                        Id: r,
                        GroupName: "",
                        Selected: false
                    });

                    onNotificationUsersUpdate(notificationGroupToEdit);
                })
                .catch(() => {
                    setErrorOccured(true);
                })
                .finally(() => {
                    setLoadSpinner(false);
                });
        }
    }

    

    return (
        <>
            <SlideInModal width={700} open={open} handleClose={() => handleClose()}>
                <div className="patch-notificationuseradd__header">
                    <div className="patch-notificationuseradd__header-items">
                        <div
                            className={`patch-notificationuseradd__cancel patch-notificationuseradd__cancel-edit`}
                            onClick={() => handleClose()}
                        >
                            <span>{t('COMMON.CANCEL')}</span>
                            <CancelSvg className="patch-notificationuseradd__cancel-icon" />
                        </div>
                        <Button
                            className={`patch-notificationuseradd__submit-button patch-notificationuseradd__save`}
                            onClick={() => add()}
                            disabled={!isValid()}
                        >
                            {t('PATCH_NOTIFICATION.ADD')}
                        </Button>
                    </div>
                </div>
                <div className="patch-notificationuseradd__body">
                    <div className="patch-notificationuseradd__body-title">
                        Add notification user
                    </div>

                    <div className="patch-notificationuseradd__content">

                        <div className="patch-notificationuseradd__body-form">
                            <form id="patch-user__body-form">
                                <div>
                                    <input
                                        type="text"
                                        className={name === '' ? '' : 'patch-user__body-form-input-text'}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required={true}
                                    />
                                    <label className="patch-user__body-form-input-label">{t('PATCH_USER.NAME')}</label>
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        className={email === '' ? '' : 'patch-user__body-form-input-text'}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required={true}
                                    />
                                    <label className="patch-user__body-form-input-label">{t('PATCH_USER.EMAIL')}</label>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        className={phonenumber === '' ? '' : 'patch-user__body-form-input-text'}
                                        value={phonenumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required={false}
                                    />
                                    <label className="patch-user__body-form-input-label">{t('PATCH_USER.PHONE')}</label>
                                </div>
                                <div className="patch-user__body-form-select-box">
                                    <DropDown
                                        borderless={true}
                                        selected={role}
                                        label="Role"
                                        selectedChange={setRole}
                                        header={<DropDownHeader>{role ? role.name : t('PATCH_USER.PLEASE_SELECT')}</DropDownHeader>}
                                    >
                                        {roles?.current.map((role) => (
                                            <DropDownItem key={role.value} item={role}>
                                                {role.name}
                                            </DropDownItem>
                                        ))}
                                    </DropDown>
                                </div>
                            </form>
                        </div>
                    </div>

                    {errorOccured && (
                        <ErrorBox className="patch-group_error-box" paragraphs={errorParagraphs} header={t('COMMON.ERROR')} />
                    )}
                </div>
                {loading && <Loader mode="fill-parent" />}
            </SlideInModal>
        </>
    );
};

export default PatchNotificationUserAdd