import React, { useEffect, useState } from 'react';
import Button from '../../../../shared/button/Button';
import SlideInModal from '../../../../shared/slideInModal/SlideInModal';
import './PatchGroup.css';
import { ReactComponent as CancelSvg } from '../../../../assets/common/iconExitGrey.svg';
import { ReactComponent as DeleteSvg } from '../../../../assets/settings/iconDeletefile.svg';
import SimpleGroup from '../../../../models/SimpleGroup';
import * as GroupService from '../../../../data/services/GroupService';
import DropDown from '../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownChildren from '../../../../shared/dropDown/dropDownChildren/DropDownChildren';
import DropDownItem from '../../../../shared/dropDown/dropDownItem/DropDownItem';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import Group from '../../../../models/Group';
import Loader from '../../../../shared/loader/Loader';
import ErrorBox from '../../../../shared/errorBox/ErrorBox';

export interface PatchGroupProps {
  open: boolean;
  handleClose: () => void;
  mode?: 'create' | 'edit';
  onDelete?: () => void;
  onSubmit?: () => void;
  groupToEdit?: Group;
  groupsExistingParent?: Group;
  groupList: SimpleGroup[];
}

const PatchGroup: React.FC<PatchGroupProps> = ({
  open,
  handleClose,
  mode = 'create',
  onDelete,
  onSubmit,
  groupToEdit,
  groupsExistingParent,
  groupList,
}) => {
  const [groupName, setGroupName] = useState('');
  const [parentGroup, setParentGroup] = useState<SimpleGroup>();
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { t }: UseTranslationResponse = useTranslation();

  useEffect(() => {
    if (open) {
      if (mode === 'edit') {
        if (groupToEdit) {
          fillInputs(groupToEdit);
        }
        setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);
      } else {
        setErrorParagraphs([t('PATCH_GROUP.ERROR_ADD')]);
      }
    } else {
      setErrorOccured(false);
    }
  }, [open, groupToEdit, mode, t]);

  useEffect(() => {
    if (groupList.length > 0 && mode === 'edit' && groupToEdit) {
      setParentGroup(groupsExistingParent);
    }
  }, [groupList, open, mode, groupToEdit, groupsExistingParent]);

  useEffect(() => {
    if (!open) {
      resetInputs();
    }
  }, [open]);

  const fillInputs = (group: Group) => {
    setGroupName(group.name);
  };

  const resetInputs = () => {
    setGroupName('');
    setParentGroup(undefined);
  };

  const isValid = () => {
    return parentGroup && groupName.length > 0;
  };

  const updateGroup = () => {
    if (isValid() && parentGroup) {
      if (groupToEdit) {
        setLoading(true);
        GroupService.UpdateGroup(groupToEdit.id, groupName, parentGroup.id)
          .then(() => {
            if (onSubmit) {
              onSubmit();
            }
            handleClose();
          })
          .catch(() => {
            setErrorOccured(true);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        //No translations because its an error bering thrown
        throw new Error('When updating group it should have a group to edit');
      }
    }
  };

  const createGroup = () => {
    if (isValid() && parentGroup) {
      setLoading(true);
      GroupService.CreateGroup(groupName, parentGroup.id)
        .then(() => {
          if (onSubmit) {
            onSubmit();
          }
          handleClose();
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const fillDropdown = (data: SimpleGroup) => {
    return (
      <DropDownChildren key={data.id + '-wrap'}>
        <DropDownItem key={data.id} item={data}>
          {data.name}
        </DropDownItem>
        {data.children.length > 0 ? data.children.map((subData: SimpleGroup) => fillDropdown(subData)) : null}
      </DropDownChildren>
    );
  };

  return (
    <SlideInModal width={540} open={open} handleClose={() => handleClose()}>
      <div className="patch-group__header">
        <div className="patch-group__header-items">
          <div
            className="patch-group__delete"
            style={{ visibility: mode === 'create' ? 'hidden' : 'visible' }}
            onClick={() => onDelete && onDelete()}
          >
            <span>{t('COMMON.DELETE')}</span>
            <DeleteSvg className="patch-group__delete-icon" />
          </div>
          <div
            className={`patch-group__cancel ${
              mode === 'create' ? 'patch-group__cancel-create' : 'patch-group__cancel-edit'
            }`}
            onClick={() => handleClose()}
          >
            <span>{t('COMMON.CANCEL')}</span>
            <CancelSvg className="patch-group__cancel-icon" />
          </div>
          <Button
            className={`patch-group__submit-button ${mode === 'create' ? 'patch-group__create' : 'patch-group__save'}`}
            onClick={() => (mode === 'create' ? createGroup() : updateGroup())}
            disabled={!isValid()}
          >
            {mode === 'create' ? t('PATCH_GROUP.CREATE') : t('PATCH_GROUP.SAVE')}
          </Button>
        </div>
      </div>
      <div className="patch-group__body">
        <div className="patch-group__body-title">
          {mode === 'create' ? t('PATCH_GROUP.CREATE_GROUP') : t('PATCH_GROUP.EDIT_GROUP')}
        </div>
        <div className="patch-group__body-form">
          <form id="patch-group__form">
            <div>
              <input
                type="text"
                className={groupName === '' ? '' : 'patch-group__body-form-input-text'}
                value={groupName}
                onChange={(event) => setGroupName(event.target.value)}
                required
              />
              <label className="patch-group__body-form-input-label">{t('PATCH_GROUP.GROUP_NAME')}</label>
            </div>
            <div>
              <DropDown
                selected={parentGroup}
                borderless={true}
                label={t('PATCH_GROUP.NEST_UNDER')}
                selectedChange={(data: SimpleGroup) => {
                  setParentGroup(data);
                }}
                header={
                  <DropDownHeader>{parentGroup ? parentGroup.name : t('PATCH_GROUP.SELECT_GROUP')}</DropDownHeader>
                }
              >
                {groupList.map((data: SimpleGroup) => fillDropdown(data))}
              </DropDown>
            </div>
          </form>
        </div>
        {errorOccured && (
          <ErrorBox className="patch-group_error-box" paragraphs={errorParagraphs} header={t('COMMON.ERROR')} />
        )}
      </div>
      {loading && <Loader mode="fill-parent" />}
    </SlideInModal>
  );
};

export default PatchGroup;
