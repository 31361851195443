import React from 'react';
import ErrorBox from '../errorBox/ErrorBox';
import './ConfirmDialog.css';

export interface dialogprops {
  paragraphs: string[];
  primaryText: string;
  primaryClick: () => void;
  secondaryText: string;
  secondaryClick: () => void;
  error?: boolean;
  errorParagraphs?: string[];
  errorHeader?: string;
}
export const ConfirmDialog: React.FC<dialogprops> = ({ error = false, errorParagraphs = [], ...props }) => {
  return (
    <div className="confirmbox">
      <div className="confirmbox_inner">
        {props.paragraphs.map((value, index) => {
          return (
            <p className="confirmbox-paragraph" key={index}>
              {value}
            </p>
          );
        })}
        {error && <ErrorBox className="confirmbox_error-box" paragraphs={errorParagraphs} header={props.errorHeader} />}
        <button className="confirmbox-primary-button" onClick={props.primaryClick}>
          {props.primaryText}
        </button>
        <button className="confirmbox-secondary-button" onClick={props.secondaryClick}>
          {props.secondaryText}
        </button>
      </div>
    </div>
  );
};

export default ConfirmDialog;
