import React, { useEffect, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ReactComponent as DeleteSvg } from '../../../../../assets/settings/iconDeletefile.svg';
import SimpleUser from '../../../../../models/SimpleUser';
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import DropDown from '../../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownItem from '../../../../../shared/dropDown/dropDownItem/DropDownItem';
import { ReactComponent as EditSvg } from '../../../../../assets/settings/iconEdit.svg';
import NotificationGroupInfo from './NotificationGroupInfo';
import ConfirmDialog from '../../../../../shared/confirmDialog/ConfirmDialog';
import { ReactComponent as ToggleOffSvg } from '../../../../../assets/addUser/toggleOff.svg';
import { ReactComponent as ToggleOnSvg } from '../../../../../assets/addUser/toggleOn.svg';

import './NotificationGroupUserEdit.css';

export interface NotificationGroupUserEditProps {
    notificationGroup: SimpleNotificationGroup;
    userAvailable: SimpleUser[];
    onAddUser: (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => void;
    onRemoveUser: (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => void;
    onEditGroup: (notificationGroup: SimpleNotificationGroup) => void;
    onToggleChildGroup: (notificationGroup: SimpleNotificationGroup, user: SimpleUser) => void;
    onAddNotificationUser: (notificationGroup: SimpleNotificationGroup) => void;
}

const NotificationGroupUserEdit: React.FC<NotificationGroupUserEditProps> = ({ notificationGroup, userAvailable, onAddUser, onRemoveUser, onEditGroup, onToggleChildGroup, onAddNotificationUser }) => {
    const { t }: UseTranslationResponse = useTranslation();
    const [infoOpen, setInfoOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [groupServiceOpened, setGroupServiceToggle] = useState(false);
    const [availableUsers, setAvailableUsers] = useState<SimpleUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<SimpleUser>();
    const [selectedUsers, setSelectedUsers] = useState<SimpleUser[]>();
    const openStyle: React.CSSProperties = { display: "block" };
    const closeStyle: React.CSSProperties = { display: "none" };
    const [errorOccured, setErrorOccured] = useState(false);
    const [indexToRemove, setIndexToRemove] = useState(-1);

    const openGroupInfo = () => {
        setGroupServiceToggle(!groupServiceOpened);
        setInfoOpen(!infoOpen);
    }

    useEffect(() => {
        setOpenDeleteModal(false);
        setSelectedUsers(notificationGroup.users);
    }, [notificationGroup]);

    useEffect(() => {
        let _userAvailable = [...userAvailable];

        if (notificationGroup.users && _userAvailable) {
            notificationGroup.users.forEach((u) => {
                let search = _userAvailable.find(a => a.Id === u.Id);
                if (search) {
                    let index = _userAvailable.indexOf(search);
                    if (index > -1) {
                        _userAvailable.splice(index, 1);
                    }
                }
            });
        }
        setAvailableUsers(_userAvailable);
    }, [userAvailable]);

    const removeUser = (index: number) => {
        setIndexToRemove(index);
        setOpenDeleteModal(true);
    }

    const deleteCurrentUser = () => {
        if (indexToRemove > -1 && selectedUsers) {
            availableUsers.push(selectedUsers[indexToRemove]);
            onRemoveUser(notificationGroup, selectedUsers[indexToRemove]);

            setOpenDeleteModal(false);
            setAvailableUsers(availableUsers);
        }
    }

    const addUser = () => {
        const user = selectedUser as SimpleUser;

        if (availableUsers) {
            let search = availableUsers.find(c => c.Id === user.Id);
            if (search) {
                let index = availableUsers.indexOf(search);
                if (index > -1) {
                    availableUsers.splice(index, 1);
                }
            }
        }

        setAvailableUsers(availableUsers);
        onAddUser(notificationGroup, user);
        setSelectedUser(undefined);
    }

    const editGroup = (notificationGroup: SimpleNotificationGroup) => {
        onEditGroup(notificationGroup);
    }

    const toggleChildGroup = (index: number, selected: boolean) => {
        if (selectedUsers) {
            selectedUsers[index].Selected = selected;
            setSelectedUsers([...selectedUsers]);

            onToggleChildGroup(notificationGroup, selectedUsers[index]);
        }
    }

    const onSelectedUser = (user: SimpleUser) => {
        if (user) {
            setSelectedUser(user);
        } else {
            onAddNotificationUser(notificationGroup);
        }
    }

    return (
        <>
            <div className="patch-group__notification-group-card">
                <div className="patch-group__notification-group-card-header">
                    <div className="patch-group__notification-group-info">
                        <a
                            href="#"
                            onClick={() => {
                                openGroupInfo();
                            }}>
                            <div className={`${groupServiceOpened ? 'patch-group__notification-group-info-icon-opened' : 'patch-group__notification-group-info-icon-closed'}`}></div>
                        </a>
                    </div>
                    <div className="patch-group__notification-group-card-headline">
                        <a
                            href="#"
                            onClick={() => {
                                openGroupInfo();
                            }}>
                            {notificationGroup.name}
                        </a>
                    </div>
                    <div className="patch-group__notification-group-card-editable">
                        <a
                            href="#"
                            onClick={() => {
                                editGroup(notificationGroup);
                            }}>
                            <EditSvg className="notification-group-card__context-icon" />
                        </a>
                    </div>
                </div>
                <div className="patch-group__notification-group-card-groupinfo" style={infoOpen ? openStyle : closeStyle}>
                    <NotificationGroupInfo notificationGroupDeviceCodes={notificationGroup.notificationGroupDeviceCodes} />
                </div>
                <div className="patch-group__notification-group-card-body">
                    {selectedUsers && selectedUsers.length > 0 && (
                        <table className="patch-group__notification-group-user-table">
                            <thead>
                                <tr>
                                    <th>{t('COMMON.NAME')}</th>
                                    <th>{t('COMMON.EMAIL')}</th>

                                    {!notificationGroup.deviceId && (
                                        <th style={{ textAlign: "center" }}>{t('COMMON.SUBGROUP')}</th>
                                    )}
                                    <th style={{ textAlign: "center" }}>{t('COMMON.REMOVE')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedUsers.map((u, i) => {
                                    return (
                                        <tr key={`user${u.Id}`}>
                                            <td>{u.Name}</td>
                                            <td>{u.Email}</td>
                                            {!notificationGroup.deviceId && (
                                                <td style={{ textAlign: "center", position: "relative" }}>
                                                    {u.Selected ? (
                                                        <ToggleOnSvg onClick={() => toggleChildGroup(i, false)} className="patch-group__notification-group-user-childgroup-toggle" />
                                                    ) : (
                                                        <ToggleOffSvg onClick={() => toggleChildGroup(i, true)} className="patch-group__notification-group-user-childgroup-toggle" />
                                                    )}
                                                </td>
                                            )}
                                            <td style={{ textAlign: "center" }}>
                                                <DeleteSvg
                                                    title={t('CONTEXT_MENU.TITLE')}
                                                    className="patch-group__notification-group-user-delete-btn"
                                                    onClick={() => {
                                                        removeUser(i);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    <div style={{ display: availableUsers.length > 0 ? "table" : "none", width: "100%" }}>
                        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                            <DropDown
                                selected={selectedUser}
                                borderless={true}
                                label={t('PATCH_NOTIFICATION.ADD_USER_GROUP')}
                                selectedChange={(data: SimpleUser) => {
                                    onSelectedUser(data);
                                }}
                                header={<DropDownHeader>{selectedUser ? selectedUser.Name : t('PATCH_NOTIFICATION.SELECT_USER')}</DropDownHeader>}>

                                <DropDownItem key="0" item="">
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div><EditSvg /></div>
                                        <div style={{ marginLeft: "5px" }}>Add new user</div>
                                    </div>
                                </DropDownItem>

                                {availableUsers.map((data: SimpleUser) => (
                                    <DropDownItem key={data.Id} item={data}>
                                        {data.Name}
                                        <div style={{ fontWeight: "bold", fontSize: "11px", paddingTop: "5px" }}>{data.GroupName}</div>
                                    </DropDownItem>
                                ))}
                            </DropDown>
                        </div>
                        <div style={{ display: "table-cell", verticalAlign: "middle", width: "110px" }}>
                            <button className="patch-notification__save patch-notification-group__submit-button button_default" disabled={selectedUser == undefined} onClick={() => {
                                addUser();
                            }}>{t('PATCH_NOTIFICATION.ADD')}</button>
                        </div>
                    </div>
                </div>
            </div>
            {openDeleteModal && (
                <ConfirmDialog
                    paragraphs={[
                        t('DELETE_MODAL.FIRST_PARAGRAPH'),
                        t('DELETE_MODAL.SECOND_PARAGRAPH_NOTIFICATION_GROUP_USER')
                    ]}
                    primaryText={t('DELETE_MODAL.CONFIRM_REMOVE_USER')}
                    secondaryText={t('COMMON.CANCEL')}
                    primaryClick={() => {
                        deleteCurrentUser();
                    }}
                    secondaryClick={() => setOpenDeleteModal(false)}
                    error={errorOccured}
                    errorHeader={t('COMMON.ERROR')}
                    errorParagraphs={[t('DELETE_MODAL.ERROR_NOTIFICATION_GROUP')]}
                ></ConfirmDialog>
            )}
        </>
    );
};

export default NotificationGroupUserEdit;