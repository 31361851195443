import React, { useEffect } from 'react';
import './Loader.css';

interface LoaderProps {
  className?: string;
  style?: React.CSSProperties;
  mode?: 'inline' | 'fill-parent' | 'fullscreen' | 'none';
}

// Loader from  https://loading.io/css/
const Loader: React.FC<LoaderProps> = ({ className, style, mode }) => {
  let ldsSpinnerContainerDomRef: HTMLDivElement | null = null;

  useEffect(() => {
    let isMoved = false;
    if (mode === 'fullscreen') {
      if (ldsSpinnerContainerDomRef) {
        document.body.appendChild(ldsSpinnerContainerDomRef);
        isMoved = true;
      }
    }

    return () => {
      if (isMoved && ldsSpinnerContainerDomRef) {
        document.body.removeChild(ldsSpinnerContainerDomRef);
      }
    };
  }, [ldsSpinnerContainerDomRef, mode]);
  return (
    <div
      className={`lds-spinner-container lds-spinner-container__mode-${mode ? mode : 'none'}`}
      ref={(c) => (ldsSpinnerContainerDomRef = c as HTMLDivElement)}
    >
      <div style={style} className={`lds-spinner ${className}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
