import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconExit } from '../../../../assets/common/iconExitGrey.svg';
import { KFormatter } from '../../../../utils/KFormatter';
import './FilterDevices.css';

export const FilterDevices: React.FC<{ showNarrow: boolean }> = ({ children, showNarrow }) => {
  return (
    <div className="FilterDevices" style={{ maxWidth: showNarrow ? '624px' : '832px' }}>
      {children}
    </div>
  );
};

export enum FilterDeviceItemType {
  ERROR,
  WARNING,
  NORMAL,
  SHOWALL,
}

type FilterDeviceItemProps = {
  amount: number;
  text: string;
  type: FilterDeviceItemType;
  onClick: () => void;
  isActive: boolean;
  iconOverride?: ReactNode | null;
};

export const FilterDeviceItem: React.FC<FilterDeviceItemProps> = ({
  amount = 1,
  text,
  type = FilterDeviceItemType.NORMAL,
  onClick,
  isActive,
  iconOverride = null,
}) => {
  const { t } = useTranslation();
  const getBackgroundColor = () => {
    switch (type) {
      case FilterDeviceItemType.ERROR:
        return 'var(--error)';

      case FilterDeviceItemType.WARNING:
        return 'var(--warning)';

      default:
        return 'var(--gray)';
    }
  };

  return (
    <div
      className="FilterDeviceItem"
      onClick={() => onClick()}
      style={{ backgroundColor: isActive ? 'var(--lightgray)' : '' }}
    >
      {iconOverride && iconOverride}
      {!iconOverride && (
        <div
          className="FilterDeviceItem__indicator"
          style={{ backgroundColor: getBackgroundColor(), color: type <= 1 ? 'var(--white)' : 'var(--accentgray)' }}
        >
          {KFormatter(amount)}
        </div>
      )}
      <div className="FilterDeviceItem__text">{text ? text : t('DEVICE_FILTER.UNKNOWN')}</div>
      <div className="FilterDeviceItem__exit">{isActive && <IconExit className="FilterDeviceItem__exit_icon" />}</div>
    </div>
  );
};
