export const KFormatter = (num: number): string => {
  if (Math.abs(num) > 999) {
    let amount = `${(Math.sign(num) * Math.abs(num)) / 1000}`;
    if (amount.length > 3) {
      amount = amount.slice(0, 3);
    }
    return `${amount}k`;
  } else {
    return `${Math.sign(num) * Math.abs(num)}`;
  }
};
