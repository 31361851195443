import { LatLngBounds, Layer, MarkerClusterGroup as leafletMarkerClusterGroup } from 'leaflet';
import { throttle } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useLeaflet } from 'react-leaflet';
import MarkerClusterGroup, { MarkerClusterGroupProps } from 'react-leaflet-markercluster';
import Device, { Status } from '../../../../models/Device';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import { CreateClusterCustomIcon } from '../../../../utils/CreateClusterCustomIcon';
import { MapMarker } from '../MapMarker/MapMarker';

import './MapClusterView.css';

type MapClusterViewProps = {
  devices: Device[];
  selectedLocation: string;
  setSelectedDevice: (device: Device) => void;
  setBounds: React.Dispatch<React.SetStateAction<LatLngBounds | undefined>>;
  stateValues: DeviceStateValues;
};

export const MapClusterView: React.FC<MapClusterViewProps> = ({
  devices,
  selectedLocation,
  setSelectedDevice,
  setBounds,
  stateValues,
}) => {
  const throttledSetBounds = useRef(
    throttle(() => {
      setBounds(mapContext?.map?.getBounds());
    }, 2000),
  ).current;
  const mapContext = useLeaflet();
  const markerCluster = useRef<MarkerClusterGroup<MarkerClusterGroupProps> | null>(null);

  mapContext.map?.on('zoomend', throttledSetBounds);

  mapContext.map?.on('moveend', throttledSetBounds);

  const zoomToMarker = useCallback(
    (marker: Layer) => {
      const markerClusterLeaflet = markerCluster.current?.leafletElement as leafletMarkerClusterGroup;
      markerClusterLeaflet.zoomToShowLayer(marker);
    },
    [markerCluster],
  );

  const mapMarkers = useMemo(() => {
    return devices.map((device: Device) => {
      return (
        <MapMarker
          key={device.id}
          id={device.machineId}
          type={device.type}
          client={device?.customer?.name}
          position={{ lat: device?.location?.latitude || 0, lng: device?.location?.longitude || 0 }}
          hasPopup={device.id === selectedLocation}
          status={device.deviceStatus || Status.ok}
          fillLevel={device.fillLevel}
          setSelectedDevice={setSelectedDevice}
          device={device}
          zoomToMarker={zoomToMarker}
          stateValues={stateValues}
        />
      );
    });
  }, [devices, setSelectedDevice, selectedLocation, zoomToMarker, stateValues]);

  return (
    <MarkerClusterGroup
      iconCreateFunction={(cluster) => CreateClusterCustomIcon(cluster)}
      showCoverageOnHover={false}
      ref={markerCluster}
      chunkedLoading={true}
    >
      {mapMarkers}
    </MarkerClusterGroup>
  );
};
