import { GetAttachment as RespondAttachment } from '../models/attachments/GetAttachment';
import { GetAttachments as RespondAttachmentList } from '../models/attachments/GetAttachments';
import { PostAttachment as AddedAttachment } from '../models/attachments/PostAtttachment';
import { authorizedAxiosInstance } from './AuthorizedAxios';
import { saveAs } from 'file-saver';
import { AttachmentFile } from '../../models/AttachmentFile';

export const PostAttachment = async (file: File, deviceId: string): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  const instance = await authorizedAxiosInstance();
  instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

  const response = await instance.post<AddedAttachment>(`api/devices/${deviceId}/attachments`, formData);
  return response.data.attachmentId;
};

export const DeleteAttachment = async (deviceId: string, attachmentId: string): Promise<void> => {
  await (await authorizedAxiosInstance()).delete(`api/devices/${deviceId}/attachments/${attachmentId}`);
};

export const GetAttachment = async (deviceId: string, attachment: AttachmentFile): Promise<void> => {
  const response = await (await authorizedAxiosInstance()).get(
    `api/devices/${deviceId}/attachments/${attachment.attachmentId}`,
    {
      responseType: 'blob',
    },
  );

  const blob = new Blob([response.data]);

  saveAs(blob, attachment.filename);
};

export const GetAttachments = async (deviceId: string): Promise<AttachmentFile[]> => {
  const response = await (await authorizedAxiosInstance()).get<RespondAttachmentList>(
    `api/devices/${deviceId}/attachments`,
  );

  const attachmentDataList: RespondAttachment[] = response.data.attachments;

  return attachmentDataList.map((x) => {
    return {
      attachmentId: x.attachmentId,
      filename: x.filename,
      uploadedBy: x.uploadedBy,
      lastModified: x.lastModified,
      canUserDelete: x.canCallingUserDelete,
    } as AttachmentFile;
  });
};
