import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconExitDanitechGrey } from '../../assets/common/iconExitDanitechGrey.svg';
import { DataType, IPostReport, PeriodType, ResultAs } from "../../data/models/PostReport";
import { IReportDataset, IReportDatasetItem } from "../../data/models/ReportDataset";
import * as ReportService from "../../data/services/ReportService";
import Loader from '../../shared/loader/Loader';
import Topbar from '../../shared/topbar/Topbar';
import { ReportFilter } from "./components/ReportFilter/ReportFilter";
import { GetDevices } from '../../data/services/DeviceService';
import Device from '../../models/Device';
import moment from "moment";
import './Reports.css';

const FRONTPAGE = '/';

export const options = {
    responsive: true,
    scales: {
        yAxes: [
            {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-axis-1',
                gridLines: {
                    display: false
                },
                labels: {
                    show: true
                },
                ticks: {
                    beginAtZero: true,
                    suggestedMax: 100,
                    suggestedMin: 0
                },
                scaleLabel: {
                    display: true,
                    labelString: ""
                }
            }
        ]
    }
};

export const labels = [""];
export const data = {
    labels,
    datasets: [
        {
            label: 'Fill Level',
            data: [0],
            borderColor: '#c75b18',
            backgroundColor: '#e1671b',
            yAxisID: 'y-axis-1',
        }
    ],
};

export const Reports: React.FC = () => {
    const history = useHistory();
    const [pageInit, setPageInit] = useState(true);
    const [hasReport, setHasReport] = useState(false);
    const [loadingReport, setLoadingReport] = useState(false);
    const { t } = useTranslation();
    const [periodStart, setPeriodeStart] = useState(new Date());
    const [periodEnd, setPeriodeEnd] = useState(new Date());
    const [deviceList, setDeviceList] = useState<Device[]>([]);
    const [loadingDeviceList, setLoadingDeviceList] = useState(false);
    const [periodType, setPeriodeType] = useState<PeriodType>(PeriodType.WEEK);
    const [dataType, setDataType] = useState<DataType>(DataType.FillLevelIndex);
    const [reportFilterUpdate, setReportFilterUpdate] = useState<IPostReport>({
        dataType: DataType.BaleEjectCounter,
        devices: [],
        periodEnd: "",
        periodStart: "",
        periodType: PeriodType.MONTH,
        resultAs: ResultAs.Chart
    });
    const [showChart, setShowChart] = useState(true);
    const [showGrid, setShowGrid] = useState(false);
    const [gridValueLabel, setGridValueLabel] = useState("LABEL_PERCENTAGE");
    const [exportData, setExportData] = useState<string[][] | undefined>(undefined);
    const [deviceListSelected, setDeviceListSelected] = useState<Device[]>([]);

    useEffect(() => {
        let _periodEnd : Date = new Date();
        let _periodStart : Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
        let _periodType: PeriodType = PeriodType.WEEK;
        let _dataType: DataType = DataType.FillLevelIndex;

        if (localStorage.getItem("reportPeriodEnd") && localStorage.getItem("reportPeriodStart")) {
            _periodEnd = moment(String(localStorage.getItem("reportPeriodEnd"))).toDate();
            _periodStart = moment(String(localStorage.getItem("reportPeriodStart"))).toDate();
        }

        if (localStorage.getItem("reportPeriodType")) {
            _periodType = JSON.parse(String(localStorage.getItem("reportPeriodType"))) as PeriodType;
        }

        if (localStorage.getItem("reportDataType")) {
            _dataType = JSON.parse(String(localStorage.getItem("reportDataType"))) as DataType;
        }

        setPeriodeEnd(_periodEnd);
        setPeriodeStart(_periodStart);
        setPeriodeType(_periodType);
        setDataType(_dataType);

        let isMounted = true;
        setLoadingDeviceList(true);

        GetDevices().then((d) => {
            if (isMounted) {
                if (d.devices) {
                    let deviceList: Device[] = [];

                    for (var i = 0; i < d.devices.length; i++) {
                        let device = d.devices[i];
                        device.selected = true;
                        deviceList.push(d.devices[i]);
                    }
                    setDeviceList([...deviceList]);
                    
                    if (localStorage.getItem("deviceSelected")) {
                        let _deviceSelectedList: Device[] = JSON.parse(String(localStorage.getItem("deviceSelected"))) as Device[];
                        setDeviceListSelected([..._deviceSelectedList]);
                    } else {
                        setDeviceListSelected([...deviceList]);
                    }

                    if (pageInit) {
                        setReportFilterUpdate({
                            devices: deviceList.map(c => c.id),
                            dataType: DataType.FillLevelIndex,
                            periodEnd: new Date().toISOString(),
                            periodStart: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
                            periodType: PeriodType.MONTH,
                            resultAs: ResultAs.Chart
                        });

                        setPageInit(false);
                    }
                }
            }
        })
            .finally(() => {
                setLoadingDeviceList(false);
            });

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        setLoadingReport(true);
        setHasReport(false);

        setExportData(undefined);
        
        ReportService.GetReport(reportFilterUpdate)
            .then((reportDataset) => {
                if (isMounted) {
                    if (reportDataset.data) {
                        if (reportFilterUpdate.resultAs === ResultAs.Chart) {
                            setShowChart(true);
                            setShowGrid(false);

                            if (reportDataset.data.datasets) {

                                let maxValue = Math.max(...reportDataset.data.datasets[0].data);

                                if (reportFilterUpdate.dataType === DataType.BaleEjectCounter) {
                                    options.scales.yAxes[0].scaleLabel.labelString = t("REPORT.LABEL_TOTAL");
                                    options.scales.yAxes[0].ticks.suggestedMin = 0;
                                    options.scales.yAxes[0].ticks.suggestedMax = maxValue + 10;
                                } else if (reportFilterUpdate.dataType === DataType.FullDetectCounter) {
                                    options.scales.yAxes[0].scaleLabel.labelString = t("REPORT.LABEL_TOTAL");
                                    options.scales.yAxes[0].ticks.suggestedMin = 0;
                                    options.scales.yAxes[0].ticks.suggestedMax = maxValue + 10;
                                } else if (reportFilterUpdate.dataType === DataType.FillLevelIndex) {
                                    options.scales.yAxes[0].scaleLabel.labelString = t("REPORT.LABEL_PERCENTAGE");
                                    options.scales.yAxes[0].ticks.suggestedMax = 100;
                                    options.scales.yAxes[0].ticks.suggestedMin = 0;
                                }

                                let labels: string[] = reportDataset.data.labels;

                                reportDataset.data.datasets[0].label = t(reportDataset.data.datasets[0].label);

                                if (reportFilterUpdate.periodType === PeriodType.MONTH) {
                                    labels = [];

                                    for (var i = 0; i < reportDataset.data.labels.length; i++) {
                                        labels.push(t(reportDataset.data.labels[i]));
                                    }
                                }

                                data.labels = labels;
                                data.datasets = reportDataset.data.datasets;

                                setHasReport(true);
                            }
                        } else if (reportFilterUpdate.resultAs === ResultAs.Grid) {
                            setShowChart(false);
                            setShowGrid(true);

                            if (reportFilterUpdate.dataType === DataType.BaleEjectCounter) {
                                setGridValueLabel(t("REPORT.LABEL_TOTAL"));
                            } else if (reportFilterUpdate.dataType === DataType.FullDetectCounter) {
                                setGridValueLabel(t("REPORT.LABEL_TOTAL"));
                            } else if (reportFilterUpdate.dataType === DataType.FillLevelIndex) {
                                setGridValueLabel(t("REPORT.LABEL_PERCENTAGE"));
                            }

                            if (reportDataset.data.datasets) {
                                let labels: string[] = [];
                                let values: number[] = [];
                                for (var j =  reportDataset.data.labels.length-1; j > -1; j--) {
                                    let labelTranslated: string = reportDataset.data.labels[j];

                                    if (reportFilterUpdate.periodType === PeriodType.MONTH) {
                                        labelTranslated = t(reportDataset.data.labels[j]);
                                    }

                                    labels.push(labelTranslated);
                                    values.push(reportDataset.data.datasets[0].data[j]);
                                }
                                
                                data.labels = labels;
                                reportDataset.data.datasets[0].data = values;

                                data.datasets = reportDataset.data.datasets;

                                setHasReport(true);
                            }
                        }

                        let dataset = buildCsv(reportDataset.data);
                        setExportData(dataset);
                    }
                }
            })
            .finally(() => {
                setLoadingReport(false);
            });

        return () => {
            isMounted = false;
        };
    }, [reportFilterUpdate]);

    const onUpdated = (postData: IPostReport) => {
        setReportFilterUpdate(postData);
        setDeviceListSelected([...deviceListSelected]);
    }

    const buildCsv = (dataset: IReportDataset) => {
        let csvData: string[][] = [];

        if (reportFilterUpdate.dataType === DataType.FillLevelIndex) {
            csvData.push(["Date", "Percentage"]);
        } else {
            csvData.push(["Date", "Total"]);
        }
        for (var i = dataset.labels.length - 1; i > -1; i--) {
            csvData.push([dataset.labels[i], dataset.datasets[0].data[i].toString()]);
        }

        return csvData;
    }
    
    return (
        <div className="Reports">
            <Topbar>
                <div className="home__view-type-buttons-container"></div>
                <div className="Manuals__close">
                    <button className="Manuals__close-button" onClick={() => history.push(FRONTPAGE)}>
                        <p>{t('COMMON.CLOSE')}</p>
                        <IconExitDanitechGrey />
                    </button>
                </div>
            </Topbar>
            <div className="Reports_container">
                <div className="Reports__body">
                    <div className="Reports__body-title">
                        <h1 className="Reports__body-title-text">Reports</h1>
                    </div>
                    {!loadingDeviceList ? (
                        <React.Fragment>
                            <ReportFilter startDate={periodStart} endDate={periodEnd} deviceList={deviceList} deviceListSelected={deviceListSelected} periodType={periodType} dataType={dataType} updated={onUpdated} exportData={exportData}></ReportFilter>
                            {loadingReport ? (
                                <Loader className="DeviceListView__loading" />
                            ) : (
                                <React.Fragment>
                                    {hasReport && showChart && (
                                        <div style={{ width: '70%', margin: '20px auto 0 auto' }}>
                                            <Bar data={data} options={options} />
                                        </div>
                                    )}
                                    {hasReport && showGrid && (
                                        <div>
                                            <table className="Reports__grid_table">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>{gridValueLabel}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.labels.map((d, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{d}</td>
                                                                    <td>{data.datasets[0].data[index]}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <Loader className="DeviceListView__loading" />
                    )}
                </div>
            </div>

        </div>

    );
};
