import React, { useEffect, useState } from 'react';
import Button from '../../../../../shared/button/Button';
import SlideInModal from '../../../../../shared/slideInModal/SlideInModal';
import NotificationGroupUserList from '../NotificationGroupUserList/NotificationGroupUserList';
import './patchNotificationDevice.css';
import { ReactComponent as CancelSvg } from '../../../../../assets/common/iconExitGrey.svg';
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import SimpleDevice from '../../../../../models/SimpleDevice';
import * as DeviceService from '../../../../../data/services/DeviceService';
import DropDown from '../../../../../shared/dropDown/DropDown';
import DropDownHeader from '../../../../../shared/dropDown/dropDownHeader/DropDownHeader';
import DropDownChildren from '../../../../../shared/dropDown/dropDownChildren/DropDownChildren';
import DropDownItem from '../../../../../shared/dropDown/dropDownItem/DropDownItem';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import Group from '../../../../../models/Group';
import Loader from '../../../../../shared/loader/Loader';
import ErrorBox from '../../../../../shared/errorBox/ErrorBox';
import * as NotificationGroupService from '../../../../../data/services/NotificationGroupService';

export interface PatchNotificationDeviceProps {
    open: boolean;
    handleClose: () => void;
    onSubmit?: () => void;
    groupToEdit: Group;
    onNotificationDeviceEdit: (notificationGroup?: SimpleNotificationGroup) => void;
    notificationDeviceToUpdate?: SimpleNotificationGroup;
    notificationDeviceToDelete?: SimpleNotificationGroup;
    onAddNotificationUser: (notificationDevice: SimpleNotificationGroup) => void;
}

const PatchNotificationDevice: React.FC<PatchNotificationDeviceProps> = ({
    open,
    handleClose,
    onSubmit,
    groupToEdit,
    onNotificationDeviceEdit,
    notificationDeviceToUpdate,
    notificationDeviceToDelete,
    onAddNotificationUser
}) => {
    const [groupName, setGroupName] = useState('');
    const [errorOccured, setErrorOccured] = useState(false);
    const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const { t }: UseTranslationResponse = useTranslation();
    const [selectedDevice, setSelectedDevice] = useState<SimpleDevice>();
    const [deviceList, setDeviceList] = useState<SimpleDevice[]>([]);
    const [notificationGroupList, setNotificationGroupList] = useState<SimpleNotificationGroup[]>([]);
    const fetchNotificationDevice = async () => {
    };

    useEffect(() => {
        if (open) {
            setNotificationGroupList([]);
            setLoading(true); 

            try {
                DeviceService.GetDevicesByGroup(groupToEdit.id).then((e) => {
                    let _devices: SimpleDevice[] = [];
                    for (let index = 0; index < e.devices.length; index++) {
                        const element = e.devices[index];
                        _devices.push({
                            deviceId: element.id,
                            id: element.machineId.substring(0, 6),
                            type: element.type
                        })
                    }

                    setDeviceList([..._devices]);
                    setGroupName(groupToEdit.name);
                    setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);

                    setLoading(false);
                });
            } catch (err) {
                setErrorOccured(true);
            }
        } else {
            setErrorOccured(false);
        }
    }, [open, groupToEdit, t]);

    useEffect(() => {
        if (notificationDeviceToUpdate && selectedDevice) {
            setLoading(true);

            try {
                NotificationGroupService.GetNotificationDevices(groupToEdit.id, selectedDevice.deviceId).then((e) => {
                    setNotificationGroupList([...e]);
                    setGroupName(groupToEdit.name);

                    setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);

                    setLoading(false);
                });
            } catch (err) {
                setErrorOccured(true);
            }
        }
    },
        [notificationDeviceToUpdate]);

    useEffect(() => {
        if (notificationDeviceToDelete) {
            const notificationGroup = notificationGroupList.find(c => c.id === notificationDeviceToDelete.id);
            if (notificationGroup) {
                const notificationGroupIndex = notificationGroupList.indexOf(notificationGroup);
                if (notificationGroupIndex > -1) {
                    notificationGroupList.splice(notificationGroupIndex, 1);
                    setNotificationGroupList([...notificationGroupList]);
                }
            }
        }
    }, [notificationDeviceToDelete]);

    const fillDropdown = (data: SimpleDevice) => {
        return (
            <DropDownChildren key={data.deviceId + '-wrap'}>
                <DropDownItem key={data.deviceId} item={data}>
                    {data.id}
                </DropDownItem>
            </DropDownChildren>
        );
    };

    const isValid = () => {
        return notificationGroupList.length > 0;
    };

    const updateGroup = () => {
        if (groupToEdit) {
            setLoading(true);

            NotificationGroupService.PutNotificationGroupUsers(groupToEdit.id, notificationGroupList)
                .then((r) => {
                    if (onSubmit) {
                        onSubmit();
                    }
                })
                .catch(() => {
                    setErrorOccured(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const notificationDeviceEdit = (notificationDevice?: SimpleNotificationGroup) => {
        if (selectedDevice) {
            if (!notificationDevice) {
                notificationDevice = {
                    deviceId: selectedDevice.deviceId,
                    device: selectedDevice,
                    groupId: groupToEdit.id,
                    id: 0,
                    name: "",
                    notificationGroupDeviceCodes: [],
                    isDefault: false,
                    isMaster: false
                };
            } else{
                notificationDevice.device = selectedDevice;
            }
            onNotificationDeviceEdit(notificationDevice);
        }
    }

    const addNotificationUser = (notificationGroup: SimpleNotificationGroup) => {
        if (selectedDevice) {
            notificationGroup.groupId = groupToEdit.id;
            notificationGroup.deviceId = selectedDevice.deviceId;
            notificationGroup.device = selectedDevice;
            onAddNotificationUser(notificationGroup);
        }
    }

    const onDeviceChanged = (data: SimpleDevice) => {
        setLoading(true);
        try {
            NotificationGroupService.GetNotificationDevices(groupToEdit.id, data.deviceId)
                .then((e) => {
                    setNotificationGroupList([...e]);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (err) {
            setErrorOccured(true);
        }

        setSelectedDevice(data);
    }

    return (
        <>
            <SlideInModal width={700} open={open} handleClose={() => handleClose()}>
                <div className="patch-group__header">
                    <div className="patch-group__header-items">

                        <div
                            className={`patch-group__cancel patch-group__cancel-edit`}
                            onClick={() => handleClose()}
                        >
                            <span>{t('COMMON.CANCEL')}</span>
                            <CancelSvg className="patch-group__cancel-icon" />
                        </div>
                        <Button
                            className={`patch-group__submit-button patch-group__save`}
                            disabled={!isValid()}
                            onClick={() => updateGroup()}
                        >
                            {t('PATCH_GROUP.SAVE')}
                        </Button>
                    </div>
                </div>
                <div className="patch-group__body">
                    <div className="patch-group__body-title">
                    {t('PATCH_NOTIFICATION.EDIT_NOTIFICATION_DEVICE')}
                    </div>
                    <div className="patch-group__group-name-section">
                        <div className="patch-group__group-name-label">{t('PATCH_NOTIFICATION.GROUP_NAME')}</div>
                        <div className="patch-group__group-name">{groupName}</div>
                    </div>
                    <div className="patch-noticationdevice__device-dropdown">
                        <DropDown
                            selected={selectedDevice}
                            borderless={true}
                            label={t('PATCH_NOTIFICATION.DEVICE_NAME')}
                            selectedChange={(data: SimpleDevice) => {
                                onDeviceChanged(data);
                            }}
                            header={
                                <DropDownHeader>{selectedDevice ? selectedDevice.id.substring(0, 6) : t('PATCH_NOTIFICATION.SELECT_MACHINE')}</DropDownHeader>
                            }
                        >
                            {deviceList.map((data: SimpleDevice) => fillDropdown(data))}
                        </DropDown>
                    </div>

                    <div className="patch-group__group-userlist">
                        {notificationGroupList &&
                            <NotificationGroupUserList
                                onEditGroup={notificationDeviceEdit}
                                dataSource={notificationGroupList}
                                groupToEdit={groupToEdit}
                                onAddNotificationUser={addNotificationUser}
                                deviceToEdit={selectedDevice}
                            />
                        }
                        {selectedDevice && (
                            <div className="patch-notification__group_add">
                                <Button className={`patch-group__submit-button patch-group__addgroup`} onClick={() => notificationDeviceEdit(undefined)}>
                                    {t('PATCH_NOTIFICATION.ADD_NOTIFICATION_GROUP')}
                                </Button></div>
                        )}
                    </div>

                    {errorOccured && (
                        <ErrorBox className="patch-group_error-box" paragraphs={errorParagraphs} header={t('COMMON.ERROR')} />
                    )}
                </div>
                {loading && <Loader mode="fill-parent" />}
            </SlideInModal>
        </>
    );
};

export default PatchNotificationDevice