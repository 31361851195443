import { GetManuals } from '../models/GetManuals';
import { Manual as ManualRespond } from '../models/Manual';
import { Manual } from '../../models/Manual';
import { authorizedAxiosInstance } from './AuthorizedAxios';

export const GetHelpLinks = async (): Promise<Manual[]> => {
  const response = await (await authorizedAxiosInstance()).get<GetManuals>('api/manuals');

  const helpLinks: ManualRespond[] = response.data.manuals;

  return helpLinks.map((x) => {
    return {
      id: x.manufacturerId,
      title: x.title,
      description: x.description,
      fileType: x.fileType,
      size: x.sizeInBytes,
      link: x.link,
    } as Manual;
  });
};
