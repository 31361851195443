import React, { useEffect, useState } from 'react';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { ReactComponent as MoreSvg } from '../../../assets/common/moreBtn.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/settings/iconDeletefile.svg';
import { ReactComponent as EditSvg } from '../../../assets/settings/iconEdit.svg';
import * as UserService from '../../../data/services/UserService';
import User, { UserInviteStatus } from '../../../models/User';
import UserType from '../../../models/UserType';
import Button from '../../../shared/button/Button';
import ConfirmDialog from '../../../shared/confirmDialog/ConfirmDialog';
import ContextMenu from '../../../shared/contextMenu/ContextMenu';
import TableList from '../../../shared/list/TableList';
import Loader from '../../../shared/loader/Loader';
import SearchBar from '../../../shared/searchbar/SearchBar';
import Topbar from '../../../shared/topbar/Topbar';
import PatchUser from './patchUser/patchUser';
import authenticator from '../../../services/Authenticator';
import './UsersList.css';

const CONTEXT_MENU_WIDTH = 212;

const UsersList: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuCords, setContextMenuCords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [contextMenuIndex, setContextMenuIndex] = useState(-1);
    const [showPatchUserModal, setShowPatchUserModal] = useState(false);
    const [search, setSearch] = useState('');
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [mode, setMode] = useState<'add' | 'edit'>('add');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentSelectedUser, setCurrentSelectedUser] = useState<User>();
    const [errorOccured, setErrorOcurred] = useState(false);

    const { t }: UseTranslationResponse = useTranslation();

    useEffect(() => {
        let isMounted = true;
        setLoadingUsers(true);
        UserService.GetUsers()
            .then((userList) => {
                if (isMounted) {
                    setUserListWithoutCurrentUser(userList);
                }
            })
            .finally(() => {
                setLoadingUsers(false);
            });

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (!openDeleteModal) {
            setErrorOcurred(false);
        }
    }, [openDeleteModal]);

    const onSearch = (s: string) => {
        setSearch(s);
        if (s !== '') {
            const lowerSearch = s.toLowerCase();
            setFilteredUsers(
                users.filter((user) => {
                    return (
                        user.Name.toLowerCase().includes(lowerSearch) ||
                        user.Group.Name.toLowerCase().includes(lowerSearch) ||
                        getRoleName(user.Role).toLowerCase().includes(lowerSearch) ||
                        getStatusName(user.Status).toLowerCase().includes(lowerSearch)
                    );
                })
            );
        } else {
            setFilteredUsers(users);
        }
    };

    const getStatusName = (status: UserInviteStatus) => {
        if (status === UserInviteStatus.Active) {
            return t('USERS_LIST.USER_ACTIVE');
        } else {
            return t('USERS_LIST.USER_INVITE_PENDING');
        }
    };

    const setUserListWithoutCurrentUser = (users: User[]) => {
        const profileInfo = authenticator.getAccount();
        const usersWithoutSelf = users.filter((u) => u.B2cId !== profileInfo.accountIdentifier);
        const sortedusers = usersWithoutSelf.sort((a, b) => a.Name.localeCompare(b.Name));
        setUsers(sortedusers);
        setFilteredUsers(sortedusers);
    };

    const getStatusLabel = (user: User) => {
        let text: string;
        let bckColor: string;
        let txtColor: string;

        if (user.Status === UserInviteStatus.InvitePending && user.Role !== UserType.NotificationUser) {
            text = t('USERS_LIST.USER_INVITE_PENDING_ALL_CAPS');
            bckColor = '#E1671B';
            txtColor = '#fff';
        } else {
            text = t('USERS_LIST.USER_ACTIVE_ALL_CAPS');
            bckColor = '#CFCFCF';
            txtColor = '#6c6c6c';
        }

        return (
            <span className="userslist__status-label" style={{ backgroundColor: bckColor, color: txtColor }}>
                {text}
            </span>
        );
    };

    const getRoleName = (role: UserType) => {
        switch (role) {
            case UserType.Admin:
                return t('USERS_LIST.ADMIN_ROLE');
            case UserType.SuperUser:
                return t('USERS_LIST.SUPER_USER_ROLE');
            case UserType.RegularUser:
                return t('USERS_LIST.REGULAR_USER_ROLE');
            case UserType.NotificationUser:
                return t('USERS_LIST.NOTIFICATION_USER_ROLE');
        }
    };

    const handleClosePatchUser = () => {
        setShowPatchUserModal(false);
    };

    const openContextMenu = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, indexClicked: number) => {
        e.preventDefault();
        e.stopPropagation();

        if (indexClicked !== contextMenuIndex) {
            const boundingRect = e.currentTarget.getBoundingClientRect();
            setContextMenuCords({
                x: boundingRect.x + boundingRect.width / 2,
                y: boundingRect.y + boundingRect.height / 2,
            });
            setShowContextMenu(true);
            setContextMenuIndex(indexClicked);
        } else {
            closeContextMenu();
        }
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
        setContextMenuIndex(-1);
    };

    const deleteSelectedUser = () => {
        if (currentSelectedUser) {
            UserService.DeleteUser(currentSelectedUser.B2cId)
                .then(() => {
                    setOpenDeleteModal(false);
                    const index = users.indexOf(currentSelectedUser);
                    users.splice(index, 1);
                    setUsers([...users]); // Createing new array to make it run the useEffect because it only runs when it is a diffrent array
                    if (showPatchUserModal) {
                        setShowPatchUserModal(false);
                    }
                })
                .catch(() => {
                    setErrorOcurred(true);
                });
        }
    };

    const onSubmit = () => {
        setLoadingUsers(true);
        UserService.GetUsers()
            .then((userList) => {
                setUserListWithoutCurrentUser(userList);
            })
            .finally(() => {
                setLoadingUsers(false);
            });
    };

    return (
        <>
            <Topbar>
                <div className="userslist__searchfield">
                    <SearchBar
                        search={search}
                        onChange={(s: any) => onSearch(s)}
                        placeholder={t('USERS_LIST_SEARCH_BAR.PLACEHOLDER')}
                    ></SearchBar>
                </div>
            </Topbar>
            <div className="userslist__content" onClick={() => closeContextMenu()} onScroll={() => closeContextMenu()}>
                <div className="userslist__header">
                    <span className="userslist__title">{t('USERS_LIST.TITLE')}</span>
                    <Button
                        className="userslist__add"
                        onClick={() => {
                            setMode('add');
                            setShowPatchUserModal(true);
                        }}
                    >
                        {t('ADD_BUTTON.ADD_NEW')}
                    </Button>
                </div>
                <TableList
                    header={
                        <tr>
                            <th className="userlist__name-col">{t('USERS_LIST.NAME')}</th>
                            <th>{t('USERS_LIST.ROLE')}</th>
                            <th>{t('USERS_LIST.GROUP')}</th>
                            <th colSpan={2}>{t('USERS_LIST.STATUS')}</th>
                        </tr>
                    }
                >
                    {filteredUsers.map((u, i) => {
                        return (
                            <tr key={`user${i}`}>
                                <td className="userlist__name-col">{u.Name}</td>
                                <td>{getRoleName(u.Role)}</td>
                                <td>{u.Group.Name}</td>
                                <td>{getStatusLabel(u)}</td>
                                <td>
                                    <MoreSvg
                                        title={t('CONTEXT_MENU.TITLE')}
                                        className="userslist__more-btn"
                                        onClick={(e) => {
                                            openContextMenu(e, i);
                                            setCurrentSelectedUser(u);
                                        }}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </TableList>
                {loadingUsers && users.length < 1 && <Loader mode="inline" />}
            </div>

            <ContextMenu
                hidden={!showContextMenu}
                style={{
                    width: CONTEXT_MENU_WIDTH,
                }}
                left={contextMenuCords.x - CONTEXT_MENU_WIDTH}
                yPlacement={contextMenuCords.y}
                menuItems={[
                    {
                        content: (
                            <div>
                                <EditSvg className="userslist__context-icon" />
                                <span className="userslist__context-text">{t('CONTEXT_MENU.EDIT_USER')}</span>
                            </div>
                        ),
                        onClick: () => {
                            closeContextMenu();
                            setMode('edit');
                            setShowPatchUserModal(true);
                        },
                    },
                    {
                        content: (
                            <div>
                                <DeleteSvg className="userslist__context-icon" />
                                <span className="userslist__context-text">{t('CONTEXT_MENU.DELETE_USER')}</span>
                            </div>
                        ),
                        onClick: () => {
                            closeContextMenu();
                            setOpenDeleteModal(true);
                        },
                    },
                ]}
            />

            <PatchUser
                open={showPatchUserModal}
                handleClose={() => handleClosePatchUser()}
                mode={mode}
                userToEdit={currentSelectedUser}
                onSubmit={() => onSubmit()}
                onDelete={() => setOpenDeleteModal(true)}
            />
            {currentSelectedUser && openDeleteModal && (
                <ConfirmDialog
                    paragraphs={[t('DELETE_MODAL.FIRST_PARAGRAPH'), t('DELETE_MODAL.SECOND_PARAGRAPH_USER')]}
                    primaryText={t('DELETE_MODAL.CONFIRM_USER')}
                    secondaryText={t('COMMON.CANCEL')}
                    primaryClick={() => {
                        deleteSelectedUser();
                    }}
                    secondaryClick={() => setOpenDeleteModal(false)}
                    error={errorOccured}
                    errorHeader={t('COMMON.ERROR')}
                    errorParagraphs={[t('DELETE_MODAL.ERROR_USER')]}
                ></ConfirmDialog>
            )}
        </>
    );
};
export default UsersList;
