import { GetDeviceDetails as GetDeviceDetailsObject } from '../models/devices/GetDeviceDetails';
import { GetDevices as GetDevicesObject } from '../models/devices/GetDevices';
import { Location as DeviceLocation } from '../models/devices/Location';
import { authorizedAxiosInstance } from './AuthorizedAxios';
import { GetNotificationGroups as GetNotificationGroupsObject } from '../../data/models/notificationgroups/GetNotificationGroups';
import { GetNotificationGroup } from '../../data/models/notificationgroups/GetNotificationGroup';
import SimpleNotificationGroup from '../../models/SimpleNotificationGroup';
import SimpleNotificationGroupDeviceCode from '../../models/SimpleNotificationGroupDeviceCode';
import SimpleUser from '../../models/SimpleUser';

export const GetNotificationGroups = async (groupid: number): Promise<SimpleNotificationGroup[]> => {
    const response = await (await authorizedAxiosInstance()).get<GetNotificationGroupsObject>('/api/notificationgroups/' + groupid);

    const notificationGroupDataList: GetNotificationGroup[] = response.data.notificationGroups;
    const notificationGroupList: SimpleNotificationGroup[] = notificationGroupDataList.map((x) => {
        return {
            id: x.id,
            isDefault: x.isDefault,
            name: x.name,
            isMaster: false,
            notificationGroupDeviceCodes: x.notificationGroupDeviceCodes.map((u) => {
                return {
                    type: u.type,
                    bitNumber: u.bitNumber,
                    name: u.name,
                    selected: true
                } as SimpleNotificationGroupDeviceCode;
            }),
            users: x.users?.map((us) => {
                return {
                    Id: us.id,
                    Email: us.userName,
                    Name: us.fullName,
                    Selected: us.selected,
                    GroupName: ""
                } as SimpleUser;
            })
        } as SimpleNotificationGroup;
    });

    return notificationGroupList;
};

export const GetPresetNotificationGroups = async (groupId: number): Promise<SimpleNotificationGroup[]> => {
    const response = await (await authorizedAxiosInstance()).get<GetNotificationGroupsObject>('/api/notificationgroupspreset/' + groupId);

    const notificationGroupDataList: GetNotificationGroup[] = response.data.notificationGroups;
    const notificationGroupList: SimpleNotificationGroup[] = notificationGroupDataList.map((x) => {
        return {
            id: x.id,
            isDefault: x.isDefault,
            name: x.name,
            isMaster: x.isMaster,
            notificationGroupDeviceCodes: x.notificationGroupDeviceCodes.map((u) => {
                return {
                    type: u.type,
                    bitNumber: u.bitNumber,
                    name: u.name,
                    selected: true
                } as SimpleNotificationGroupDeviceCode;
            })
        } as SimpleNotificationGroup;
    });

    return notificationGroupList;
};

export const PutNotificationGroup = async (
    id: number,
    name: string,
    groupid: number,
    notificationGroupDeviceCodes: any[]
): Promise<void> => {
    const putNotificationGroup: any = {
        Name: name,
        NotificationGroupDeviceCodes: notificationGroupDeviceCodes
    };
    await (await authorizedAxiosInstance()).put(`/api/notificationgroups/${id}/${groupid}`, putNotificationGroup);
}

export const PostNotificationGroup = async (
    id: number,
    groupid: number,
    name: string,
    notificationGroupDeviceCodes: any[],
    deviceid?: string
): Promise<any> => {
    const putNotificationGroup: any = {
        id: id,
        groupid: groupid,
        name: name,
        notificationGroupDeviceCodes: notificationGroupDeviceCodes,
        deviceid: deviceid
    };
    const response = await (await authorizedAxiosInstance()).post(`/api/notificationgroups`, putNotificationGroup);
    return response.data;
}

export const DeleteNotificationGroup = async (id: number, groupid: number): Promise<boolean> => {
    const response = await (await authorizedAxiosInstance()).delete(`/api/notificationgroups/${id}/${groupid}`);
    return response.data;
}

export const PutNotificationGroupUsers = async (
    groupid: number,
    notificationGroups: any[]
): Promise<any> => {
    const putNotificationGroupUsers: any = {
        notificationGroups: notificationGroups
    };
    const response = await (await authorizedAxiosInstance()).put(`/api/notificationgroupusers/${groupid}`, putNotificationGroupUsers);
    return response.data;
}
export const GetNotificationDevices = async (groupid: number, deviceid: string): Promise<SimpleNotificationGroup[]> => {
    const response = await (await authorizedAxiosInstance()).get<GetNotificationGroupsObject>('/api/notificationgroups/' + groupid + '/' + deviceid);

    const notificationDeviceDataList: GetNotificationGroup[] = response.data.notificationGroups;
    const notificationDeviceList: SimpleNotificationGroup[] = notificationDeviceDataList.map((x) => {
        return {
            id: x.id,
            name: x.name,
            deviceId: x.deviceId,
            groupId: x.groupId,
            notificationGroupDeviceCodes: x.notificationGroupDeviceCodes.map((u) => {
                return {
                    type: u.type,
                    bitNumber: u.bitNumber,
                    name: u.name,
                    selected: true
                } as SimpleNotificationGroupDeviceCode;
            }),
            users: x.users?.map((us) => {
                return {
                    Id: us.id,
                    Email: us.userName,
                    Name: us.fullName,
                    Selected: us.selected,
                    GroupName: ""
                } as SimpleUser;
            })
        } as SimpleNotificationGroup;
    });

    return notificationDeviceList;
    
};