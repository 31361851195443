export const GetDomainStyleSheet = (): string => {
  const domainURL = window.location.origin;
  const dotIndex = domainURL.indexOf('.');
  let domain = domainURL.slice(0, dotIndex);
  if (domain.indexOf('https://') !== -1 || domain.indexOf('http://') !== -1) {
    const lastSlashIndex = domain.lastIndexOf('/');
    domain = domain.slice(lastSlashIndex + 1, domain.length);
  }
  const cssURL =  process.env.REACT_APP_CSS_BASE_URI + `${domain}.css`;
  return cssURL;
};
