import React, { useEffect } from 'react';
import { Offline } from 'react-detect-offline';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import { InformProfileLoggedIn } from './data/services/ProfileService';
import Main from './pages/main/Main';
import * as CssService from './services/CssService';
import './shared/globalCSS/GlobalCSS.css';
import { OfflineIndicator } from './shared/OfflineIndicator/OfflineIndicator';

const App: React.FC = () => {
  useEffect(() => {
    InformProfileLoggedIn().catch(() => {
      // Ignore error, since it is not critical just nice to know and it will run again next time user reloads/logs in.
      return;
    });
  }, []);

  return (
    <div className="app">
      <link rel="stylesheet" type="text/css" href={CssService.GetDomainStyleSheet()} />
      <Offline>
        <OfflineIndicator />
      </Offline>
      <Switch>
        <Route path="/" component={Main} />
      </Switch>
    </div>
  );
};

export default App;
