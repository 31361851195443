export interface DeviceCode {
    name: string;
    bitNumber: number;
    type: DeviceCodeType;
}

export enum DeviceCodeType {
    ERROR,
    WARNING,
    FILLLEVEL,
    SERVICE
}
