import { LatLngBounds } from 'leaflet';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as DisplayModeList } from '../../assets/topbar/iconDisplaymodeList.svg';
import { ReactComponent as DisplayModeListActive } from '../../assets/topbar/iconDisplaymodeListActive.svg';
import { ReactComponent as DisplayModeSplit } from '../../assets/topbar/iconDisplaymodeSplit.svg';
import { ReactComponent as DisplayModeSplitActive } from '../../assets/topbar/iconDisplaymodeSplitActive.svg';

import { GetDevices } from '../../data/services/DeviceService';
import { GetGroups, GetGroupsWithSelf } from '../../data/services/GroupService';
import Device from '../../models/Device';
import { DeviceOrderBy } from '../../models/DeviceOrderingEnum';
import Group from '../../models/Group';
import SearchBar from '../../shared/searchbar/SearchBar';
import Topbar from '../../shared/topbar/Topbar';
import { OrderDevicesBy } from '../../utils/OrderDevicesBy';
import { DeviceDetails } from './components/DeviceDetails/DeviceDetails';
import DeviceListView from './components/DeviceListView/DeviceListView';
import DeviceMapView from './components/DeviceMapView/DeviceMapView';
import { useMediaQuery } from 'react-responsive';
import { isMobile } from 'react-device-detect';
import './Home.css';
import { DeviceStateValues } from '../../models/DeviceStateValues';
import { FilterValue } from '../../models/FilterValue';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { OrderValue } from '../../models/OrderValue';
import useInterval from '../../utils/UseInterval';

const FETCH_INTERVAL_MS = parseInt(process.env.REACT_APP_FETCH_DEVICES_INTERVAL_IN_MS || '30000');

const Home: React.FC = () => {
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [search, setSearch] = useState('');
    const [listOrderBy, setListOrderBy] = useState<DeviceOrderBy>(DeviceOrderBy.ID);
    const [selectedLocation, setSelectedLocation] = useState<string>('');
    const [selectedFilters, setSelectedFilters] = useState<FilterValue[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [fetchDeviceError, setFetchDeviceError] = useState();
    const [filteredDevices, setFilteredDevices] = useState<Device[]>(devices);
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const [deviceDetailsOpen, setDeviceDetailsOpen] = useState(false);
    const [mapBounds, setBounds] = useState<LatLngBounds | undefined>();
    const [sortOrder, setSortOrder] = useState<OrderValue>(OrderValue.ASCENDING);
    const [stateValues, setStateValues] = useState<DeviceStateValues>({
        fullLevelCodesSet: [],
        overFullLevelCodesSet: [],
        tooFullToBaleOutCodesSet: [],
        offlineCodesSet: [],
        okCodesSet: [],
        errorsCodesSet: [],
        serviceDueCodesSet: [],
        almostEmptyCodesSet: [],
        halfFullCodesSet: [],
        almostFullCodesSet: [],
        warningCodesSet: [],
        emptyCodesSet: [],
    });

    const isNarrowWindow = useMediaQuery({ query: '(max-width: 730px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const { t }: UseTranslationResponse = useTranslation();

    const fetchDevices = (setLoading = true) => {
        if (setLoading) {
            setLoadingDevices(true);
        }
        GetDevices()
            .then((data) => {
                setStateValues({
                    okCodesSet: data.ok || [],
                    errorsCodesSet: data.errors || [],
                    fullLevelCodesSet: data.full || [],
                    overFullLevelCodesSet: data.overFull || [],
                    tooFullToBaleOutCodesSet: data.toFullToBaleOut || [],
                    offlineCodesSet: data.offline || [],
                    serviceDueCodesSet: data.serviceDue || [],
                    warningCodesSet: data.warnings || [],
                    almostFullCodesSet: data.almostFull || [],
                    halfFullCodesSet: data.halfFull || [],
                    almostEmptyCodesSet: data.almostEmpty || [],
                    emptyCodesSet: data.empty || [],
                });
                const devicesArrayisDifffrent = JSON.stringify(data?.devices) !== JSON.stringify(devices);
                if (devicesArrayisDifffrent) {
                    setDevices(data?.devices);
                }
            })
            .catch((err) => {
                setFetchDeviceError(err);
                setLoadingDevices(false);
            })
            .finally(() => {
                setLoadingDevices(false);
            });
    };

    const fetchGroups = () => {
        GetGroupsWithSelf()
            .then((data: Group) => setGroups(data.children))
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        if (devices && devices.length <= 0) {
            fetchDevices();
        }
        if (groups && groups.length <= 0) {
            fetchGroups();
        }
    }, []);

    useInterval(() => {
        fetchDevices(false);
    }, FETCH_INTERVAL_MS);

    // Handles search and list reordering
    useEffect(() => {
        if (devices && devices.length > 0) {
            setLoadingDevices(true);
            const reorderedList = OrderDevicesBy(
                devices,
                listOrderBy,
                search,
                sortOrder,
                selectedFilters,
                stateValues,
                mapBounds,
                t,
            );
            setFilteredDevices(reorderedList);
            setLoadingDevices(false);
        }
    }, [search, listOrderBy, devices, selectedFilters, sortOrder, mapBounds, stateValues, t]);

    const handleFilterByCategory = (newFilter: FilterValue) => {
        if (selectedFilters.includes(newFilter)) {
            setSelectedFilters(selectedFilters.filter((x) => x !== newFilter));
            return;
        }

        if (newFilter === FilterValue.SHOW_ALL) {
            setSelectedFilters([]);
        } else {
            setSelectedFilters([...selectedFilters, newFilter]);
        }
    };

    // Run this after we update any device - Since we dont have any kind of state management...
    const deviceUpdatedHandler = () => {
        fetchDevices();
    };

    const setSorting = (orderBy: DeviceOrderBy) => {
        if (listOrderBy === orderBy) {
            setSortOrder(sortOrder === OrderValue.ASCENDING ? OrderValue.DESCENDING : OrderValue.ASCENDING);
        } else {
            setSortOrder(OrderValue.ASCENDING);
            setListOrderBy(orderBy);
        }
    };

    const deviceOnClick = useCallback(
        (d: Device) => {
            setSelectedDevice(d);
            setDeviceDetailsOpen(true);
        },
        [setSelectedDevice, setDeviceDetailsOpen],
    );

    return (
        <div className="home__container">
            <div className="home__topbar-container">
                <Topbar>
                    <div className="home__searchfield">
                        <SearchBar
                            search={search}
                            onChange={(s) => setSearch(s)}
                            placeholder={t('DEVICE_SEARCH_BAR.PLACEHOLDER')}
                            collapsed={isNarrowWindow}
                        ></SearchBar>
                    </div>
                    {!(isMobile && isPortrait) && (
                        <div className="home__view-type-buttons-container">
                            <button
                                style={{ color: !showMap ? 'var(--topbariconselected)' : 'var(--topbaricon)' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => setShowMap(false)}
                            >
                                {showMap ? <DisplayModeList /> : <DisplayModeListActive />}
                            </button>
                            <button
                                style={{ color: showMap ? 'var(--topbariconselected)' : 'var(--topbaricon)' }}
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => setShowMap(true)}
                            >
                                {showMap ? <DisplayModeSplitActive /> : <DisplayModeSplit />}
                            </button>
                        </div>
                    )}
                   
                    <div className="topbar__vertical-line"></div>
                </Topbar>
            </div>
            <div className="home__content-container">
                <div style={{ width: showMap && !(isPortrait && isMobile) ? '50%' : '100%' }} className="home__device-list">
                    <DeviceListView
                        allDevices={devices}
                        stateValues={stateValues}
                        selectedFilters={selectedFilters}
                        devices={filteredDevices}
                        mapShown={showMap}
                        onClick={handleFilterByCategory}
                        deviceError={fetchDeviceError}
                        setListOrderBy={setSorting}
                        listOrderAttribute={listOrderBy}
                        onClickLocate={setSelectedLocation}
                        loading={loadingDevices}
                        onDeviceClicked={(d) => deviceOnClick(d)}
                        sortOrder={sortOrder}
                    ></DeviceListView>
                </div>
                {showMap && (
                    <div
                        style={{ transform: showMap && !(isPortrait && isMobile) ? 'translateX(0px)' : 'translateX(4000px)' }}
                        className="home__device-map"
                    >
                        <DeviceMapView
                            setBounds={setBounds}
                            selectedLocation={selectedLocation}
                            devices={filteredDevices}
                            setSelectedDevice={deviceOnClick}
                            stateValues={stateValues}
                        ></DeviceMapView>
                    </div>
                )}
                <DeviceDetails
                    device={selectedDevice}
                    isOpen={deviceDetailsOpen}
                    toggleOpen={() => setDeviceDetailsOpen(false)}
                    deviceUpdated={() => deviceUpdatedHandler()}
                    groups={groups}
                    stateValues={stateValues}
                />
            </div>
        </div>
    );
};
export default Home;
