import { Layer } from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { Marker, Popup, useLeaflet } from 'react-leaflet';
import { DeviceStatus } from '../../../../data/models/devices/DeviceStatus';
import Device, { FillLevel } from '../../../../models/Device';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import { GetCustomMarkerIconByStatus } from '../../../../utils/GetCustomMarkerIconByStatus';
import { MapPopupContent } from '../MapPopupContent/MapPopupContent';
import './MapMarker.css';

export type MapMarkerProps = {
  device: Device;
  id: string;
  type: string;
  client: string;
  position: PositionType;
  hasPopup: boolean;
  status: DeviceStatus;
  setSelectedDevice: (device: Device) => void;
  zoomToMarker: (marker: Layer) => void;
  fillLevel: FillLevel;
  stateValues: DeviceStateValues;
};

export type PositionType = {
  lat: number;
  lng: number;
  zoom?: number;
};

export const MapMarker: React.FC<MapMarkerProps> = ({
  device,
  id,
  type,
  client,
  position,
  hasPopup = false,
  status,
  fillLevel,
  stateValues,
  setSelectedDevice,
  zoomToMarker,
}: MapMarkerProps) => {
  const mapContext = useLeaflet();
  const { map } = mapContext;
  const markerRef = useRef<Marker>(null);

  useEffect(() => {
    const currentMarker = markerRef?.current?.leafletElement;
    const markPos = currentMarker?.getLatLng();
    const popupOpen = currentMarker?.isPopupOpen();
    if (hasPopup && !popupOpen && markPos) {
      const marker = markerRef?.current?.leafletElement;
      if (marker) zoomToMarker(marker);

      setTimeout(() => {
        currentMarker?.openPopup();
      }, 300);
    }
    if (!hasPopup && popupOpen) {
      currentMarker?.closePopup();
    }
  }, [hasPopup, map, zoomToMarker]);

  const customMarkerIcon = GetCustomMarkerIconByStatus(stateValues, device.id);

  return (
    <>
      {<div className={markerRef?.current?.leafletElement?.isPopupOpen() ? 'MapMarker__background--active' : ''}></div>}
      <Marker key={id} ref={markerRef} position={position} icon={customMarkerIcon}>
        <Popup className="MapMarker__popup">
          <MapPopupContent
            device={device}
            id={id}
            status={status}
            title={`${id.substring(0, 6)} · ${type}`}
            client={client}
            stateValues={stateValues}
            setSelectedDevice={setSelectedDevice}
          />
        </Popup>
      </Marker>
    </>
  );
};
