import { Location } from '../data/models/devices/Location';
import { DeviceCodeType } from '../data/models/devices/DeviceCode';

interface Device {
    fillLevel: FillLevel;
    id: string;
    machineId: string;
    type: string;
    deviceStatus: DeviceStatus;
    customer: {
        id: number;
        name: string;
    };
    group: {
        id: number;
        name: string;
    };
    site: string | undefined;
    lastEmpty: Date;
    offline: boolean;
    lastService: Date;
    location: Location;
    statistics: DeviceStatistics[];
    lastConnected: Date;
    wasteFraction: string | undefined;
    groupTree: string | undefined;
    cellularDataRemaining: number | undefined;
    selected?: boolean | undefined;
}
export interface DeviceStatus {
    errorCodesSet: DeviceStatusItem[];
    fillLevelCodesSet: DeviceStatusItem[];
    serviceCodeSet: DeviceStatusItem[];
    warningCodesSet: DeviceStatusItem[];
}

export interface DeviceStatusItem {
    name: string;
    bitNumber: number;
    type: DeviceCodeType;
}

export interface DeviceStatistics {
    key: string;
    value: number;
}

export enum FillLevel {
    empty = 0,
    almostEmpty,
    halfFull,
    almostFull,
    full,
    overFull,
    tooFullToBaleOut,
}

export enum Status {
    ok,
    serviceDue,
    error,
    offline,
    fillLevel,
    warning
}

export default Device;
