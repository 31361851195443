import React from 'react';
import { useTranslation } from 'react-i18next';
import * as MonthContainer from '../months/MonthContainer';
import './DeviceLastUpdatedDisplay.css';
import { getFormattedDateString } from '../../utils/DateFormat';

interface DeviceLastUpdatedDiplayProps {
  lastUpdated: Date;
}

export const DeviceLastUpdatedDisplay: React.FC<DeviceLastUpdatedDiplayProps> = ({ lastUpdated }) => {
  const { t } = useTranslation();

  const getFormattedDateSpan = () => {
    return (
      <span>
        {getFormattedDateString(lastUpdated)}
      </span>
    );
  };

  return (
    <div className="DeviceLastUpdatedDisplay">
      <div>
        {t('DEVICE.LAST_UPDATED')} {getFormattedDateSpan()}
      </div>
    </div>
  );
};
