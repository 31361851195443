import React from 'react';
import './DeviceStateTagsDisplay.css';
import { GetDeviceStates } from '../../utils/GetDeviceStates';
import { DeviceStateValues } from '../../models/DeviceStateValues';
import { useTranslation } from 'react-i18next';

export interface DeviceStateTagsDisplayProps {
  stateValues: DeviceStateValues;
  deviceId: string;
}

export const DeviceStateTagsDisplay: React.FC<DeviceStateTagsDisplayProps> = ({ stateValues, deviceId }) => {
  const { t } = useTranslation();
  return (
    <div className="DeviceStateTagsDisplay">
      {GetDeviceStates(stateValues, deviceId, t).map((data, index) => {
        return (
          <div
            key={index}
            className="DeviceStateTagsDisplay__text"
            style={{
              backgroundColor: data.bckColor,
              color: data.txtColor,
            }}
          >
            {data.text}
          </div>
        );
      })}
    </div>
  );
};
