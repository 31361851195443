import { TFunction } from 'i18next';
import { DeviceStateValues } from '../models/DeviceStateValues';

export interface DeviceStateVisuals {
  bckColor: string;
  text: string;
  txtColor: string;
}

export const GetDeviceStates = (
  stateValues: DeviceStateValues,
  deviceId: string,
  t: TFunction,
): DeviceStateVisuals[] => {
  const deviceStates: DeviceStateVisuals[] = [];

  if (stateValues.okCodesSet.includes(deviceId)) {
    //Device is Ok. Should be only tag
    deviceStates.push(CreateDeviceStateVisual('var(--ok)', 'var(--oktext)', t('STATE_TAG_ALL_CAPS.OK')));
    return deviceStates;
  }

  if (stateValues.offlineCodesSet.includes(deviceId)) {
    //Offline state
    deviceStates.push(CreateDeviceStateVisual('var(--black)', 'var(--white)', t('STATE_TAG_ALL_CAPS.OFFLINE')));
  }

  if (stateValues.errorsCodesSet.includes(deviceId)) {
    //Error
    deviceStates.push(CreateDeviceStateVisual('var(--error)', 'var(--white)', t('STATE_TAG_ALL_CAPS.ERROR')));
  }

  if (stateValues.serviceDueCodesSet.includes(deviceId)) {
    //Service due
    deviceStates.push(CreateDeviceStateVisual('var(--warning)', 'var(--white)', t('STATE_TAG_ALL_CAPS.SERVICE_DUE')));
  }

  if (
    stateValues.fullLevelCodesSet.includes(deviceId) ||
    stateValues.overFullLevelCodesSet.includes(deviceId) ||
    stateValues.tooFullToBaleOutCodesSet.includes(deviceId)
  ) {
    //Fill level
    deviceStates.push(CreateDeviceStateVisual('var(--warning)', 'var(--white)', t('STATE_TAG_ALL_CAPS.FULL')));
  }

  return deviceStates;
};

const CreateDeviceStateVisual = (bckColor: string, txtColor: string, text: string) => {
  return {
    bckColor: bckColor,
    text: text,
    txtColor: txtColor,
  } as DeviceStateVisuals;
};
