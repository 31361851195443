import { times } from 'lodash';
import React from 'react';
import { ReactComponent as ChevronLeftIcon } from '../../../../../../assets/common/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '../../../../../../assets/common/chevron-right.svg';
import './DeviceErrorCodesNavigation.css';

type DeviceErrorCodesNavigationProps = {
  currentPage: number;
  toNextPage: (direction: number) => void;
  toPrevPage: (direction: number) => void;
  toSpecificPage: (page: number) => void;
  totalPages: number;
};

export const DeviceErrorCodesNavigation: React.FC<DeviceErrorCodesNavigationProps> = ({
  totalPages,
  currentPage,
  toPrevPage,
  toNextPage,
  toSpecificPage,
}) => {
  return (
    <div className="DeviceErrorCodesNavigation">
      <div
        className="DeviceErrorCodesNavigation__navigation DeviceErrorCodesNavigation__navigation-prev"
        style={{ opacity: currentPage === 0 ? 0.3 : 1 }}
        onClick={() => toPrevPage(-1)}
      >
        <ChevronLeftIcon />
      </div>
      {times(totalPages, (i) => (
        <DeviceErrorCodesNavigationBlob key={i} active={i === currentPage} onClick={() => toSpecificPage(i)} />
      ))}
      <div
        className="DeviceErrorCodesNavigation__navigation DeviceErrorCodesNavigation__navigation-next"
        style={{ opacity: currentPage === totalPages - 1 ? 0.3 : 1 }}
        onClick={() => toNextPage(1)}
      >
        <ChevronRightIcon />
      </div>
    </div>
  );
};

type DeviceErrorCodesNavigationBlobProps = {
  active: boolean;
  onClick: () => void;
};

const DeviceErrorCodesNavigationBlob: React.FC<DeviceErrorCodesNavigationBlobProps> = ({ active, onClick }) => (
  <div
    className="DeviceErrorCodesNavigationBlob"
    style={{ backgroundColor: active ? '#e1671b' : '#cfcfcf' }}
    onClick={onClick}
  ></div>
);
