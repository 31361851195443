import React from 'react';
import { FillLevel } from '../models/Device';
import { ReactComponent as FillLevel0 } from '../assets/deviceList/fillLevel/iconFill0.svg';
import { ReactComponent as FillLevel1 } from '../assets/deviceList/fillLevel/iconFill1.svg';
import { ReactComponent as FillLevel2 } from '../assets/deviceList/fillLevel/iconFill2.svg';
import { ReactComponent as FillLevel3 } from '../assets/deviceList/fillLevel/iconFill3.svg';
import { ReactComponent as FillFull } from '../assets/deviceList/fillLevel/iconFillFull.svg';
import { ReactComponent as FillOverFull } from '../assets/deviceList/fillLevel/iconFillOverFull.svg';
import { ReactComponent as FillTooFullToBaleOut } from '../assets/deviceList/fillLevel/iconFillTooFullToBaleOut.svg';

export const getDeviceFillLevelIcon = (fillLevel: FillLevel): JSX.Element => {
  switch (fillLevel) {
    case FillLevel.empty:
      return <FillLevel0 className="devicelist__icon" />;
    case FillLevel.almostEmpty:
      return <FillLevel1 className="devicelist__icon" />;
    case FillLevel.halfFull:
      return <FillLevel2 className="devicelist__icon" />;
    case FillLevel.almostFull:
      return <FillLevel3 className="devicelist__icon" />;
    case FillLevel.full:
      return <FillFull className="devicelist__icon" />;
    case FillLevel.overFull:
      return <FillOverFull className="devicelist__icon" />;
    case FillLevel.tooFullToBaleOut:
      return <FillTooFullToBaleOut className="devicelist__icon" />;
  }
};

export const getDeviceFillLevelTextKey = (fillLevel: FillLevel): string => {
  switch (fillLevel) {
    case FillLevel.empty:
      return 'FILL_LEVEL.EMPTY';
    case FillLevel.almostEmpty:
      return 'FILL_LEVEL.FILL_LEVEL_1';
    case FillLevel.halfFull:
      return 'FILL_LEVEL.FILL_LEVEL_2';
    case FillLevel.almostFull:
      return 'FILL_LEVEL.ALMOST_FULL';
    case FillLevel.full:
      return 'FILL_LEVEL.FULL';
    case FillLevel.overFull:
      return 'FILL_LEVEL.OVERFULL';
    case FillLevel.tooFullToBaleOut:
      return 'FILL_LEVEL.TOOFULL';
  }
};
