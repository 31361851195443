import React from 'react';
import './TableList.css';

interface ListProps {
  header: React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;
}

const TableList: React.FC<ListProps> = ({ header, children }) => {
  return (
    <table className="table-list__table">
      <thead className="table-list__table-head">{header}</thead>
      <tbody className="table-list__table-body">{children}</tbody>
    </table>
  );
};

export default TableList;
