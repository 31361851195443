import React from 'react';
import Button from '../../shared/button/Button';
import { ReactComponent as CloseIcon } from '../../assets/common/iconExitGrey.svg';
import './Settings.css';
import { useHistory, Switch, Redirect, useLocation, useRouteMatch, Route } from 'react-router-dom';
import UsersList from './users/UsersList';
import GroupsList from './groups/GroupsList';
import NotificationsList from './notifications/NotificationsList';
import { useTranslation } from 'react-i18next';

const Settings: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    const { t } = useTranslation();

    return (
        <>
            <div className="settings__sidebar">
                {/* TODO On click should route to correct routes */}
                <Button className="settings__close-settings settings__sidebar-button" onClick={() => history.push('/')}>
                    <span className="settings__close-text">{t('SETTINGS.CLOSE')}</span>{' '}
                    <CloseIcon className="settings__close-icon" />
                </Button>
                {/* TODO Background color and text color should be set on the currently chosen based on route */}
                <Button
                    style={
                        location.pathname.includes('/users') ? { backgroundColor: 'var(--darkgrary)', color: 'var(--white)' } : {}
                    }
                    className="settings__tab-button settings__first-tab-button settings__sidebar-button"
                    onClick={() => history.push(`${match.path}/users`)}
                >
                    {t('SETTINGS.USERS')}
                </Button>
                <Button
                    style={
                        location.pathname.includes('/groups') ? { backgroundColor: 'var(--darkgrary)', color: 'var(--white)' } : {}
                    }
                    className="settings__tab-button settings__sidebar-button"
                    onClick={() => history.push(`${match.path}/groups`)}
                >
                    {t('SETTINGS.GROUPS')}
                </Button>
                <Button
                    style={
                        location.pathname.includes('/notifications') ? { backgroundColor: 'var(--darkgrary)', color: 'var(--white)' } : {}
                    }
                    className="settings__tab-button settings__sidebar-button"
                    onClick={() => history.push(`${match.path}/notifications`)}
                >
                    {t('SETTINGS.NOTIFICATIONS')}
                </Button>
            </div>
            <div className="settings__main-area">
                <Switch>
                    <Route path={`${match.path}/users`} component={UsersList} />
                    <Route path={`${match.path}/groups`} component={GroupsList} />
                    <Route path={`${match.path}/notifications`} component={NotificationsList} />
                    <Redirect path={`${match.path}/`} to={`${match.path}/users`} />
                </Switch>
            </div>
        </>
    );
};

export default Settings;
