import React, { useState, useEffect } from 'react';
import Topbar from '../../../shared/topbar/Topbar';
import TableList from '../../../shared/list/TableList';
import Group from '../../../models/Group';
import { ReactComponent as MoreSvg } from '../../../assets/common/moreBtn.svg';
import { ReactComponent as EditSvg } from '../../../assets/settings/iconEdit.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/settings/iconDeletefile.svg';
import './GroupsList.css';
import ContextMenu from '../../../shared/contextMenu/ContextMenu';
import Button from '../../../shared/button/Button';
import * as GroupService from '../../../data/services/GroupService';
import Loader from '../../../shared/loader/Loader';
import PatchGroup from './patchGroup/PatchGroup';
import SearchBar from '../../../shared/searchbar/SearchBar';
import ConfirmDialog from '../../../shared/confirmDialog/ConfirmDialog';
import { useTranslation, UseTranslationResponse } from 'react-i18next';

const CONTEXT_MENU_WIDTH = 212;
const CHILD_INDENT = 20;

const GroupsList: React.FC = () => {
  const [groupStructure, setGroupStructure] = useState<Group>();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuCords, setContextMenuCords] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [contextMenuIndex, setContextMenuIndex] = useState(-1);
  const [search, setSearch] = useState('');
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [showPatchGroup, setShowPatchGroup] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [currentSelectedGroup, setCurrentSelectedGroup] = useState<Group>();
  const [selectedGroupsParent, setSelectedGroupsParent] = useState<Group>();
  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const { t }: UseTranslationResponse = useTranslation();

  //TODO Handle groups fetch Error, user should be informed in some way
  const fetchGroups = async () => {
    try {
      setLoadingGroups(true);
      const group = await GroupService.GetGroups();
      setGroupStructure(group);
    } finally {
      setLoadingGroups(false);
    }
  };

  const getChildrenFromStructure = () => {
    return groupStructure ? groupStructure.children : [];
  };

  const setDirectChildrenInStructure = (children: Group[]) => {
    if (groupStructure) {
      groupStructure.children = children;
      setGroupStructure(groupStructure);
    }
  };

  useEffect(() => {
    fetchGroups().then(() => {
      return;
    });
  }, []);

  useEffect(() => {
    if (!openDeleteModal) {
      setErrorOccured(false);
    }
  }, [openDeleteModal]);

  const openContextMenu = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, indexClicked: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (indexClicked !== contextMenuIndex) {
      const boundingRect = e.currentTarget.getBoundingClientRect();
      setContextMenuCords({
        x: boundingRect.x + boundingRect.width / 2,
        y: boundingRect.y + boundingRect.height / 2,
      });
      setShowContextMenu(true);
      setContextMenuIndex(indexClicked);
    } else {
      closeContextMenu();
    }
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuIndex(-1);
  };

  const filter = (group: Group) => {
    if (search.length === 0) {
      return true;
    }

    const searchWords = search.toLocaleLowerCase().split(' ');

    let meetsSearch = true;

    searchWords.forEach((element) => {
      if (!group.name.toLocaleLowerCase().includes(element)) {
        meetsSearch = false;
        return;
      }
    });

    return meetsSearch;
  };

  const filterTreeBySearch = (groups: Group[]) => {
    const matching: Group[] = [];
    groups.forEach((element) => {
      const matchingChildren = filterTreeBySearch(element.children);
      if (matchingChildren.length > 0) {
        element.children = matchingChildren;
        matching.push(element);
      } else if (filter(element)) {
        element.children = [];
        matching.push(element);
      }
    });
    return matching;
  };

  const removeFromGroupArray = (toRemove: Group, array: Group[]) => {
    const index = array.indexOf(toRemove);
    if (index >= 0) {
      array.splice(index, 1);
    } else {
      array = array.map((g) => {
        g.children = removeFromGroupArray(toRemove, g.children);
        return g;
      });
    }
    return array;
  };

  const deleteCurrentSelectedgroup = () => {
    if (currentSelectedGroup) {
      const groupToDelete = currentSelectedGroup;
      GroupService.DeleteGroup(groupToDelete.id)
        .then(() => {
          setOpenDeleteModal(false);
          const groupsWithoutDeleted = removeFromGroupArray(groupToDelete, getChildrenFromStructure());
          setDirectChildrenInStructure(groupsWithoutDeleted);
          if (showPatchGroup) {
            setShowPatchGroup(false);
          }
        })
        .catch(() => {
          setErrorOccured(true);
        });
    }
  };

  const onSubmit = () => {
    fetchGroups().then(() => {
      return;
    });
  };

  const groupsToRow = (parentGroup: Group, keyPrefix = 'groups'): React.ReactFragment => {
    return parentGroup.children.map((group, i) => {
      const key = `${keyPrefix}${i}`;
      return (
        <React.Fragment key={key}>
          <tr>
            <td className="grouplist__name-col">
              <span style={{ paddingLeft: `${(group.nestLevel - 2) * CHILD_INDENT}px` }}>{group.name}</span>
            </td>
            <td>{`${group.usersCount} user${group.usersCount === 1 ? '' : 's'}`}</td>
            <td>{`${group.fleetSize} machine${group.fleetSize === 1 ? '' : 's'}`}</td>
            <td>
              <MoreSvg
                title={t('CONTEXT_MENU.TITLE')}
                className="grouplist__more-btn"
                onClick={(e) => {
                  openContextMenu(e, i);
                  setCurrentSelectedGroup(group);
                  setSelectedGroupsParent(parentGroup);
                }}
              />
            </td>
          </tr>
          {groupsToRow(group, key)}
        </React.Fragment>
      );
    });
  };

  const filteredGroupStructure = () => {
    if (groupStructure) {
      const filteredChildren = filterTreeBySearch(getChildrenFromStructure());
      const groupWithFilteredChildren = { ...groupStructure };
      groupWithFilteredChildren.children = filteredChildren;
      return groupsToRow(groupWithFilteredChildren);
    }
    return undefined;
  };

  return (
    <>
      <Topbar>
        <div className="grouplist__searchfield">
          <SearchBar
            search={search}
            onChange={(s) => setSearch(s)}
            placeholder={t('GROUPS_LIST_SEARCH_BAR.PLACEHOLDER')}
          ></SearchBar>
        </div>
      </Topbar>
      <div className="grouplist__content" onClick={() => closeContextMenu()} onScroll={() => closeContextMenu()}>
        <div className="grouplist__header">
          <span className="grouplist__title">{t('GROUPS_LIST.TITLE')}</span>
          <Button
            className="grouplist__add"
            onClick={() => {
              setShowPatchGroup(true);
              setMode('create');
            }}
          >
            {t('ADD_BUTTON.ADD_NEW')}
          </Button>
        </div>
        <TableList
          header={
            <tr>
              <th className="grouplist__name-col">{t('GROUPS_LIST.NAME')}</th>
              <th>{t('GROUPS_LIST.USERS')}</th>
              <th colSpan={2}>{t('GROUPS_LIST.FLEET')}</th>
            </tr>
          }
        >
          {filteredGroupStructure()}
        </TableList>
        {loadingGroups && !groupStructure && <Loader mode="inline" />}
        <ContextMenu
          hidden={!showContextMenu}
          style={{
            width: CONTEXT_MENU_WIDTH,
          }}
          yPlacement={contextMenuCords.y}
          left={contextMenuCords.x - CONTEXT_MENU_WIDTH}
          menuItems={[
            {
              content: (
                <div>
                  <EditSvg className="grouplist__context-icon" />
                  <span className="grouplist__context-text">{t('CONTEXT_MENU.EDIT_GROUP')}</span>
                </div>
              ),
              onClick: () => {
                closeContextMenu();
                setMode('edit');
                setShowPatchGroup(true);
              },
            },
            {
              content: (
                <div>
                  <DeleteSvg className="grouplist__context-icon" />
                  <span className="grouplist__context-text">{t('CONTEXT_MENU.DELETE_GROUP')}</span>
                </div>
              ),
              onClick: () => {
                closeContextMenu();
                setOpenDeleteModal(true);
              },
            },
          ]}
        />
        {groupStructure && (
          <PatchGroup
            open={showPatchGroup}
            handleClose={() => setShowPatchGroup(false)}
            mode={mode}
            onDelete={() => setOpenDeleteModal(true)}
            onSubmit={() => onSubmit()}
            groupToEdit={currentSelectedGroup}
            groupList={[groupStructure]}
            groupsExistingParent={selectedGroupsParent}
          />
        )}
        {currentSelectedGroup && openDeleteModal && (
          <ConfirmDialog
            paragraphs={[
              t('DELETE_MODAL.FIRST_PARAGRAPH'),
              t('DELETE_MODAL.SECOND_PARAGRAPH_GROUP'),
              t('DELETE_MODAL.THIRD_PARAGRAPH_GROUP'),
            ]}
            primaryText={t('DELETE_MODAL.CONFIRM_GROUP')}
            secondaryText={t('COMMON.CANCEL')}
            primaryClick={() => {
              deleteCurrentSelectedgroup();
            }}
            secondaryClick={() => setOpenDeleteModal(false)}
            error={errorOccured}
            errorHeader={t('COMMON.ERROR')}
            errorParagraphs={[t('DELETE_MODAL.ERROR_GROUP')]}
          ></ConfirmDialog>
        )}
      </div>
    </>
  );
};
export default GroupsList;
