import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconLocateSmall } from '../../assets/map/iconPointerSmall.svg';
import './LocateButton.css';

type LocateButtonProps = {
  onClick: () => void;
};

export const LocateButton: React.FC<LocateButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button onClick={onClick} className="LocateButton">
      <div className="LocateButton__icon">
        <IconLocateSmall />
      </div>
      <p className="LocateButton__text">{t('LOCATE_BUTTON.LOCATE')}</p>
    </button>
  );
};
