import React from 'react';
import './SlideInModal.css';

export interface SlideInModalProps {
  width: number;
  open: boolean;
  handleClose: () => void;
}

export const SlideInModal: React.FC<SlideInModalProps> = ({ children, width, open, handleClose }) => {
  const openStyle: React.CSSProperties = { width: width };
  const closeStyle: React.CSSProperties = { transform: `translate(${width}px)`, width: width };
  return (
    <div>
      <div className="slide-in-modal" style={open ? openStyle : closeStyle}>
        <React.Fragment>{children}</React.Fragment>
      </div>
      <div
        className="slide-in-modal__overlay"
        onClick={() => handleClose()}
        style={{
          visibility: open ? 'visible' : 'hidden',
        }}
      />
    </div>
  );
};

export default SlideInModal;
