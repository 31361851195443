import React from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Switch, useHistory } from 'react-router-dom';
import UserType from '../../models/UserType';
import AccessRoute from '../../shared/accessRoute/AccessRoute';
import Home from '../home/Home';
import { Manuals } from '../Manuals/Manuals';
import { Reports } from '../Reports/Reports';
import Settings from '../settings/Settings';
import './Main.css';

const Main: React.FC = () => {
  const history = useHistory();

  return (
    <>
      {!isMobile && (
        <div className="sidebar">
          <div>
            <div className="company-logo" onClick={() => history.push('/')} />
          </div>
        </div>
      )}
      <div className={`main-area ${isMobile ? 'main-area-full' : ''}`}>
        <Switch>
          <Route path="/" component={Home} exact />
          <AccessRoute path="/settings" component={Settings} requiredRole={UserType.Admin} />
          <Route path="/help" component={Manuals} />
          <Route path="/reports" component={Reports} />
        </Switch>
      </div>
    </>
  );
};

export default Main;
