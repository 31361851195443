import React, { useEffect, useState } from 'react';
import { ReactComponent as IconShowAll } from '../../../../assets/map/iconShowAll.svg';
import { ReactComponent as IconPointerGrey } from '../../../../assets/map/pointerGrey.svg';
import Device from '../../../../models/Device';
import { DeviceOrderBy } from '../../../../models/DeviceOrderingEnum';
import Loader from '../../../../shared/loader/Loader';
import { FilterDeviceItem, FilterDeviceItemType, FilterDevices } from '../FilterDevices/FilterDevices';
import DeviceList from './DeviceList/DeviceList';
import { DeviceListCollapsed } from './DeviceListCollapsed/DeviceListCollapsed';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../shared/button/Button';
import { isMobile } from 'react-device-detect';
import './DeviceListView.css';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { union } from 'lodash';
import { DeviceStateValues } from '../../../../models/DeviceStateValues';
import { FilterValue } from '../../../../models/FilterValue';
import { OrderValue } from '../../../../models/OrderValue';

const INITIAL_PAGE_ITEM_COUNT = 50;
const ITEM_MIN_HEIGHT = 50;
const ITEM_BUFFER = 10;
const SCROLLED_TO_BOTTOM_DIFFERENCE = 1.5;

interface DeviceListViewProps {
  devices: Device[];
  mapShown: boolean;
  onClick: (filterToToggle: FilterValue) => void;
  onClickLocate: (id: string) => void;
  selectedFilters: FilterValue[];
  setListOrderBy: (orderBy: DeviceOrderBy) => void;
  listOrderAttribute: string;
  deviceError?: Error | undefined;
  stateValues: DeviceStateValues;
  allDevices: Device[];
  loading: boolean;
  onDeviceClicked: (device: Device) => void;
  sortOrder: OrderValue;
}

const DeviceListView: React.FC<DeviceListViewProps> = ({
  devices,
  mapShown,
  onClick,
  selectedFilters,
  setListOrderBy,
  listOrderAttribute,
  deviceError,
  onClickLocate,
  stateValues,
  loading,
  allDevices,
  onDeviceClicked,
  sortOrder,
}) => {
  const [page, setPage] = useState(1);
  const [itemPerPageCount, setItemPerPageCount] = useState(INITIAL_PAGE_ITEM_COUNT);
  const [filtersShown, setFilterShown] = useState(false);

  const showNarrowList = useMediaQuery({ query: '(max-width: 910px)' });
  const { t }: UseTranslationResponse = useTranslation();

  const partialDeviceList = devices.slice(0, page * itemPerPageCount);

  useEffect(() => {
    if (window.innerHeight > (itemPerPageCount - ITEM_BUFFER) * ITEM_MIN_HEIGHT) {
      setItemPerPageCount(Math.ceil(window.innerHeight / ITEM_MIN_HEIGHT + ITEM_BUFFER));
    }
  });

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const scrollingLeft = (e.target as any).scrollHeight - (e.target as any).scrollTop - (e.target as any).clientHeight;
    const bottom = scrollingLeft <= SCROLLED_TO_BOTTOM_DIFFERENCE;
    if (bottom) {
      setPage(page + 1);
    }
  };

  return (
    <div className="devicelist_container">
      <div className={`DeviceListView__filter ${isMobile && 'DeviceListView__filter-compact'}`}>
        {(filtersShown || !isMobile) && (
          <FilterDevices showNarrow={mapShown || showNarrowList}>
            <FilterDeviceItem
              amount={stateValues.errorsCodesSet.length}
              text={t('DEVICE_FILTER.ERRORS')}
              type={FilterDeviceItemType.ERROR}
              onClick={() => onClick(FilterValue.ERRORS)}
              isActive={selectedFilters.includes(FilterValue.ERRORS)}
            />
            <FilterDeviceItem
              amount={
                union(
                  stateValues.fullLevelCodesSet,
                  stateValues.overFullLevelCodesSet,
                  stateValues.tooFullToBaleOutCodesSet,
                ).length
              }
              text={t('DEVICE_FILTER.FULL')}
              type={FilterDeviceItemType.WARNING}
              onClick={() => onClick(FilterValue.FULL)}
              isActive={selectedFilters.includes(FilterValue.FULL)}
            />
            <FilterDeviceItem
              amount={stateValues.serviceDueCodesSet.length}
              text={t('DEVICE_FILTER.SERVICE_DUE')}
              type={FilterDeviceItemType.WARNING}
              onClick={() => onClick(FilterValue.SERVICE_DUE)}
              isActive={selectedFilters.includes(FilterValue.SERVICE_DUE)}
            />
            <FilterDeviceItem
              amount={stateValues.okCodesSet.length}
              text={t('DEVICE_FILTER.OK')}
              type={FilterDeviceItemType.NORMAL}
              onClick={() => onClick(FilterValue.OK)}
              isActive={selectedFilters.includes(FilterValue.OK)}
            />
            <FilterDeviceItem
              amount={stateValues.almostFullCodesSet.length}
              text={t('DEVICE_FILTER.ALMOST_FULL')}
              type={FilterDeviceItemType.NORMAL}
              onClick={() => onClick(FilterValue.ALMOST_FULL)}
              isActive={selectedFilters.includes(FilterValue.ALMOST_FULL)}
            />
            {mapShown && (
              <FilterDeviceItem
                amount={0}
                text={t('DEVICE_FILTER.FILTER_MAP')}
                isActive={selectedFilters.includes(FilterValue.MAP_VIEW)}
                onClick={() => onClick(FilterValue.MAP_VIEW)}
                type={FilterDeviceItemType.NORMAL}
                iconOverride={
                  <IconPointerGrey
                    style={{ height: '22px', width: '14px', marginRight: '11px', minWidth: '14px', marginLeft: '3px' }}
                  />
                }
              />
            )}
            <FilterDeviceItem
              amount={stateValues.offlineCodesSet.length}
              text={t('DEVICE_FILTER.OFFLINE')}
              type={FilterDeviceItemType.NORMAL}
              onClick={() => onClick(FilterValue.OFFLINE)}
              isActive={selectedFilters.includes(FilterValue.OFFLINE)}
            />
            <FilterDeviceItem
              amount={
                union(
                  stateValues.emptyCodesSet,
                  stateValues.almostEmptyCodesSet,
                  stateValues.halfFullCodesSet
                ).length
              }
              text={t('DEVICE_FILTER.EMPTY_AND_LOW')}
              type={FilterDeviceItemType.NORMAL}
              onClick={() => onClick(FilterValue.EMPTY)}
              isActive={selectedFilters.includes(FilterValue.EMPTY)}
            />
            <FilterDeviceItem
              amount={allDevices.length}
              text={t('DEVICE_FILTER.SHOW_ALL', { amount: allDevices.length })}
              type={FilterDeviceItemType.SHOWALL}
              onClick={() => onClick(FilterValue.SHOW_ALL)}
              isActive={false}
              iconOverride={<IconShowAll style={{ height: '13px', width: '21px', marginRight: '10px' }} />}
            />
          </FilterDevices>
        )}
        {isMobile && (
          <Button size="small" onClick={() => setFilterShown(!filtersShown)}>
            {filtersShown ? t('DEVICE_FILTER_BUTTON.HIDE') : t('DEVICE_FILTER_BUTTON.SHOW')}
          </Button>
        )}
      </div>
      {deviceError && <div className="DeviceListView__message">{deviceError.message}</div>}
      {!loading &&
        !deviceError &&
        (allDevices.length <= 0 ? (
          <div className="DeviceListView__message">{t('DEVICE_LIST.NO_DEVICES')}</div>
        ) : (
          devices.length <= 0 && <div className="DeviceListView__message">{t('DEVICE_LIST.NO_SEARCH_RESULT')}</div>
        ))}
      {loading && <Loader className="DeviceListView__loading" />}
      {!loading && !deviceError && !mapShown && !showNarrowList && devices.length > 0 && (
        <DeviceList
          setListOrderBy={setListOrderBy}
          devices={partialDeviceList}
          listOrderAttribute={listOrderAttribute}
          handleScroll={handleScroll}
          onDeviceClicked={onDeviceClicked}
          sortOrder={sortOrder}
          stateValues={stateValues}
        />
      )}
      {!loading && !deviceError && (mapShown || showNarrowList) && devices.length > 0 && (
        <DeviceListCollapsed
          devices={partialDeviceList}
          onClickLocate={onClickLocate}
          setListOrderBy={setListOrderBy}
          listOrderAttribute={listOrderAttribute}
          handleScroll={handleScroll}
          onDeviceClicked={onDeviceClicked}
          showMapLocateButton={mapShown}
          sortOrder={sortOrder}
          stateValues={stateValues}
        />
      )}
    </div>
  );
};

export default DeviceListView;
