import User, { ServiceToolAccessStatus, UserInviteStatus } from '../../models/User';
import UserType from '../../models/UserType';
import GetUser, { UserRole } from '../models/GetUser';
import { GetUsers as UserListObject } from '../models/GetUsers';
import PostUser, { toPermission } from '../models/PostUser';
import { PutUser } from '../models/PutUser';
import { authorizedAxiosInstance } from './AuthorizedAxios';
import { platformUrl } from '../../utils/UrlUtils';

export const InviteNewUser = async (
    email: string,
    name: string,
    usertype: UserType,
    groupId: number,
    phoneNumber: string,
    hasServicetoolAccess: boolean,
): Promise<number> => {
    const invitedUser: PostUser = {
        email: email,
        name: name,
        permission: toPermission(usertype),
        groupId: groupId,
        phoneNumber: phoneNumber,
        hasServicetoolAccess: hasServicetoolAccess,
        platformUrl: platformUrl(),
    };

    let id: number = -1;

    await (await authorizedAxiosInstance()).post('/api/users', invitedUser)
        .then((r) => {
            if (r?.data?.id) {
                id = r.data.id;
            }
        }).catch((e) => {
            console.exception(e);
        });

    return id;
};

export const DeleteUser = async (userB2cId: string): Promise<void> => {
    const axiosInstance = await authorizedAxiosInstance();
    await axiosInstance.delete(`/api/users/${userB2cId}`);
};

export const GetUsers = async (): Promise<User[]> => {
    const axiosInstance = await authorizedAxiosInstance();
    const response = await axiosInstance.get<UserListObject>(`/api/users`);
    const userDataList: GetUser[] = response.data.users;
    const userList: User[] = userDataList.map((x) => {
        return {
            Name: x.fullName,
            Group: { Id: x.groupId, Name: x.groupName },
            Id: x.id,
            B2cId: x.b2cId,
            Status: x.hasLoggedIn ? UserInviteStatus.Active : UserInviteStatus.InvitePending,
            Role:
                x.role === UserRole.Admin
                    ? UserType.Admin
                    : x.role === UserRole.SuperUser
                        ? UserType.SuperUser 
                        : x.role === UserRole.NotificationUser 
                            ? UserType.NotificationUser
                            : UserType.RegularUser,
            PhoneNumber: x.phoneNumber,
            ServiceToolAccess: x.serviceToolAccess ? ServiceToolAccessStatus.Access : ServiceToolAccessStatus.NoAccess,
            Email: x.userName,
        } as User;
    });

    userList.sort((a, b) => {
        if (a.Role > b.Role) {
            return -1;
        } else if (a.Role < b.Role) {
            return 1;
        }
        return 0;
    });

    return userList;
};

export const UpdateUser = async (
    name: string,
    groupId: number | undefined,
    usertype: UserType | undefined,
    phoneNumber: string | undefined,
    hasServicetoolAccess: boolean | undefined,
    b2cId: string,
): Promise<void> => {
    const putUser: PutUser = {
        name: name,
        groupId: groupId,
        permission: usertype,
        phoneNumber: phoneNumber,
        hasServicetoolAccess: hasServicetoolAccess,
    };

    await (await authorizedAxiosInstance()).put(`/api/users/${b2cId}`, putUser);
};

export const GetUsersByGroup = async (groupId: number): Promise<User[]> => {
    const axiosInstance = await authorizedAxiosInstance();
    const response = await axiosInstance.get<UserListObject>(`/api/usersbygroup/` + groupId);
    const userDataList: GetUser[] = response.data.users;
    const userList: User[] = userDataList.map((x) => {
        return {
            Name: x.fullName,
            Group: { Id: x.groupId, Name: x.groupName },
            Id: x.id,
            B2cId: x.b2cId,
            Status: x.hasLoggedIn ? UserInviteStatus.Active : UserInviteStatus.InvitePending,
            Role:
                x.role === UserRole.Admin
                    ? UserType.Admin
                    : x.role === UserRole.SuperUser
                        ? UserType.SuperUser
                        : UserType.RegularUser,
            PhoneNumber: x.phoneNumber,
            ServiceToolAccess: x.serviceToolAccess ? ServiceToolAccessStatus.Access : ServiceToolAccessStatus.NoAccess,
            Email: x.userName,
            GroupName: x.groupName
        } as User;
    });
    userList.sort((a, b) => {
        if (a.Role > b.Role) {
            return -1;
        } else if (a.Role < b.Role) {
            return 1;
        }
        return 0;
    });

    return userList;
};

export const GetUsersByGroupIncludeSelf = async (groupId: number): Promise<User[]> => {
    const axiosInstance = await authorizedAxiosInstance();
    const response = await axiosInstance.get<UserListObject>(`/api/usersbygroupincludeself/` + groupId);
    const userDataList: GetUser[] = response.data.users;
    const userList: User[] = userDataList.map((x) => {
        return {
            Name: x.fullName,
            Group: { Id: x.groupId, Name: x.groupName },
            Id: x.id,
            B2cId: x.b2cId,
            Status: x.hasLoggedIn ? UserInviteStatus.Active : UserInviteStatus.InvitePending,
            Role:
                x.role === UserRole.Admin
                    ? UserType.Admin
                    : x.role === UserRole.SuperUser
                        ? UserType.SuperUser
                        : UserType.RegularUser,
            PhoneNumber: x.phoneNumber,
            ServiceToolAccess: x.serviceToolAccess ? ServiceToolAccessStatus.Access : ServiceToolAccessStatus.NoAccess,
            Email: x.userName,
            GroupName: x.groupName
        } as User;
    });

    return userList;
};