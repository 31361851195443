import React, { useEffect, useState } from 'react';
import Button from '../../../../../shared/button/Button';
import SlideInModal from '../../../../../shared/slideInModal/SlideInModal';
import NotificationGroupUserList from '../NotificationGroupUserList/NotificationGroupUserList';
import './patchNotification.css';
import { ReactComponent as CancelSvg } from '../../../../../assets/common/iconExitGrey.svg';
import * as NotificationGroupService from '../../../../../data/services/NotificationGroupService';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import Group from '../../../../../models/Group';
import SimpleNotificationGroup from '../../../../../models/SimpleNotificationGroup';
import Loader from '../../../../../shared/loader/Loader';
import ErrorBox from '../../../../../shared/errorBox/ErrorBox';

export interface PatchNotificationProps {
    open: boolean;
    handleClose: () => void;
    onSubmit?: () => void;
    groupToEdit: Group;
    onNotificationGroupEdit: (notificationGroup?: SimpleNotificationGroup) => void;
    notificationGroupToUpdate?: SimpleNotificationGroup;
    notificationGroupToDelete?: SimpleNotificationGroup;
    onAddNotificationUser: (notificationGroup: SimpleNotificationGroup) => void;
}

const PatchNotification: React.FC<PatchNotificationProps> = ({
    open,
    handleClose,
    onSubmit,
    groupToEdit,
    onNotificationGroupEdit,
    notificationGroupToUpdate,
    notificationGroupToDelete,
    onAddNotificationUser
}) => {
    const [groupName, setGroupName] = useState('');
    const [errorOccured, setErrorOccured] = useState(false);
    const [errorParagraphs, setErrorParagraphs] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const { t }: UseTranslationResponse = useTranslation();
    const [notificationGroupToEdit, setNotificationGroupToEdit] = useState<SimpleNotificationGroup>();
    const [notificationGroupList, setNotificationGroupList] = useState<SimpleNotificationGroup[]>([]);

    const fetchNotificationGroup = async () => {

    };

    useEffect(() => {
        if (open) {
            setNotificationGroupList([]);
            setLoading(true);

            try {
                NotificationGroupService.GetNotificationGroups(groupToEdit.id).then((e) => {
                    setNotificationGroupList([...e]);

                    if (groupToEdit) {
                        fillInputs(groupToEdit);
                    }
                    setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);

                    setLoading(false);
                });
            } catch (err) {
                setErrorOccured(true);
            }
        } else {
            setErrorOccured(false);
        }
    }, [open, groupToEdit, t]);

    useEffect(() => {
        if (notificationGroupToUpdate) {
            setLoading(true);

            try {
                NotificationGroupService.GetNotificationGroups(groupToEdit.id).then((e) => {
                    setNotificationGroupList([...e]);

                    if (groupToEdit) {
                        fillInputs(groupToEdit);
                    }
                    setErrorParagraphs([t('PATCH_GROUP.ERROR_EDIT')]);

                    setLoading(false);
                });
            } catch (err) {
                setErrorOccured(true);
            }
        }
    },
        [notificationGroupToUpdate]);

    useEffect(() => {
        if (notificationGroupToDelete) {
            const notificationGroup = notificationGroupList.find(c => c.id === notificationGroupToDelete.id);
            if (notificationGroup) {
                const notificationGroupIndex = notificationGroupList.indexOf(notificationGroup);
                if (notificationGroupIndex > -1) {
                    notificationGroupList.splice(notificationGroupIndex, 1);
                    setNotificationGroupList([...notificationGroupList]);
                }
            }
        }
    }, [notificationGroupToDelete]);

    const fillInputs = (group: Group) => {
        setGroupName(group.name);
    };

    const isValid = () => {
        return notificationGroupList.length > 0;
    };

    const updateGroup = () => {
        if (groupToEdit) {
            setLoading(true);

            NotificationGroupService.PutNotificationGroupUsers(groupToEdit.id, notificationGroupList)
                .then((r) => {
                    if (onSubmit) {
                        onSubmit();
                    }
                })
                .catch(() => {
                    setErrorOccured(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const notificationGroupEdit = (notificationGroup?: SimpleNotificationGroup) => {
        if (notificationGroup) {
            notificationGroup.groupId = groupToEdit.id;
        }
        onNotificationGroupEdit(notificationGroup);
    }

    const addNotificationUser = (notificationGroup: SimpleNotificationGroup) => {
        notificationGroup.groupId = groupToEdit.id;
        onAddNotificationUser(notificationGroup);
    }

    return (
        <>
            <SlideInModal width={700} open={open} handleClose={() => handleClose()}>
                <div className="patch-group__header">
                    <div className="patch-group__header-items">

                        <div
                            className={`patch-group__cancel patch-group__cancel-edit`}
                            onClick={() => handleClose()}
                        >
                            <span>{t('COMMON.CANCEL')}</span>
                            <CancelSvg className="patch-group__cancel-icon" />
                        </div>
                        <Button
                            className={`patch-group__submit-button patch-group__save`}
                            onClick={() => updateGroup()}
                            disabled={!isValid()}
                        >
                            {t('PATCH_GROUP.SAVE')}
                        </Button>
                    </div>
                </div>
                <div className="patch-group__body">
                    <div className="patch-group__body-title">
                        {t('PATCH_NOTIFICATION.EDIT_NOTIFICATION')}
                    </div>
                    <div className="patch-group__group-name-section">
                        <div className="patch-group__group-name-label">{t('PATCH_NOTIFICATION.GROUP_NAME')}</div>
                        <div className="patch-group__group-name">{groupName}</div>
                    </div>

                    <div className="patch-group__group-userlist">
                        {notificationGroupList &&
                            <NotificationGroupUserList
                                onEditGroup={notificationGroupEdit}
                                dataSource={notificationGroupList}
                                groupToEdit={groupToEdit}
                                onAddNotificationUser={addNotificationUser}
                            />
                        }
                        <div className="patch-notification__group_add">
                            <Button className={`patch-group__submit-button patch-group__addgroup`} onClick={() => notificationGroupEdit(undefined)}>
                                {t('PATCH_NOTIFICATION.ADD_NOTIFICATION_GROUP')}
                            </Button></div>
                    </div>

                    {errorOccured && (
                        <ErrorBox className="patch-group_error-box" paragraphs={errorParagraphs} header={t('COMMON.ERROR')} />
                    )}
                </div>
                {loading && <Loader mode="fill-parent" />}



            </SlideInModal>


        </>
    );
};

export default PatchNotification