import React from 'react';
import './Button.css';

interface ButtonProps {
  onClick?: () => void;
  className?: string;
  size?: 'default' | 'small';
  style?: React.CSSProperties;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick = () => {
    return;
  },
  className,
  size = 'default',
  style,
  disabled,
}) => {
  return (
    <button
      onClick={() => onClick()}
      onMouseDown={(e) => e.preventDefault()}
      className={`${className} button_${size}`}
      style={style}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default Button;
