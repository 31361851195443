import React from 'react';
import './InputField.css';

type InputFieldProps = {
  value: string;
  setValue: (key: string) => void;
  label: string;
  disabled?: boolean;
  type?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

export const InputField: React.FC<InputFieldProps> = ({
  value,
  setValue,
  label,
  disabled = false,
  type = 'text',
  onBlur,
  onFocus,
}) => {
  return (
    <div className="InputField__body-form">
      <input
        type={type}
        className={value === '' ? '' : 'InputField__body-form-input-text'}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <label className="InputField__body-form-input-label">{label}</label>
    </div>
  );
};
